import { createSlice } from "@reduxjs/toolkit";

export const bootcampSlice = createSlice({
  name: "bootcamp",
  initialState: {
    selectCohort: 1,
    dataScienceCohort: 1,
    bootcampStartDate: ``,
    dataScienceSelected: false,
    successNotificationStatus: false,
  },
  reducers: {
    handleSelectCohort: (state, action) => {
      state.selectCohort = action.payload;
    },
    handleDataScienceCohort: (state, action) => {
      state.dataScienceCohort = action.payload;
    },
    handleStoreBootcampDate: (state, action) => {
      state.bootcampStartDate = action.payload;
    },
    handleDataScienceSelected: (state, action) => {
      state.dataScienceSelected = action.payload;
    },
    handleSuccessNotificationStatus: (state, action) => {
      state.successNotificationStatus = action.payload;
    },
  },
});

export const {
  handleSelectCohort,
  handleStoreBootcampDate,
  handleDataScienceCohort,
  handleDataScienceSelected,
  handleSuccessNotificationStatus,
} = bootcampSlice.actions;

export default bootcampSlice.reducer;
