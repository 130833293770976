import React from 'react';
import { Link } from 'react-router-dom';
import useScrollToTop from '../../hooks/useScrollToTop';

const ComingSoon = () => {
  useScrollToTop();
  return (
    <div className='container mx-auto flex flex-col my-16'>
      <div className='flex flex-col items-center'>
        <div className='py-3'>
          <h3 className='text-6xl text-center pb-8'>Coming Soon</h3>
          <p className='text-center text-[#808080]'>
            The content on this page is being worked on. <br /> In the meantime, you can
            look through our other product offerings.
          </p>
        </div>

        <div className=''>
          <Link to='/'>
            <button className='py-4 px-8 rounded bg-[#2b2c54] text-white hover:bg-[#15175c] mt-8 text-sm'>
              Browse through website
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
