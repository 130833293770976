import { createSlice } from "@reduxjs/toolkit";

const adminSlice = createSlice({
  name: "adminSlice",
  initialState: {
    isAdmin: false,
    projectSubmission: false,
  },
  reducers: {
    handleIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    handleProjectSubmission: (state, action) => {
      state.projectSubmission = action.payload;
    },
  },
});

export const { handleIsAdmin, handleProjectSubmission } =
  adminSlice.actions;
export default adminSlice.reducer;
