import { useEffect } from "react";
import { useCountdownTimer } from "../../hooks/useCountdownTimer";
import { SplitDate } from "../WebsiteTimer/WebsiteTimer";

const CountdownTimer = ({ ttl }) => {
  const [days, hours, minutes, seconds] = useCountdownTimer(ttl);

  useEffect(() => {
    localStorage.setItem("days-left", JSON.stringify(days));
    localStorage.setItem("hours-left", JSON.stringify(hours));
    localStorage.setItem("minutes-left", JSON.stringify(minutes));
    localStorage.setItem("seconds-left", JSON.stringify(seconds));
  });

  return <></>;
};

export function formatBootcampKickOffDate(inputDate) {
  const { day, month, year } = SplitDate(inputDate);
  const date = new Date(year, month, day);

  date.setDate(date.getDate());

  return date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function formatDateTwoDaysBack(inputDate) {
  const { day, month, year } = SplitDate(inputDate);

  const date = new Date(year, month, day);

  date.setDate(date.getDate() - 2);

  return date.toLocaleDateString("en-US", {
    weekday: "short",
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export default CountdownTimer;
