import { createSlice } from "@reduxjs/toolkit";

export const unlockedTopics = createSlice({
  name: "unlockedTopics",
  initialState: {
    percentCompleted: null,
    unlockedTopics: null,
    toggler: false,
  },
  reducers: {
    handleUnlockedTopics: (state, action) => {
      state.unlockedTopics = action.payload;
    },
    handleUnlockedTopicsToggler: (state, action) => {
      state.toggler = action.payload;
    },
    handlePercentCompleted: (state, action) => {
      state.percentCompleted = action.payload;
    },
  },
});

export const {
  handleUnlockedTopics,
  handlePercentCompleted,
  handleUnlockedTopicsToggler,
} = unlockedTopics.actions;
export default unlockedTopics.reducer;
