import {
  query,
  where,
  orderBy,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase/firebase";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { handleToggleSignupModal } from "../../redux/reducers/toggleSlice";
import { handleCaseStudyProjectSubmissonStatus } from "../../redux/reducers/getCaseStudySlice";

const TabNavItem = ({ title, id, activeTab, setActiveTab, path }) => {
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  const userHasPaidRole = useSelector(
    (state) => state.userSubscription.premiumUser
  );
  const [submissionStatus, setSubmissionStatus] =
    useState("Not submitted");
  const paths = [
    "/account/achievements",
    "/account/subscription",
    "/account/profile",
  ];
  const handleTabSwitch = () => {
    if (!userHasPaidRole === true && !paths.includes(pathname)) {
      dispatch(handleToggleSignupModal(true));
      return;
    }
    setActiveTab(id);

    if (
      pathname ===
        "/library/analysis-and-visualisation-of-gdp-in-powerbi-excel" ||
      pathname ===
        "/library/data-analytics-portfolio-and-capstone-project" ||
      pathname ===
        "/library/data-science-portfolio-and-capstone-project" ||
      pathname === "/library/machine-learning-case-study" ||
      pathname ===
        "/library/data-analytics-fundamentals-with-google-sheets"
    )
      return;
    dispatch(handleCaseStudyProjectSubmissonStatus(submissionStatus));
  };

  useEffect(() => {
    if (
      pathname ===
        "/library/analysis-and-visualisation-of-gdp-in-powerbi-excel" ||
      pathname ===
        "/library/data-analytics-portfolio-and-capstone-project" ||
      pathname ===
        "/library/data-science-portfolio-and-capstone-project" ||
      pathname === "/library/machine-learning-case-study" ||
      pathname ===
        "/library/data-analytics-fundamentals-with-google-sheets"
    )
      return;
    (async () => {
      if (!currentUser?.uid) return;
      try {
        const assignmentsQuery = query(
          collection(db, "assignments"),
          orderBy("submissionTimestamp", "desc"),
          where("userId", "==", currentUser.uid)
        );
        const assignmentsSnapshot = await getDocs(assignmentsQuery);

        if (assignmentsSnapshot.docs.length < 1) return;

        const recentSubmission = assignmentsSnapshot.docs.map((doc) =>
          doc.data()
        )[0];

        // For those who have submitted
        // before this feature was added.
        if (!recentSubmission?.status) {
          setSubmissionStatus("Approved");
          dispatch(handleCaseStudyProjectSubmissonStatus("Approved"));
        } else {
          setSubmissionStatus(recentSubmission?.status);
          dispatch(
            handleCaseStudyProjectSubmissonStatus(
              recentSubmission?.status
            )
          );
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentUser?.uid]);

  return (
    <>
      {path ? (
        <NavLink
          end
          to={path}
        >
          <li
            onClick={handleTabSwitch}
            className={activeTab === id ? "active__tab" : null}
          >
            {title}
          </li>
        </NavLink>
      ) : (
        <li
          onClick={handleTabSwitch}
          className={activeTab === id ? "active__tab" : null}
        >
          {title}
        </li>
      )}
    </>
  );
};

export default TabNavItem;
