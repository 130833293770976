import { Link } from 'react-router-dom';

const LearnMenuCard = ({ icon, path, title }) => {
  return (
    <div>
      <Link to={path}>
        <div className='bg-white rounded p-6 relative xs:w-full md:w-[200px] xs:h-[100px] md:h-[80px] border-[#dddddd] shadow-md flex flex-col justify-center gap-3'>
          <div className='w-[20px] h-[20px] rounded-full absolute border-solid border-[#2b2c54] top-0 left-1'>
            <img
              className='w-full h-full object-cover rounded-full'
              src={icon}
              alt=''
            />
          </div>
          <div className=''>
            <h4 className='text-sm font-medium'>{title}</h4>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default LearnMenuCard;
