import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @components
import LearnMenuSidebar from '../../components/LeanMenuSidebar/LearnMenuSidebar';
import useScrollToTop from '../../hooks/useScrollToTop';

const LearnMenu = () => {
  useScrollToTop();
  const width = useSelector((state) => state.windowDimension.width);

  return (
    <>
      {width <= 768 ? (
        <section className='flex flex-row gap-8 py-3 px-4'>
          <div className='w-full'>
            <LearnMenuSidebar />
          </div>
        </section>
      ) : (
        <div className='px-4'>
          <div className='lg:px-0 mx-auto max-w-[1100px] pt-8'>
            <section className='flex flex-col'>
              <div className='basis-[20%]'>
                <LearnMenuSidebar />
              </div>
              <div className='basis-full'>
                <Outlet />
              </div>
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default LearnMenu;
