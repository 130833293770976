import { createSlice } from '@reduxjs/toolkit';

export const unAuthPaymentSlice = createSlice({
  name: 'unAuthPayment',
  initialState: {
    unAuth: false,
    email: ''
  },
  reducers: {
    setUnAuthPayment: (state, action) => {
      state.unAuth = action.payload;
    },
    setUnAuthEmail: (state, action) => {
      state.email = action.payload;
    }
  },
});

export const { setUnAuthPayment, setUnAuthEmail } = unAuthPaymentSlice.actions;

export default unAuthPaymentSlice.reducer;
