export default function MSPowerBIIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="56"
      fill="none"
      viewBox="0 0 42 56"
    >
      <g clipPath="url(#clip0_2279_925)">
        <mask
          id="mask0_2279_925"
          style={{ maskType: "luminance" }}
          width="42"
          height="56"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M23.123 2.329A2.32 2.32 0 0125.435 0H39.31a2.32 2.32 0 012.313 2.329v51.233a2.32 2.32 0 01-2.313 2.329H2.312A2.32 2.32 0 010 53.562V30.274a2.32 2.32 0 012.312-2.328h9.25V16.302a2.32 2.32 0 012.312-2.33h9.25V2.33z"
          ></path>
        </mask>
        <g mask="url(#mask0_2279_925)">
          <path
            fill="url(#paint0_linear_2279_925)"
            d="M41.623 0v55.89H23.125V0h18.498z"
          ></path>
        </g>
        <mask
          id="mask1_2279_925"
          style={{ maskType: "luminance" }}
          width="42"
          height="56"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M23.123 2.329A2.32 2.32 0 0125.435 0H39.31a2.32 2.32 0 012.313 2.329v51.233a2.32 2.32 0 01-2.313 2.329H2.312A2.32 2.32 0 010 53.562V30.274a2.32 2.32 0 012.312-2.328h9.25V16.302a2.32 2.32 0 012.312-2.33h9.25V2.33z"
          ></path>
        </mask>
        <g mask="url(#mask1_2279_925)">
          <path
            fill="#000"
            fillOpacity="0.2"
            d="M30.061 16.534v39.59H11.563V14.206h16.186a2.32 2.32 0 012.312 2.328"
          ></path>
        </g>
        <mask
          id="mask2_2279_925"
          style={{ maskType: "luminance" }}
          width="42"
          height="56"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M23.123 2.329A2.32 2.32 0 0125.435 0H39.31a2.32 2.32 0 012.313 2.329v51.233a2.32 2.32 0 01-2.313 2.329H2.312A2.32 2.32 0 010 53.562V30.274a2.32 2.32 0 012.312-2.328h9.25V16.302a2.32 2.32 0 012.312-2.33h9.25V2.33z"
          ></path>
        </mask>
        <g mask="url(#mask2_2279_925)">
          <path
            fill="#000"
            fillOpacity="0.18"
            d="M30.061 17.466v39.59H11.563V15.136h16.186a2.32 2.32 0 012.312 2.329"
          ></path>
        </g>
        <mask
          id="mask3_2279_925"
          style={{ maskType: "luminance" }}
          width="42"
          height="56"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M23.123 2.329A2.32 2.32 0 0125.435 0H39.31a2.32 2.32 0 012.313 2.329v51.233a2.32 2.32 0 01-2.313 2.329H2.312A2.32 2.32 0 010 53.562V30.274a2.32 2.32 0 012.312-2.328h9.25V16.302a2.32 2.32 0 012.312-2.33h9.25V2.33z"
          ></path>
        </mask>
        <g mask="url(#mask3_2279_925)">
          <path
            fill="url(#paint1_linear_2279_925)"
            d="M30.061 16.302V55.89H11.563V13.973h16.186a2.32 2.32 0 012.312 2.329"
          ></path>
        </g>
        <mask
          id="mask4_2279_925"
          style={{ maskType: "luminance" }}
          width="42"
          height="56"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#fff"
            d="M23.123 2.329A2.32 2.32 0 0125.435 0H39.31a2.32 2.32 0 012.313 2.329v51.233a2.32 2.32 0 01-2.313 2.329H2.312A2.32 2.32 0 010 53.562V30.274a2.32 2.32 0 012.312-2.328h9.25V16.302a2.32 2.32 0 012.312-2.33h9.25V2.33z"
          ></path>
        </mask>
        <g mask="url(#mask4_2279_925)">
          <path
            fill="url(#paint2_linear_2279_925)"
            d="M0 27.946V55.89h18.498V30.275a2.32 2.32 0 00-2.312-2.33H0z"
          ></path>
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2279_925"
          x1="811.033"
          x2="2879.51"
          y1="0"
          y2="4353.57"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6AD10"></stop>
          <stop
            offset="1"
            stopColor="#C87E0E"
          ></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2279_925"
          x1="753.767"
          x2="2478.69"
          y1="13.973"
          y2="3335.72"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F6D751"></stop>
          <stop
            offset="1"
            stopColor="#E6AD10"
          ></stop>
        </linearGradient>
        <linearGradient
          id="paint2_linear_2279_925"
          x1="513.778"
          x2="1564.85"
          y1="27.946"
          y2="2501.29"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9E589"></stop>
          <stop
            offset="1"
            stopColor="#F6D751"
          ></stop>
        </linearGradient>
        <clipPath id="clip0_2279_925">
          <path
            fill="#fff"
            d="M0 0H41.622V56H0z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
