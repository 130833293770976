import { createSlice } from '@reduxjs/toolkit';

export const videoContentSlice = createSlice({
  name: 'videoContent',
  initialState: {
    startCourse: false,
    continueCourse: false,
    showPlayButton: true,
    video: null,
    isWatching: null,
    isEnded: null,
    showPreview: false,
    lessonId: null,
    videoTitle: '',
    lessonType: '',
    previouslyWatching: '',
    previouslyWatchingUrl: '',
    previouslyWatchingTitle: '',
    previouslyWatchingTimestamp: '',
  },
  reducers: {
    updateVideo: (state, action) => {
      state.video = action.payload;
    },
    updateWatchedState: (state, action) => {
      state.isWatching = action.payload;
    },
    updateEndedState: (state, action) => {
      state.isEnded = action.payload;
    },
    updateShowPreview: (state, action) => {
      state.showPreview = action.payload;
    },
    updateLessonId: (state, action) => {
      state.lessonId = action.payload;
    },
    updateWatchTitle: (state, action) => {
      state.videoTitle = action.payload;
    },
    updateStartCourse: (state, action) => {
      state.startCourse = action.payload;
    },
    updateContinueCourse: (state, action) => {
      state.continueCourse = action.payload;
    },
    updateShowPlayButton: (state, action) => {
      state.showPlayButton = action.payload;
    },
    updateLessonType: (state, action) => {
      state.lessonType = action.payload;
    },
    updatePreviouslyWatching: (state, action) => {
      state.previouslyWatching = action.payload;
    },
    updatePreviouslyWatchingUrl: (state, action) => {
      state.previouslyWatchingUrl = action.payload;
    },
    updatePreviouslyWatchingTitle: (state, action) => {
      state.previouslyWatchingTitle = action.payload;
    },
    updatePreviouslyWatchingTimestamp: (state, action) => {
      state.previouslyWatchingTimestamp = action.payload;
    },
  },
});

export const {
  updateVideo,
  updateWatchedState,
  updateEndedState,
  updateShowPreview,
  updateLessonId,
  updateWatchTitle,
  updateLessonType,
  updateStartCourse,
  updateShowPlayButton,
  updateContinueCourse,
  updatePreviouslyWatching,
  updatePreviouslyWatchingUrl,
  updatePreviouslyWatchingTitle,
  updatePreviouslyWatchingTimestamp,
} = videoContentSlice.actions;

export default videoContentSlice.reducer;
