import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

export const ScrollButtons = ({
  top,
  display,
  leftButtonRight,
  leftButtonDisabled,
  rightButtonDisabled,
  handleLeftForwardScroll,
  handleLeftBackwardScroll,
}) => (
  <>
    {(!leftButtonDisabled || display) && (
      <Button
        top={top}
        handler={handleLeftBackwardScroll}
        right={leftButtonRight ? leftButtonRight : ""}
        left={leftButtonRight ? leftButtonRight : "left-0"}
      >
        <ChevronLeftRoundedIcon
          className={` ${
            !leftButtonDisabled ? "text-white" : "text-[#fafafa3d]"
          } !text-4xl`}
        />
      </Button>
    )}
    {(!rightButtonDisabled || display) && (
      <Button
        top={top}
        left={"right-0"}
        handler={handleLeftForwardScroll}
      >
        <ChevronRightRoundedIcon
          className={` ${
            !rightButtonDisabled ? "text-white" : "text-[#fafafa3d]"
          } text-white !text-4xl`}
        />
      </Button>
    )}
  </>
);

const Button = ({ handler, children, left, top, right }) => (
  <div
    className={`absolute z-10 ${left} ${
      top ? top : "top-[11.5rem]"
    } bg-[#000000a1] rounded-full ${
      right ? right : ""
    } flex items-center justify-center`}
  >
    <button
      className="!bg-transparent"
      onClick={handler}
    >
      {children}
    </button>
  </div>
);
