import { createSlice } from "@reduxjs/toolkit";

const projectReviews = createSlice({
  name: "projectReviews",
  initialState: {
    ascOrDesc: "ASC",
    projectFilter: "None",
    awaitingReviews: null,
    projectsToReview: null,
    filteredCourseTitle: "None",
    filteredProjectsToReview: null,
    orderByTableColumn: "Submission Time",
  },
  reducers: {
    handleAscOrDesc: (state, action) => {
      state.ascOrDesc = action.payload;
    },
    handleProjectFilter: (state, action) => {
      state.projectFilter = action.payload;
    },
    handleAwaitingReviews: (state, action) => {
      state.awaitingReviews = action.payload;
    },
    handleprojectsToReview: (state, action) => {
      state.projectsToReview = action.payload;
    },
    handleOrderByTableColumn: (state, action) => {
      state.orderByTableColumn = action.payload;
    },
    handleFilteredCourseTitle: (state, action) => {
      state.filteredCourseTitle = action.payload;
    },
    handleFilteredProjectsToReview: (state, action) => {
      state.filteredProjectsToReview = action.payload;
    },
  },
});

export const {
  handleAscOrDesc,
  handleProjectFilter,
  handleAwaitingReviews,
  handleprojectsToReview,
  handleOrderByTableColumn,
  handleFilteredCourseTitle,
  handleFilteredProjectsToReview,
} = projectReviews.actions;

export default projectReviews.reducer;
