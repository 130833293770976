import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { updateUserSettings } from "../../redux/reducers/userSettingsSlice";

// @components
import UserAuthMenu from "../UserAuthMenu/UserAuthMenu";

// @styling
import "./authnavbar.scss";

// @icons
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import {
  handleProgramAcess,
  handleSetPremiumRole,
} from "../../redux/reducers/setUserSubscriptionSlice";

// Redux
import {
  handleBootcampType,
  handleNoBootcampData,
  handleUserEngagement,
  handleIsDataAnalytics,
  handleTotalEngagement,
  handleGlobalLeaderboard,
  handleBbootcampCohortData,
  handleBootcampCohortDetails,
  handleIsFetchingBootcamperDoc,
} from "../../redux/reducers/dashboardDataSlice";
import {
  BootcampLeagueListPlaceholder,
  globleLeaguePlaceholderList,
} from "../../constants/leagueTablePlaceholder";
import { handleProgramCoursesIndex } from "../../redux/reducers/toggleSlice";
import { handleBootcampStartDate } from "../../redux/reducers/bootcampStartDate";
import ExperiencePoint from "../ExperiencePoint/ExperiencePoint";

const AuthNavBar = () => {
  const [showAuth, setShowAuth] = useState(false);
  const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
  const [navDisplayName, setNavDisplayName] = useState("");

  const { logout, currentUser } = useAuth();

  const userHasPaidRole = useSelector(
    (state) => state.userSubscription.premiumUser
  );
  const awaitingAccess = useSelector(
    (state) => state.userSubscription.awaitingAccess
  );
  const settingsUpdate = useSelector(
    (state) => state.userSettings.updateChanges
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = async () => {
    try {
      logout();
      localStorage.removeItem("CourseCompletionData");
      localStorage.removeItem("recentVideos");
      localStorage.removeItem("dataAnalyticsBootcampRoadmap-v3");
      localStorage.removeItem("dataScienceBootcampRoadmap-v4");
      sessionStorage.removeItem("userHasFreeRole");
      localStorage.removeItem("bootcampStartDate");
      dispatch(handleSetPremiumRole(false));
      dispatch(handleIsDataAnalytics(true));
      dispatch(handleBootcampType(null));
      dispatch(handleBootcampStartDate(null));
      dispatch(handleProgramCoursesIndex(0));
      dispatch(handleNoBootcampData(false));
      dispatch(handleProgramAcess(false));
      dispatch(handleUserEngagement(null));
      dispatch(handleTotalEngagement(null));
      dispatch(handleGlobalLeaderboard(globleLeaguePlaceholderList));
      dispatch(
        handleBbootcampCohortData(BootcampLeagueListPlaceholder)
      );
      dispatch(handleBootcampCohortDetails([]));
      dispatch(
        handleIsFetchingBootcamperDoc({
          status: false,
          isChecked: false,
        })
      );
      navigate("/", {
        state: {
          previousUrl: "/signin",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleAuthLeave = () => {
    setShowAuth(false);
  };

  const truncate = (input) =>
    input && input.length > 10
      ? input.substring(0, 9) + "..."
      : input;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowUpgradeBtn(true);
      settingsUpdate === true &&
        setNavDisplayName(currentUser?.displayName);
    }, 500);
    if (navDisplayName !== "") {
      dispatch(updateUserSettings(false));
    }

    return () => clearTimeout(timeout);
  }, [
    settingsUpdate,
    currentUser?.displayName,
    dispatch,
    navDisplayName,
  ]);

  return (
    <>
      <div className="flex flex-row items-center gap-3">
        {showUpgradeBtn
          ? userHasPaidRole !== true &&
            !awaitingAccess && (
              <Link to="/pricing">
                <button className="bg-[#222] text-[#ff8c00] font-bold p-2 rounded text-xs hover:bg-[#111]">
                  Upgrade
                </button>
              </Link>
            )
          : null}

        <div className="flex flex-row gap-3 items-center">
          {location.pathname !== "/dashboard" && (
            <div className="authnavbar__link__dashboard">
              <Link to="/dashboard">
                <button>
                  <DashboardRoundedIcon className="dashboard__icon" />{" "}
                  Dashboard
                </button>
              </Link>
            </div>
          )}

          <ExperiencePoint />

          <div
            className="authnavbar__auth__user"
            onMouseLeave={handleAuthLeave}
          >
            <div
              className="authnavbar__auth__user__container"
              onMouseEnter={() => setShowAuth(true)}
            >
              <AccountCircleRoundedIcon className="account__icon" />
              {settingsUpdate ? (
                <p>
                  {truncate(navDisplayName !== "" && navDisplayName)}
                </p>
              ) : (
                <p>{truncate(currentUser?.displayName)}</p>
              )}
            </div>
            <div className="authnavbar__auth__arrow__icon">
              <ArrowDropDownRoundedIcon
                className="arrow__icon"
                onMouseEnter={() => setShowAuth(true)}
              />
            </div>
            {showAuth && (
              <UserAuthMenu
                showAuth={showAuth}
                setShowAuth={setShowAuth}
                handleLogout={handleLogout}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AuthNavBar;
