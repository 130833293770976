import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LearningPathCourseList from '../../components/LearningPathCourseList/LearningPathCourseList';

// @components
import CloseRoundedButton from '../../components/CloseRoundedButton/CloseRoundedButton'

const LearningPathStepper = ({ id, title, data, exitLink }) => {
  useEffect(() => {
    let el = document.getElementById(`${id}`);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }, [id]);

  return (
    <>
      <div id={id} className='relative xs:top-[100px] md:top-auto flex flex-col gap-16 xs:h-screen md:h-auto overflow-y-auto pb-16'>
        <div className=''>
          <CloseRoundedButton
            path={'/learn/learning-path'}
            bg='bg-[#ff605c]'
            border='border-[#ff605c]'
            color='#fff'
          />
        </div>
        <div className='flex flex-col gap-12 items-center py-12 bg-[#2b2c54] rounded p-6 relative w-full border-[#2b2c54] shadow-md xs:h-fit md:h-auto'>
          <h3 className='font-medium text-[#f4bd52]'>
            {title}
          </h3>
          <div className='xs:flex xs:flex-col md:flex md:flex-row justify-center w-fit'>
            {data.map((excel) => (
              <LearningPathCourseList
                key={excel.id}
                title={excel.title}
                auth={excel.authPath}
                unauth={excel.unauthPath}
                connector={excel.connector}
                stepper={excel.id}
              />
            ))}
          </div>
          <div className='xs:w-full flex justify-center'>
            <Link
              to={exitLink}
              className='inline-block text-center text-xs font-bold text-[#2b2c54] bg-[#f4bd52] xs:py-3 md:px-4 rounded hover:bg-[#eab64e] xs:w-1/2 md:w-auto'
            >
              Get started
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningPathStepper;
