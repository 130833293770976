import { createSlice } from '@reduxjs/toolkit';

export const getCaseStudySlice = createSlice({
  name: 'getCaseStudy',
  initialState: {
    isCaseStudyFetched: false,
    caseStudyTitle: '',
    showSqlConsole: false,
    submissionStatus: "Not submitted",
    caseStudyProjectSubmissonStatus: null,
  },
  reducers: {
    handleCaseStudyFetched: (state, action) => {
      state.isCaseStudyFetched = action.payload;
    },
    handleCaseStudyTitle: (state, action) => {
      state.caseStudyTitle = action.payload;
    },
    handleShowSqlConsole: (state, action) => {
      state.showSqlConsole = action.payload;
    },
    handleCaseStudyProjectSubmissonStatus: (state, action) => {
      state.caseStudyProjectSubmissonStatus = action.payload;
    },
    handleSubmissionStatus: (state, action) => {
      state.submissionStatus = action.payload;
    },
  },
});

export const {
  handleCaseStudyTitle,
  handleShowSqlConsole,
  handleCaseStudyFetched,
  handleSubmissionStatus,
  handleCaseStudyProjectSubmissonStatus,
} = getCaseStudySlice.actions;

export default getCaseStudySlice.reducer;
