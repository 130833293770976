import { createSlice } from '@reduxjs/toolkit';

export const activationFormSlice = createSlice({
  name: 'activationForm',
  initialState: {
    checkBeforeUnmount: false,
    googleSignupRedirect: false,
  },
  reducers: {
    handleCheckBeforeUnmount: (state, action) => {
      state.checkBeforeUnmount = action.payload;
    },
    handleGoogleSignupRedirect: (state, action) => {
      state.googleSignupRedirect = action.payload;
    },
  },
});

export const { handleCheckBeforeUnmount, handleGoogleSignupRedirect } =
  activationFormSlice.actions;

export default activationFormSlice.reducer;
