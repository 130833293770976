import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const PaymentToggler = ({
  isFullPayment,
  setIsFullPayment,
}) => {
  return (
    <div className="w-full flex mt-5 gap-8">
      <div
        className={` flex-1 ${
          isFullPayment ? "bg-[#2B2C54] text-white" : "bg-[#eaeaea]"
        } rounded relative`}
      >
        {isFullPayment && <SelectedMark />}
        <button
          onClick={() => {
            setIsFullPayment(true);
          }}
          className="py-3 px-4 text-left font-normal text-lg w-full"
        >
          <span className="block">Full Payment</span>
          <span className="mt-1 text-xs block font-light">
            100% payment for the bootcamp. Get access to discounts.
          </span>
        </button>
      </div>
      <div
        className={` flex-1 ${
          !isFullPayment ? "bg-[#2B2C54] text-white" : "bg-[#eaeaea]"
        } rounded relative`}
      >
        {!isFullPayment && <SelectedMark />}
        <button
          onClick={() => {
            setIsFullPayment(false);
          }}
          className="py-3 px-4 text-left font-normal text-lg w-full"
        >
          <span className="block">Partial Payment</span>
          <span className="mt-1 text-xs block font-light">
            Pay 50% now and complete it in 30 days.
          </span>
        </button>
      </div>
    </div>
  );
};

const SelectedMark = () => (
  <span className="text-xs font-bold px-2 py-1 bg-white rounded-full w-4 h-4 flex items-center justify-center gap-1 absolute -left-1 -top-1">
    <CheckCircleIcon className="text-[#ff8c00]" />
  </span>
);
