import { useOutletContext } from "react-router-dom";

// @components
import CurrentUserSubscription from "../../components/CurrentUserSubscription/CurrentUserSubscription";
import NoUserSubscription from "../../components/NoUserSubscription/NoUserSubscription";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import useScrollToTop from "../../hooks/useScrollToTop";

const UserSubscriptionSettings = () => {
  useScrollToTop();
  const { subscription } = useOutletContext();

  return (
    <section className="flex flex-col gap-3 py-1">
      <MetaTags
        path={`profile`}
        title={`Resagratia | Subscription`}
        desc={`Choose one of our subscription tailored for you.`}
      />
      <div className="">
        <h4 className="text-3xl leading-loose mb-4">Your Subscriptions</h4>
        {subscription?.isPaidSubscriber === true ? (
          <CurrentUserSubscription
            plan={subscription?.plan}
            endDate={subscription?.endDate}
            inProgress={subscription.inProgress}
            paymentPlan={subscription?.paymentPlan}
          />
        ) : (
          <NoUserSubscription />
        )}
      </div>
    </section>
  );
};

export default UserSubscriptionSettings;
