import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { getAuth, updateProfile } from "firebase/auth";
import { ToastContainer, toast } from "react-toastify";
import { updateUserSettings } from "../../redux/reducers/userSettingsSlice";
import "react-toastify/dist/ReactToastify.css";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import useScrollToTop from "../../hooks/useScrollToTop";
import ReferralLink from "../../components/ReferralLink/ReferralLink";

const UserProfile = () => {
  useScrollToTop();
  const { profile } = useOutletContext();
  const [displayName, setDisplayName] = useState(profile?.username);

  const auth = getAuth();
  const dispatch = useDispatch();
  const inputRef = useRef();

  const handleTextInput = (e) => {
    setDisplayName(e.target.value);
  };
  const updateDisplayName = () => {
    updateProfile(auth.currentUser, {
      displayName: displayName,
    })
      .then(() => {
        dispatch(updateUserSettings(true));
        toast.success("Profile updated successfully");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <section className="flex flex-col gap-16">
        <MetaTags
          path={`account/profile`}
          title={`Resagratia | ${auth.currentUser?.displayName} Profile`}
          desc={`Welcome ${auth.currentUser?.displayName} to your profile`}
        />
        <div className="">
          <h4 className="text-4xl leading-loose mb-4">
            Profile Overview
          </h4>
          <div className="flex flex-col gap-8 items-start">
            <div className="flex flex-row items-center gap-3 w-1/2">
              <span className="font-medium text-[#808080] text-sm">
                Username:
              </span>
              <input
                ref={inputRef}
                className="border border-transparent text-sm placeholder:font-light px-2 py-2 outline-none rounded-lg bg-[#f6f7f8]"
                type="text"
                value={displayName ? displayName : ""}
                onChange={handleTextInput}
              />
            </div>
            <div className="flex flex-row items-center gap-3 w-1/2">
              <span className="font-medium text-[#808080] text-sm">
                Company:
              </span>
              <span className="text-sm">{profile?.company}</span>
            </div>
            <ReferralLink profile={profile} />
          </div>
        </div>
        <div className="xs:w-full md:w-1/3">
          <button
            onClick={updateDisplayName}
            className="xs:w-full md:w-full text-sm font-bold text-white bg-[#0069ff] rounded px-4 py-3 hover:bg-[#005fe6]"
          >
            Save
          </button>
        </div>
      </section>
      <ToastContainer
        position="bottom-left"
        autoClose={5000}
        hideProgressBar={false}
        limit={1}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        className="react__toast"
      />
    </>
  );
};

export default UserProfile;
