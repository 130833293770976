import { useState } from 'react';
import feather from '../../feather-sprite.svg';

// @components
import SearchBoxModal from '../SearchBoxModal/SearchBoxModal';

const SearchField = (props) => {
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleOpenSearchModal = () => {
    setOpenSearchModal(true);
    props.setSearchOpen(true);
  };

  const handleCloseSearchModal = () => {
    setOpenSearchModal(false);
    props.setSearchOpen(false);
  };

  return (
    <>
      <div className=''>
        <div className=''>
          {props.type === 'mobile'  && openSearchModal === false ? (
            <div
              className={`flex items-center gap-2 cursor-pointer ${
                props.type === 'absolute' &&
                'border-2 border-solid border-[#f1f3f4] rounded py-3 px-3'
              }`}
              onClick={handleOpenSearchModal}
            >
              <svg
                width={props.width}
                height={props.height}
                fill='none'
                stroke={`${props.type === 'mobile' ? '#2b2c54' : '#878a8c'}`}
                strokeWidth='3'
                strokeLinecap='round'
                strokeLinejoin='round'
              >
                <use href={`${feather}#search`} />
              </svg>
            </div>
          ) : (
            props.type === 'inline' && (
              <SearchBoxModal
                placeholder={props.placeholder}
                type={props.type}
              />
            )
          )}
        </div>
      </div>

      {openSearchModal ? (
        <SearchBoxModal
          handleCloseSearchModal={handleCloseSearchModal}
          placeholder={props.placeholder}
          type={props.type}
        />
      ) : null}
    </>
  );
};

export default SearchField;
