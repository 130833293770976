import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const AuthenticatedPrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Navigate to='/dashboard' />;
  }

  return children || null;
};

export default AuthenticatedPrivateRoute;
