import { useState, useCallback, useEffect } from "react";
import { useLocation, Outlet } from "react-router-dom";
import { bootcampDates } from "../../constants/bootcamp/bootcampData";
import { useDispatch, useSelector } from "react-redux";
import { updateDisplayCohortDate } from "../../redux/reducers/timerSlice";
import dayjs from "dayjs";

// @components
import NavBar from "../../components/NavBar/NavBar";
import Footer from "../../components/Footer/Footer";
import WebAnnouncement from "../../components/WebAnnouncement/WebAnnouncement";
// import Loader from '../Loader/Loader';

// @paths
import { urls } from "../../constants/paths/paths";
import AppNotification from "../../components/AppNotification/AppNotification";
import { handleSuccessNotificationStatus } from "../../redux/reducers/bootcampSlice";
// import { Confetti } from '../../components/Confetti/Confetti';

const Layout = () => {
  const [closeBanner, setCloseBanner] = useState(true);

  const location = useLocation();
  const dispatch = useDispatch();
  const successNotificationStatus = useSelector(
    (state) => state.bootcamp.successNotificationStatus
  );

  const checkUrl = urls.map((url) => url.url);
  const q = checkUrl.find((el) => el === location.pathname);

  const handleCloseBanner = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setCloseBanner(false);
  };

  const updateNextBootcampdate = useCallback(() => {
    let parseDate;
    let index = [];
    const today = dayjs();

    for (let i = 0; i < bootcampDates.length; i++) {
      const firstBootcampDate = bootcampDates[i].dateToParse;
      parseDate = dayjs(firstBootcampDate);
      if (today.isBefore(parseDate, "day")) {
        index = [...index, i];
        let returnIndex = index[0];
        dispatch(
          updateDisplayCohortDate(bootcampDates[returnIndex].date)
        );
        // dispatch(updateCountdownTimer(bootcampDates[returnIndex].dateToParse));
      }
    }
  }, [dispatch]);

  useEffect(() => {
    updateNextBootcampdate();
  }, [updateNextBootcampdate]);

  useEffect(() => {
    if (successNotificationStatus) {
      setTimeout(() => {
        dispatch(handleSuccessNotificationStatus(false));
      }, 10000);
    }
  }, [successNotificationStatus]);

  return (
    <>
      {!q && closeBanner && (
        <WebAnnouncement
          handleCloseBanner={handleCloseBanner}
          closeBanner={closeBanner}
        />
      )}

      {q ? null : <NavBar closeBanner={closeBanner} />}

      {/* <Confetti /> */}

      <div
        className={`overflow-y-hidden ${
          location.pathname === q ? "lg:mt-0" : "pt-0"
        }`}
      >
        {successNotificationStatus && (
          <div className="fixed left-0 right-0 top-[3%] z-[20000] xs:px-4 md:px-0">
            <AppNotification text="Thank you for subscribing to the Resa Data Bootcamp. Details have been shared in your email." />
          </div>
        )}
        <Outlet />
      </div>

      {q ? null : <Footer />}
    </>
  );
};

export default Layout;
