import { createSlice } from '@reduxjs/toolkit';

export const quizSlice = createSlice({
  name: 'showQuiz',
  initialState: {
    quiz: false,
    quizIndex: null,
    quizTopicId: '',
    quizCourseId: '',
    quizTopicTitle: '',
    quizCourseTitle: '',
    retakeFromScore: false,
    checkAndShowScore: null,
    clickedQuizCompleted: false,
    questionHasInstructions: false,
  },
  reducers: {
    showQuiz: (state, action) => {
      state.quiz = action.payload;
    },
    selectQuizIndex: (state, action) => {
      state.quizIndex = action.payload;
    },
    selectQuizTopicId: (state, action) => {
      state.quizTopicId = action.payload;
    },
    selectQuizTopicTitle: (state, action) => {
      state.quizTopicTitle = action.payload;
    },
    selectQuizCourseTitle: (state, action) => {
      state.quizCourseTitle = action.payload;
    },
    selectQuizCourseId: (state, action) => {
      state.quizCourseId = action.payload;
    },
    selectCheckAndShowScore: (state, action) => {
      state.checkAndShowScore = action.payload;
    },
    selectRetakeFromScore: (state, action) => {
      state.retakeFromScore = action.payload;
    },
    handleClickedQuizCompleted: (state, action) => {
      state.clickedQuizCompleted = action.payload;
    },
    handleQuestionHasInstructions: (state, action) => {
      state.questionHasInstructions = action.payload;
    }
  },
});

export const {
  showQuiz,
  selectQuizIndex,
  selectQuizTopicId,
  selectQuizCourseId,
  selectQuizTopicTitle,
  selectQuizCourseTitle,
  selectRetakeFromScore,
  selectCheckAndShowScore,
  handleClickedQuizCompleted,
  handleQuestionHasInstructions,
} = quizSlice.actions;

export default quizSlice.reducer;
