// @components
import LearningPathStepper from '../../components/LearningPathStepper/LearningPathStepper';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import useScrollToTop from '../../hooks/useScrollToTop';

const tableauCoursePath = [
  {
    id: 1,
    title: `Introduction to Tableau`,
    connector: false,
  },
];

const TableauAnalystPath = () => {
  useScrollToTop();
  
  return (
    <>
      <MetaTags
        path={`learn/tableauviz-analyst-path`}
        title={`Resagratia | Tableau Viz Analyst path`}
        desc={
        `Take your Tableau skill to the next level`
        }
      />
      <LearningPathStepper
        id='tableau__path'
        title='Tableau Analyst Learning Path'
        data={tableauCoursePath}
        exitLink='/course/tableau'
      />
    </>
  );
};

export default TableauAnalystPath;
