// @styling
import "./index.scss";

// @assets
import Logo from "../../assets/logo.svg";

// Constant
import {
  globleLeaguePlaceholderList,
  BootcampLeagueListPlaceholder,
} from "../../constants/leagueTablePlaceholder";

// Authentication
import { useAuth } from "../../context/AuthContext";

// Packages
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

// @icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";

// @components
import SearchField from "../SearchField/SearchField";
import MobileNavbarMenuItems from "../MobileNavbarMenuItems/MobileNavbarMenuItems";

// Redux
import {
  handleBootcampType,
  handleNoBootcampData,
  handleUserEngagement,
  handleIsDataAnalytics,
  handleTotalEngagement,
  handleGlobalLeaderboard,
  handleBbootcampCohortData,
  handleBootcampCohortDetails,
  handleIsFetchingBootcamperDoc,
} from "../../redux/reducers/dashboardDataSlice";
import { updateUserSettings } from "../../redux/reducers/userSettingsSlice";
import {
  handleProgramAcess,
  handleSetPremiumRole,
} from "../../redux/reducers/setUserSubscriptionSlice";
import { handleProgramCoursesIndex } from "../../redux/reducers/toggleSlice";
import { handleBootcampStartDate } from "../../redux/reducers/bootcampStartDate";
import ExperiencePoint from "../ExperiencePoint/ExperiencePoint";

const AuthMobileNavBar = ({ closeBanner }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [showUpgradeBtn, setShowUpgradeBtn] = useState(false);
  const [navDisplayName, setNavDisplayName] = useState("");

  const { logout, currentUser } = useAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const userHasPaidRole = useSelector(
    (state) => state.userSubscription.premiumUser
  );
  const awaitingAccess = useSelector(
    (state) => state.userSubscription.awaitingAccess
  );
  const settingsUpdate = useSelector(
    (state) => state.userSettings.updateChanges
  );

  const truncate = (input) =>
    input.length > 10 ? input.substring(0, 9) + "..." : input;

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
    setSearchOpen(false);
  };

  const handleLogout = async () => {
    try {
      logout();
      localStorage.removeItem("CourseCompletionData");
      localStorage.removeItem("recentVideos");
      localStorage.removeItem("dataAnalyticsBootcampRoadmap-v3");
      localStorage.removeItem("dataScienceBootcampRoadmap-v4");
      sessionStorage.removeItem("userHasFreeRole");
      localStorage.removeItem("bootcampStartDate");
      dispatch(handleSetPremiumRole(false));
      dispatch(handleBootcampType(null));
      dispatch(handleBootcampStartDate(null));
      dispatch(handleIsDataAnalytics(true));
      dispatch(handleProgramCoursesIndex(0));
      dispatch(handleNoBootcampData(false));
      dispatch(handleProgramAcess(false));
      dispatch(handleUserEngagement(null));
      dispatch(handleTotalEngagement(null));
      dispatch(handleGlobalLeaderboard(globleLeaguePlaceholderList));
      dispatch(
        handleBbootcampCohortData(BootcampLeagueListPlaceholder)
      );
      dispatch(handleBootcampCohortDetails([]));
      dispatch(
        handleIsFetchingBootcamperDoc({
          status: false,
          isChecked: false,
        })
      );
      navigate("/", {
        state: {
          previousUrl: "/signin",
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowUpgradeBtn(true);
      settingsUpdate === true &&
        setNavDisplayName(currentUser?.displayName);
    }, 500);
    if (navDisplayName !== "") {
      dispatch(updateUserSettings(false));
    }

    return () => clearTimeout(timeout);
  }, [
    settingsUpdate,
    currentUser?.displayName,
    dispatch,
    navDisplayName,
  ]);

  return (
    <div
      className={`authmobile__navbar !sticky ${
        !closeBanner ? "!top-0" : "!top-[55px]"
      }`}
    >
      <div className="authmobile__navbar__container">
        {searchOpen === false && (
          <div className="authmobile__navbar__logo">
            <img
              src={Logo}
              alt="mobile logo"
            />
          </div>
        )}

        <div
          className={`authmobile__navbar__icon ${
            searchOpen === true ? "w-full" : "gap-[9px]"
          }`}
        >
          {/* {searchOpen === false && (
            <div className="mr-3">
              <Link to="/bootcamp">
                <h5 className="relative font-medium text-sm">
                  <span className="absolute -top-4 -right-3 bg-[#ff8c00] text-white rounded px-1">
                    New
                  </span>
                  Bootcamp
                </h5>
              </Link>
            </div>
          )} */}

          <SearchField
            width="22"
            height="22"
            type="mobile"
            placeholder="Search"
            setSearchOpen={setSearchOpen}
          />

          <ExperiencePoint />

          {searchOpen === false &&
            (menuOpen === false ? (
              <MenuRoundedIcon
                className="mobile__menu__icon"
                onClick={handleMenuOpen}
              />
            ) : (
              <CloseRoundedIcon
                className="close__icon"
                onClick={() => setMenuOpen(false)}
              />
            ))}
        </div>
      </div>

      {menuOpen ? (
        <div
          className={`${
            closeBanner === true
              ? "authmobile__navbar__elements__banner"
              : "authmobile__navbar__elements"
          }`}
        >
          <div className="">
            <div className="flex flex-row items-center w-full justify-between">
              <Link
                to="/account"
                onClick={() => setMenuOpen(false)}
              >
                <div className="flex items-center gap-1">
                  <AccountCircleRoundedIcon
                    style={{ color: "#808080" }}
                  />
                  {settingsUpdate ? (
                    <span className="text-sm">
                      {truncate(navDisplayName)}
                    </span>
                  ) : (
                    <span className="text-sm">
                      {truncate(currentUser?.displayName)}
                    </span>
                  )}
                </div>
              </Link>
              {location.pathname !== "/dashboard" && (
                <div className="authmobile__link__dashboard">
                  <Link
                    to="/dashboard"
                    onClick={() => setMenuOpen(false)}
                  >
                    <button>
                      <DashboardRoundedIcon className="dashboard__icon" />{" "}
                      Dashboard
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>

          <MobileNavbarMenuItems setMenuOpen={setMenuOpen} />

          <div className="flex flex-row items-center w-full justify-between">
            {showUpgradeBtn
              ? userHasPaidRole !== true &&
                !awaitingAccess && (
                  <Link
                    to="/pricing"
                    onClick={() => setMenuOpen(false)}
                  >
                    <button className="bg-[#222] text-[#ff8c00] font-bold p-3 rounded text-sm hover:bg-[#111]">
                      Upgrade
                    </button>
                  </Link>
                )
              : null}
            <div
              className="authmobile__logout__button"
              onClick={handleLogout}
            >
              <button>Logout</button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default AuthMobileNavBar;
