import React from 'react';

const AppNotification = ({ text, loader }) => {
  return (
    // <div className={`xs:w-full xs:px-4 md:px-0 md:container mx-auto`}>
    <div className="w-full">
      <div className='bg-[#111] rounded shadow-sm flex flex-row gap-6 justify-center p-3 xs:w-fit md:w-fit mx-auto'>
        {loader && <span className='relative spinner'></span>}
        <span className='text-sm text-white w-fit'>{text}</span>
      </div>
    </div>
  );
};

export default AppNotification;