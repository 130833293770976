import { Link } from 'react-router-dom';

const NavbarItemsCard = ({ title, path }) => {
  return (
    <div className='w-full'>
      <ul className=''>
        <Link className='inline-block' to={path}>
          <li className='my-2 text-xs font-light hover:text-[#2b2c54]'>{title}</li>
        </Link>
      </ul>
    </div>
  );
};

export default NavbarItemsCard;
