import "./index.scss";
import PromoCountdown from "../PromoCountdown/PromoCountdown";

export const NormalBannerText = ({
  hasCopiedCode,
  promoCodeText,
  handleCopyPromoCode,
}) => (
  <>
    <div className="ten-percent-off absolute left-2">
      <img
        className="w-20 h-20"
        src="https://storage.googleapis.com/resagratia-webapp/misc/10_prc_off_07_2024.svg"
        alt="10% off"
      />
    </div>
    <div className="flex w-full h-full max-w-[300px] image-container items-center gap-10 relative">
      <img
        className="w-20 h-20"
        src="https://storage.googleapis.com/resagratia-webapp/misc/10_prc_off_07_2024.svg"
        alt="10% off"
      />
      <img
        alt="left banner icons"
        className="w-full h-fit min-w-[116px]"
        src="https://storage.googleapis.com/resagratia-webapp/misc/left_banner_icons.png"
      />
      <img
        alt="left banner stars"
        className="h-full absolute -right-[88px] max-[980px]:-right-[50px] w-[87%]"
        src="https://storage.googleapis.com/resagratia-webapp/misc/left_banner_stars.png"
      />
    </div>
    <div className="flex flex-col items-center gap-1">
      <p className="text-[#f4bd52] max-[290px]:text-[9px] xs:text-xs lg:text-sm font-bold">
        Click to copy code{" "}
        <span
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation();
            handleCopyPromoCode();
          }}
          className="text-[#2b2c54] bg-white max-[290px]:text-[9px] rounded px-1 border border-white text-xs font-bold hover:bg-[#2b2c54] hover:text-white hover:border-[#2b2c54] cursor-pointer"
        >
          {hasCopiedCode
            ? (promoCodeText = "Copied!")
            : (promoCodeText = "FAST10")}
        </span>
        &nbsp; for a 10% off.
      </p>
      <div className="flex flex-row items-center gap-3 relative">
        <span className="text-xs text-white font-medium">
          Offer ends in
        </span>
        <PromoCountdown
          short
          parentStyle="flex flex-row items-center gap-1"
          childStyle="text-[#2b2c54] bg-white rounded max-[290px]:text-[9px] px-1 border border-white text-xs font-bold"
        />
      </div>
    </div>
    <div className="flex w-full max-w-[300px] image-container h-full items-center relative right-[45px] justify-end">
      <img
        className="w-full h-fit"
        src="https://storage.googleapis.com/resagratia-webapp/misc/right_banner_icons.png"
        alt="Banner icons"
      />
      <img
        className="h-fit absolute -right-8 max-w-[134%]"
        src="https://storage.googleapis.com/resagratia-webapp/misc/right_banner_stars.png"
        alt="Banner stars"
      />
    </div>
  </>
);
