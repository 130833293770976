import React from 'react';
import feather from '../../feather-sprite.svg';

const LearningPathCourseList = ({
  title,
  auth,
  unauth,
  connector,
  stepper,
}) => {
  return (
    <>
      <div className='xs:hidden md:block'>
        <div className='flex flex-col gap-6'>
          <div className='flex flex-row items-center'>
            <div className=''>
              <span className='w-[25px] h-[25px] rounded-full bg-[#f4bd52] text-sm flex flex-col justify-center items-center'>
                <svg
                  width='14'
                  height='14'
                  fill='none'
                  stroke='#2b2c54'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <use href={`${feather}#check`} />
                </svg>
              </span>
            </div>
            {connector && (
              <div className='border-b-2 border-b-[#f4bd52] w-[80px] mx-4'></div>
            )}
          </div>
          <div className='flex flex-col'>
            <span className='text-[9px] uppercase font-medium text-[#f4bd52]'>
              step {stepper}
            </span>
            <span className='text-xs font-bold text-white w-[120px]'>
              {title}
            </span>
          </div>
        </div>
      </div>

      <div className='xs:block md:hidden'>
        <div className='flex flex-row gap-3'>
          <div className=''>
            <div className=''>
              <span className='w-[25px] h-[25px] rounded-full bg-[#f4bd52] text-sm flex flex-col justify-center items-center'>
                <svg
                  width='14'
                  height='14'
                  fill='none'
                  stroke='#2b2c54'
                  strokeWidth='3'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <use href={`${feather}#check`} />
                </svg>
              </span>
            </div>
            {connector && (
              <div className='border-l border-l-[#f4bd52] h-[80px] w-[10px] mx-3'></div>
            )}
          </div>
          <div className={`${!connector && 'mx-3'} h-fit w-fit`}>
            <div className='flex flex-col'>
              <span className='text-[9px] uppercase font-medium text-[#f4bd52]'>
                step {stepper}
              </span>
              <span className='text-xs font-bold text-white w-fit'>
                {title}
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LearningPathCourseList;
