import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const AuthenticatedAuthorPrivateRoute = ({ children }) => {
  const isAuthorRole = useSelector(
    (state) => state.userSubscription.authorRole
  );

  if (!isAuthorRole) return <Navigate to="/dashboard" />;

  return children || null;
};

export default AuthenticatedAuthorPrivateRoute;
