// @utils
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db } from "../../firebase/firebase";
import { useAuth } from "../../context/AuthContext";
// import Toast from "../../utils/Toast/Toast";
// import ToggleSwitch from "../../utils/ToogleSwitch/ToggleSwitch";
// import Buttons from "../Buttons/Buttons";

const CurrentUserSubscription = ({
  plan,
  endDate,
  paymentPlan,
  inProgress,
}) => {
  const [showPlanSubModal, setShowPlanSubModal] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    (async () => {
      const bootcampQuery = query(
        collection(db, "bootcampers"),
        orderBy("firestoreTimestamp", "desc"),
        where("email", "==", currentUser.email)
      );
      const bootcampSnapshot = await getDocs(bootcampQuery);
      const isCancelled = bootcampSnapshot?.docs[0]?.data()?.cancel;
      setIsCancelled(isCancelled);
    })();
  }, [isCancelling]);

  const handleCancelPaymentPlan = async () => {
    try {
      setIsCancelling(true);
      const bootcampQuery = query(
        collection(db, "bootcampers"),
        orderBy("firestoreTimestamp", "desc"),
        where("email", "==", currentUser.email)
      );
      const bootcampSnapshot = await getDocs(bootcampQuery);
      const docId = bootcampSnapshot?.docs[0]?.id;
      const docRef = doc(db, "bootcampers", docId);

      const updateFields = {
        paymentRetry: 6,
        cancel: true,
      };
      // Update the document in Firestore
      await updateDoc(docRef, updateFields);
      setIsCancelling(false);
      setShowPlanSubModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="flex flex-col gap-8">
      <section className="flex flex-col gap-8 items-start pb-9">
        <div className="w-full flex flex-col gap-2">
          <span className="text-[#111] font-bold text-base">Current plan</span>
          <span className="text-sm text-[#808080]">
            You're currently on the{" "}
            <span className="font-extrabold capitalize">{plan && plan} </span>
            plan.
          </span>
        </div>
        <div className="w-full flex flex-col gap-2">
          <span className="text-[#111] font-bold text-base">Expiry</span>
          {!endDate ? (
            plan === "Admin" ? (
              <span className="text-sm text-[#808080]">
                <span className="font-extrabold capitalize">N/A</span>.
              </span>
            ) : (
              <span className="text-sm text-[#808080]">
                Your current plan has{" "}
                <span className="font-extrabold capitalize">expired</span>.
              </span>
            )
          ) : (
            <span className="text-sm text-[#808080]">
              Your current plan will expire in{" "}
              <span className="font-extrabold capitalize">{endDate} </span>.
            </span>
          )}
        </div>
        <div className="w-full flex flex-col gap-2">
          <span className="text-[#111] font-bold text-base">Payment Plan</span>
          {!paymentPlan && inProgress && endDate ? (
            <span className="text-sm text-[#808080]">
              Payment {isCancelled ? " is" : " in"}{" "}
              <span className="font-extrabold capitalize">
                {isCancelled ? "cancelled" : "progress"}
              </span>
              .
            </span>
          ) : !paymentPlan || !endDate || plan === "Admin" ? (
            <span className="text-sm text-[#808080]">
              <span className="font-extrabold capitalize">N/A</span>
            </span>
          ) : (
            <span className="text-sm text-[#808080]">
              Your payment plan is{" "}
              <span className="font-extrabold capitalize">complete</span>.
            </span>
          )}
          {inProgress && (
            <button
              onClick={() => {
                !isCancelled && setShowPlanSubModal(!showPlanSubModal);
              }}
              className={`w-max ${
                isCancelled
                  ? "text-[#808080] bg-[#e6e6e6] cursor-not-allowed"
                  : "text-[#C94A4A] bg-[#FEE2E2]"
              } rounded px-5 py-2`}
            >
              {isCancelled ? "Cancelled" : "Cancel plan"}
            </button>
          )}
        </div>
      </section>
      {showPlanSubModal && (
        <CancelWarning
          isCancelling={isCancelling}
          setShowPlanSubModal={setShowPlanSubModal}
          handleCancelPaymentPlan={handleCancelPaymentPlan}
        />
      )}

      {/* <section className="">
        <div className="mt-3 mb-9">
          <Toast
            notice
            message="You can manage your recurring subscriptions here."
            icon="info"
            width="xs:w-full md:w-1/2"
          />
        </div>

        <div className="flex flex-row justify-between bg-white border border-[#f1f3f4] rounded xs:w-full md:w-1/2 p-3">
          <span>Recurring subscription</span>
          <span><ToggleSwitch /></span>
        </div>
      </section> */}
    </div>
  );
};

function CancelWarning({
  setShowPlanSubModal,
  handleCancelPaymentPlan,
  isCancelling,
}) {
  return (
    <div
      onClick={(e) => {
        setShowPlanSubModal(false);
      }}
      className="fixed inset-0 bg-[#00000085] z-[10000000000] p-2"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="text-[#5f5e5e] md:px-12 md:py-8 px-8 py-6 font-normal bg-white m-auto rounded mt-24 max-w-md"
      >
        <p className="text-justify">
          You are about to opt out of the payment plan, this will prevent your
          card from being charged in the next billing cycle.
        </p>
        <p className="mt-3 text-justify">
          You will also lose your subscription and access to the platform will
          be revoked.
        </p>
        <div className="block w-full font-medium text-center mt-3">
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowPlanSubModal(false);
            }}
            className="rounded w-full bg-[#2b2c54] font-medium py-3 px-2 text-white text-sm hover:bg-[#15175c]"
          >
            Cancel
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleCancelPaymentPlan();
            }}
            className="mt-6 text-black font-medium text-center"
          >
            {isCancelling ? "Cancelling.." : "Proceed"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default CurrentUserSubscription;
