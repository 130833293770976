import { useEffect, useRef, useState } from "react";

export const useScrollBarHandler = (scrollLength) => {
  const [leftButtonDisabled, setLeftButtonDisabled] = useState(false);
  const [rightButtonDisabled, setRightButtonDisabled] =
    useState(false);
  const ref = useRef(null);

  const handleLeftBackwardScroll = () => {
    ref.current.scrollLeft -= scrollLength;
  };

  const handleLeftForwardScroll = () => {
    ref.current.scrollLeft += scrollLength;
  };

  const disableOrEnableScrollButton = () => {
    const container = ref.current;
    if (container) {
      const atStart = container.scrollLeft === 0;
      const atEnd =
        container.scrollLeft + container.clientWidth + 10 >=
        container.scrollWidth;
      const disableLeftButton = atStart;
      const disableRightButton = atEnd;

      setLeftButtonDisabled(disableLeftButton);
      setRightButtonDisabled(disableRightButton);
    }
  };

  // Disbale left scroll on initial page load
  useEffect(() => {
    setLeftButtonDisabled(true);
  }, []);

  return {
    ref,
    leftButtonDisabled,
    rightButtonDisabled,
    handleLeftForwardScroll,
    handleLeftBackwardScroll,
    disableOrEnableScrollButton,
  };
};
