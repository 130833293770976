import { Fragment, useEffect, useRef, useState } from "react";
import { SectionLayout } from "../../utils/SectionLayout";
import { FAQs } from "./constant/constants";
import useInView from "../../hooks/useInView";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebase/firebase";

export const FrequentlyAskedQuestions = () => {
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const isInView = useInView("faq_component", {
    threshold: 0.1,triggerOnce: true,
  });

  useEffect(() => {
    if (isInView) {
      logEvent(analytics, "faq_component", {
        category: "scroll",
      });
    }
  }, [isInView]);

  const handleItemClick = (index) => {
    setOpenItemIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  return (
    <SectionLayout style="bg-[#2B2C54] pt-[2.21775rem] pb-[3.326625rem]">
      <p className="text-white mb-[1rem]">FAQs</p>
      <h2 className="font-normal text-[2.21775rem] mb-[1.7742rem] leading-[2.6rem] text-white sectionTitle">
        Frequently Asked <br />
        <span className="text-[#FF8C00]">Questions</span>
      </h2>

      <div
        id="faq_component"
        className="flex justify-between gap-4 max-[980px]:flex-col"
      >
        <ul className="flex flex-wrap flex-col gap-5">
          {FAQs.slice(0, 4).map((question) => (
            <Fragment key={question.question}>
              <FAQItem
                question={question}
                isOpen={openItemIndex === question.id}
                onClick={() => handleItemClick(question.id)}
              />
            </Fragment>
          ))}
        </ul>
        <ul className="flex flex-wrap flex-col gap-5">
          {FAQs.slice(4).map((question) => (
            <Fragment key={question.question}>
              <FAQItem
                question={question}
                isOpen={openItemIndex === question.id}
                onClick={() => handleItemClick(question.id)}
              />
            </Fragment>
          ))}
        </ul>
      </div>
    </SectionLayout>
  );
};

const FAQItem = ({ question, onClick, isOpen }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isOpen]);

  return (
    <li
      key={question.question}
      className="border border-[#ffffffa1] px-5 py-2 w-full max-w-lg max-[980px]:max-w-full text-white rounded h-min min-h-[4.1875rem] flex flex-col justify-center"
    >
      <button
        onClick={onClick}
        className="flex justify-between w-full"
      >
        <h5 className="text-left">{question.question}</h5>{" "}
        <RightArrowIcon
          isOpen={isOpen}
          onClick={onClick}
        />
      </button>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300 ease-in`}
        style={{ height: isOpen ? `${contentHeight}px` : "0" }}
      >
        <hr className="my-4 mt-5 border-[#ffffff76]" />
        {question.answer.map((paragraph) => (
          <p
            key={paragraph}
            className={`mt-[1rem] text-[0.96875rem] text-white leading-[1.33065rem]`}
          >
            {paragraph}
          </p>
        ))}
        <ul className="pl-8  list-disc pb-1">
          {question?.list &&
            question?.list.map((item) => (
              <li
                key={item}
                className="text-[0.96875rem] text-white leading-[1.33065rem] list-item"
              >
                {item}
              </li>
            ))}
        </ul>
      </div>
    </li>
  );
};

export function RightArrowIcon({ isOpen, onClick, deg, color, style }) {
  return (
    <svg
      {...(deg && { onClick: onClick })}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      className={`duration-300 ease-in cursor-pointer ${style ? style : ""}`}
      style={{
        transform: isOpen
          ? deg
            ? deg
            : "rotate(90deg)"
          : deg
          ? "rotate(-90deg)"
          : "rotate(0deg)",
      }}
    >
      <g
        onClick={onClick}
        clipPath="url(#clip0_2430_18)"
      >
        <path
          onClick={onClick}
          fill={`${color ? color : "#FF8C00"}`}
          fillRule="evenodd"
          d="M15.708 11.293a1 1 0 010 1.414l-5.657 5.657a1 1 0 11-1.414-1.414l4.95-4.95-4.95-4.95a1 1 0 011.414-1.414l5.657 5.657z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs onClick={onClick}>
        <clipPath
          onClick={onClick}
          id="clip0_2430_18"
        >
          <path
            onClick={onClick}
            fill="#fff"
            d="M0 0H24V24H0z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
