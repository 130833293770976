import React from 'react';
import feather from '../../feather-sprite.svg';

const SearchBoxModal = (props) => {
  return (
    <>
      {props.type === 'inline' && (
        <div className='relative flex items-center border border-solid border-[#edeff1] rounded-lg'>
          <div className='absolute left-2'>
            <svg
              width='18'
              height='18'
              fill='none'
              stroke='#878a8c'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='cursor-pointer'
            >
              <use href={`${feather}#search`} />
            </svg>
          </div>
          <input
            className='border border-transparent rounded-lg xs:text-base md:text-sm placeholder:text-sm placeholder:text-gray placeholder:font-light px-9 py-1.5 outline-none text-sm bg-[#f6f7f8] xs:w-full'
            type='text'
            placeholder={props.placeholder}
          />
        </div>
      )}

      {props.type === 'mobile' && (
        <div className='w-full relative flex items-center border-2 border-solid border-[#edeff1] rounded-lg'>
          <div className='absolute left-2'>
            <svg
              width='18'
              height='18'
              fill='none'
              stroke='#878a8c'
              strokeWidth='3'
              strokeLinecap='round'
              strokeLinejoin='round'
              className='cursor-pointer'
            >
              <use href={`${feather}#search`} />
            </svg>
          </div>
          <input
            className='w-full border border-transparent xs:text-base placeholder:text-sm placeholder:font-light px-9 py-2 outline-none rounded-lg bg-[#f6f7f8]'
            type='text'
            placeholder={props.placeholder}
          />
          <div
            className='absolute right-2 cursor-pointer'
            onClick={props.handleCloseSearchModal}
          >
            <button className='text-sm text-[#878a8c]'>Cancel</button>
            {/* <svg
              width='18'
              height='18'
              fill='none'
              stroke='#878a8c'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            >
              <use href={`${feather}#x-circle`} />
            </svg> */}
          </div>
        </div>
      )}
    </>
  );
};

export default SearchBoxModal;
