// @components
import LearningPathStepper from '../../components/LearningPathStepper/LearningPathStepper';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import useScrollToTop from '../../hooks/useScrollToTop';

const sqlCoursePath = [
  {
    id: 1,
    title: `SQL Game of Thrones`,
    connector: true,
  },
  {
    id: 2,
    title: `Data Analysis With SQL`,
    connector: true,
  },
  {
    id: 3,
    title: `SQL For Data Science`,
    connector: false,
  },
];

const SqlAnalystPath = () => {
  useScrollToTop();
  
  return (
    <>
      <MetaTags
        path={`learn/sql-analyst-path`}
        title={`Resagratia | SQL Analyst path`}
        desc={
        `Take your SQL skill to the next level`
        }
      />
      <LearningPathStepper
        id='sql__path'
        title='SQL Analyst Learning Path'
        data={sqlCoursePath}
        exitLink='/course/sql-game-of-thrones'
      />
    </>
  );
};

export default SqlAnalystPath;
