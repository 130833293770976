import { createSlice } from "@reduxjs/toolkit";

export const experiencePointSlice = createSlice({
  name: "experiencePointSlice",
  initialState: {
    experiencePoint: null,
  },
  reducers: {
    handleExperiencePoint: (state, action) => {
      state.experiencePoint = action.payload;
    },
  },
});

export const { handleExperiencePoint } = experiencePointSlice.actions;

export default experiencePointSlice.reducer;
