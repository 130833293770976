// @styling
import "./faqlist.scss";

// Packages
import { useEffect, useRef, useState } from "react";

// Components
import { RightArrowIcon } from "../../screens/BootcampLandingPage/FAQ";

const FaqList = ({ question, answer, isFaqExpanded, handleFaqExpanded }) => {
  const contentRef = useRef(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight);
    }
  }, [isFaqExpanded]);

  return (
    <div className="faqlist">
      <div className="faqlist__items">
        <li>
          <div className="faqlist__details">
            <div
              className="faqlist__question overflow-hidden transition-all duration-300 ease-in"
              onClick={handleFaqExpanded}
            >
              <h3>{question}</h3>
              <RightArrowIcon
                isOpen={isFaqExpanded}
                onClick={handleFaqExpanded}
              />
            </div>
            <div
              ref={contentRef}
              style={{
                height: isFaqExpanded ? `${contentHeight}px` : "0",
              }}
              className="overflow-hidden transition-all duration-300 ease-in"
            >
              {typeof answer === "object" ? (
                answer.map((item, index) => (
                  <p
                    key={index}
                    className="faqlist__answer py-4"
                  >
                    {item}
                  </p>
                ))
              ) : (
                <div className="faqlist__answer py-4">
                  <p>{answer}</p>
                </div>
              )}
            </div>
          </div>
        </li>
      </div>
    </div>
  );
};

export default FaqList;
