export const globleLeaguePlaceholderList = [
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
  {
    placeholder: "loading...",
  },
];

export const BootcampLeagueListPlaceholder = [
  {
    xp: 0.0,
    quiz_Xp: 0.0,
    mentor_xp: 0.0,
    usage_xp: 0.0,
    name: "Loading...",
  },
  {
    xp: 0.0,
    quiz_Xp: 0.0,
    mentor_xp: 0.0,
    usage_xp: 0.0,
    chort: 0.0,
    name: "Loading...",
  },
  {
    xp: 0.0,
    quiz_Xp: 0.0,
    mentor_xp: 0.0,
    usage_xp: 0.0,
    name: "Loading...",
  },
  {
    xp: 0.0,
    quiz_Xp: 0.0,
    mentor_xp: 0.0,
    usage_xp: 0.0,
    chort: "9.0",
    name: "Loading...",
  },
  {
    xp: 0.0,
    quiz_Xp: 0.0,
    mentor_xp: 0.0,
    usage_xp: 0.0,
    chort: 0.0,
    name: "Loading...",
  },
];
