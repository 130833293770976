import dayjs from "dayjs";

export const formatTime = (inputTime) => {
  const milliseconds =
    inputTime.seconds * 1000 +
    Math.floor(inputTime.nanoseconds / 1e6);
  const formattedTime = dayjs(milliseconds).format(
    "DD-MM-YYYY HH:mm"
  );

  return formattedTime;
};
