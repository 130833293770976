import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getTimeLeftOnCountdown } from "../../utils/WebsiteTimer/WebsiteTimer";

const defaultTime = {
  seconds: "00",
  minutes: "00",
  hours: "00",
  days: "00",
};

const PromoCountdown = ({
  parentStyle,
  childStyle,
  timeStyle,
  dayStyle,
  short,
}) => {
  const [timeLeft, setTimeLeft] = useState(defaultTime);
  const countdownState = useSelector(
    (state) => state.promoCountdown.countdownDate
  );

  const updatePromoTimeLeft = (time) => {
    setTimeLeft(getTimeLeftOnCountdown(time));
  };

  useEffect(() => {
    const int = setInterval(() => {
      updatePromoTimeLeft(countdownState);
    }, 1000);
    return () => clearInterval(int);
  }, [countdownState]);

  return (
    <div className={parentStyle}>
      <div className={`${childStyle} rounded-tl rounded-bl pl-1`}>
        <span className={timeStyle}>{timeLeft.days}</span>
        <span className={dayStyle}>{short ? "d" : "days"}</span>
      </div>
      <div className={childStyle}>
        <span className={timeStyle}>{timeLeft.hours}</span>
        <span className={dayStyle}>{short ? "h" : "hrs"}</span>
      </div>
      <div className={childStyle}>
        <span className={timeStyle}>{timeLeft.minutes}</span>
        <span className={dayStyle}>{short ? "m" : "mins"}</span>
      </div>
      <div className={`${childStyle} rounded-tr rounded-br pr-1`}>
        <span className={`${timeStyle} inline-block w-4 text-center`}>{timeLeft.seconds}</span>
        <span className={dayStyle}>
          {short ? "s" : "sec"} {`>`}
        </span>
      </div>
    </div>
  );
};

export default PromoCountdown;
