import { useSelector } from "react-redux";
import { PaystackButton } from "react-paystack";

import { customRound } from "./helper/helper";
import Buttons from "../../components/Buttons/Buttons";
import { useSearchParams } from "react-router-dom";

export function PlanSubscriptionModal({
  toggle,
  isChecked,
  paymentPlanConfig,
  isFormFillComplete,
  setShowPlanSubModal,
  FEE_FOR_PAYING_TWICE,
  FIX_MS_OFFICE_AMOUNT_IN_USD,
  FIX_MS_OFFICE_AMOUNT_IN_NAIRA,
}) {
  const [searchParams] = useSearchParams();
  const bootCampType = searchParams.get("path");
  // No promo code is allowed to be used
  // The payment plan amount remains the actual amount divid by 2
  const amount = bootCampType === "data-science" ? 90 : 70;
  const exchangeRate = useSelector((state) =>
    bootCampType === "data-science"
      ? state.getAmount.dsExchangeRate
      : state.getAmount.exchangeRate
  );

  const paymentPlanComponentProps = {
    ...paymentPlanConfig,
    amount: toggle
      ? customRound(
          customRound(
            ((amount +
              (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)) *
              FEE_FOR_PAYING_TWICE) /
              2
          ) * 100
        )
      : customRound(
          customRound(
            ((amount * exchangeRate +
              (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)) *
              FEE_FOR_PAYING_TWICE) /
              2
          ) * 100
        ),
    text: `${
      amount !== ""
        ? `Pay ${toggle ? "USD" : "NGN"} ${
            toggle
              ? customRound(
                  ((amount +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
              : customRound(
                  ((amount * exchangeRate +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
          } ${isChecked ? "(MS Office inclusive)" : ""}`
        : "Procced with payment"
    }`,
  };

  return (
    <div
      onClick={(e) => {
        setShowPlanSubModal(false);
      }}
      className="fixed inset-0 bg-[#00000085] z-[10000000000] p-2"
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="text-[#5f5e5e] p-11 md:p-14 md:px-[82px] font-normal bg-white m-auto rounded mt-24 max-w-xl"
      >
        <p>
          You're on the verge of joining the{" "}
          <strong className="text-black">Resa Data Bootcamp</strong>{" "}
          with our convenient payment plan. Remember, you have the
          flexibility to cancel anytime.
        </p>
        <p className="mt-3">
          An initial payment of{" "}
          <strong className="text-black">{`${toggle ? "USD" : "N"}${
            toggle
              ? customRound(
                  ((amount +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
              : customRound(
                  ((amount * exchangeRate +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
          } ${
            isChecked ? "(MS Office inclusive)" : ""
          }`}</strong>{" "}
          will be charged to your account today, followed by another
          payment of{" "}
          {`${toggle ? "USD" : "N"}${
            toggle
              ? customRound(
                  ((amount +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_USD : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
              : customRound(
                  ((amount * exchangeRate +
                    (isChecked ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA : 0)) *
                    FEE_FOR_PAYING_TWICE) /
                    2
                ).toLocaleString()
          }`}{" "}
          in <strong className="text-black">30 days</strong> to
          complete your enrollment.
        </p>
        <div className="block w-full font-medium text-center mt-3">
          <div className="xs:w-full">
            {isFormFillComplete ? (
              <Buttons
                style={`bg-[#f1f3f4] text-sm text-[#111] w-full py-3 px-2 font-bold w-full] cursor-not-allowed`}
              >
                {amount !== ""
                  ? `Pay ${
                      toggle
                        ? customRound(
                            customRound(
                              ((amount +
                                (isChecked
                                  ? FIX_MS_OFFICE_AMOUNT_IN_USD
                                  : 0)) *
                                FEE_FOR_PAYING_TWICE) /
                                2
                            ) * 100
                          ).toLocaleString()
                        : customRound(
                            customRound(
                              ((amount * exchangeRate +
                                (isChecked
                                  ? FIX_MS_OFFICE_AMOUNT_IN_NAIRA
                                  : 0)) *
                                FEE_FOR_PAYING_TWICE) /
                                2
                            ) * 100
                          ).toLocaleString()
                    }`
                  : "Procced with payment"}
              </Buttons>
            ) : (
              <PaystackButton
                {...paymentPlanComponentProps}
                className="rounded w-full bg-[#2b2c54] font-medium py-3 px-2 text-white text-sm hover:bg-[#15175c]"
              />
            )}
          </div>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowPlanSubModal(false);
            }}
            className="mt-6 text-black font-semibold"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
}
