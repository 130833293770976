import { Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../context/AuthContext';

const ActivationFormPrivateRoute = ({ children }) => {
  const { currentUser } = useAuth();

  const checkBeforeFormUnmounts = useSelector(
    (state) => state.activationForm.checkBeforeUnmount
  );

  if (checkBeforeFormUnmounts) {
    if (currentUser && currentUser.displayName === null) {
      return <Navigate to='/user-details' replace />;
    }
  }

  if (!currentUser) {
    return <Navigate to='/' />;
  }

  <Outlet />;

  return children || null;
};

export default ActivationFormPrivateRoute;
