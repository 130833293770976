import { Helmet } from "react-helmet-async";

export function MetaTags({ title, desc, path, canonical, xPreviewImageLink, fbPreviewImageLink }) {
  return (
    <Helmet>
      {/* Search Engine */}
      <title>{title}</title>
      {canonical && <link rel="canonical" href={canonical} />} 
      <meta name="description" content={desc} />

      {/* Facebook & LinkedIn */}
      <meta property="og:url" content={`https://www.resagratia.com/${path}`} />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      {fbPreviewImageLink && <meta property="og:image" content={fbPreviewImageLink} />}

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta property="twitter:domain" content="https://www.resagratia.com" />
      <meta
        property="twitter:url"
        content={`https://www.resagratia.com/${path}`}
      />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      {xPreviewImageLink &&  <meta name="twitter:image" content={xPreviewImageLink} />}
    </Helmet>
  );
}
