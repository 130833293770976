import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export const RichText = ({
  addOn,
  fullWidth,
  placeholder,
  additionalFeedback,
  setAdditionalFeedback,
}) => {
  const modules = {
    toolbar: [
      [{ header: [4, false] }],
      [
        "bold",
        "italic",
        "underline",
        ...(addOn ? [] : ["strike"]),
        ...(addOn ? [] : ["blockquote"]),
      ],
      [
        { list: "ordered" },
        { list: "bullet" },
        ...(addOn ? [] : [{ indent: "-1" }]),
        ...(addOn ? [] : [{ indent: "+1" }]),
      ],
      ["link" /**"image"*/],
      [{ color: [] }],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    ...(addOn ? [] : ["strike"]),
    ...(addOn ? [] : ["blockquote"]),
    "list",
    "bullet",
    ...(addOn ? [] : ["indent"]),
    "link",
    // "image",
    "color",
  ];

  return (
    <div className={`w-full mt-5 ${fullWidth ? "" : "max-w-lg"}`}>
      <ReactQuill
        theme="snow"
        modules={modules}
        formats={formats}
        value={additionalFeedback}
        onChange={setAdditionalFeedback}
        placeholder={`${
          placeholder
            ? placeholder
            : "Write your thought on this project here..."
        }`}
      />
    </div>
  );
};
