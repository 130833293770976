import { createSlice } from "@reduxjs/toolkit";

export const toggleSlice = createSlice({
  name: "toogle",
  initialState: {
    toggle: false,
    programCoursesIndex: 0,
    toggleSignupModal: false,
  },
  reducers: {
    handleToggle: (state, action) => {
      state.toggle = action.payload;
    },
    handleToggleSignupModal: (state, action) => {
      state.toggleSignupModal = action.payload;
    },
    handleProgramCoursesIndex: (state, action) => {
      state.programCoursesIndex = action.payload;
    },
  },
});

export const {
  handleToggle,
  handleToggleSignupModal,
  handleProgramCoursesIndex,
} = toggleSlice.actions;

export default toggleSlice.reducer;
