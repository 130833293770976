import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const SelectionMarker = ({ alignPosition }) => (
  <span
    className={`absolute ${
      alignPosition ? alignPosition : "-top-2 -left-2"
    } z-10`}
  >
    <CheckCircleIcon fontSize="small" />
  </span>
);
