import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @components
import TabNavItem from '../TabNavItem/TabNavItem';
import TabContent from '../TabContent/TabContent';
// import UserSettingsMobilePane from '../UserSettingsMobilePane/UserSettingsMobilePane';

// @icons
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';

const UserSettingsSidebar = ({ profile, subscription, achievements }) => {
  const { pathname } = useLocation();
  const [activeTab, setActiveTab] = useState('tab1');

  useEffect(() => {
    if (pathname === "/account/achievements") setActiveTab("tab3");
    else if (pathname === "/account/subscription") setActiveTab("tab2");
    else if (pathname === "/account/profile") setActiveTab("tab1");
  },[]);

  const nav = [
    {
      id: 'profile',
      title: 'Profile',
      path: '/account/profile',
      tabId: 'tab1',
      component: <Outlet context={{ profile }} />,
      icon: <AccountCircleRoundedIcon fontSize='small' />,
    },
    {
      id: 'subscription',
      title: 'Subscription',
      path: '/account/subscription',
      tabId: 'tab2',
      component: <Outlet context={{ subscription }} />,
      icon: <PaidRoundedIcon fontSize='small' />,
    },
    {
      id: 'achievements',
      title: 'Achievements',
      path: '/account/achievements',
      tabId: 'tab3',
      component: <Outlet context={{ achievements }} />,
      icon: <PaidRoundedIcon fontSize='small' />,
    },
  ];

  const width = useSelector((state) => state.windowDimension.width);

  return (
    <>
      {width <= 768 ? (
        <div className='tabs'>
          <ul className='text-sm flex flex-row gap-3'>
            {nav.map((item, index) => (
              // <UserSettingsMobilePane
              //   key={index}
              //   title={item.title}
              //   path={item.path}
              //   component={item.component}
              // />
              <TabNavItem
                key={index}
                title={item.title}
                id={item.tabId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                path={item.path}
              />
            ))}
          </ul>
          <div className='container mx-auto tab__outlet'>
            {nav.map((item, index) => (
              <TabContent
                key={index}
                id={item.tabId}
                activeTab={activeTab}
                path={item.path}
                setActiveTab={setActiveTab}
              >
                <div key={index} className=''>
                  {item.component}
                </div>
              </TabContent>
            ))}
          </div>
        </div>
      ) : (
        <div className=''>
          <div className='tabs'>
            <ul className='text-sm flex flex-row gap-3'>
              {nav.map((item, index) => (
                <TabNavItem
                key={index}
                title={item.title}
                id={item.tabId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                path={item.path}
              />
              ))}
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default UserSettingsSidebar;
