import ReactPlayer from "react-player";

export const SectionLayout = ({
  id,
  style,
  videoUrl,
  children,
  relative,
  setVideoUrl,
}) => {
  return (
    <section
      {...(id && { id: id })}
      className={`px-4 ${style ? style : ""}`}
    >
      <div
        className={`max-w-[68.75rem] w-full mx-auto overflow-hidden ${
          relative ? "relative" : ""
        }`}
      >
        {children}
      </div>
      {videoUrl && (
        <VideoPlayerModal
          videoUrl={videoUrl}
          setVideoUrl={setVideoUrl}
        />
      )}
    </section>
  );
};

const VideoPlayerModal = ({ videoUrl, setVideoUrl }) => {
  return (
    <>
      {videoUrl && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            setVideoUrl("");
          }}
          className="fixed inset-0 bg-[#0000008a] flex items-center justify-center z-[100000000000]"
        >
          <div className="flex-1">
            <ReactPlayer
              url={videoUrl}
              controls={true}
              playing={true}
              className={`min-[1100px]:[&:first-child]:!w-[219px] [&:first-child]:!w-[242px] min-[1100px]:[&:first-child]:!h-[390px] [&:first-child]:!h-[440px] m-auto !w-full !h-full`}
            />
          </div>
        </div>
      )}
    </>
  );
};
