import { useDispatch, useSelector } from 'react-redux';
import { handleToggle } from '../../redux/reducers/toggleSlice'

// @styling
import './index.scss';

const ToggleSwitch = ({
  leftText,
  rightText,
  spanText,
}) => {
  const dispatch = useDispatch()
  const toggle = useSelector((state) => state.toggle.toggle)

  const handleUserToggle = () => {
    dispatch(handleToggle(!toggle))
  }

  return (
    <>
      <div className='toggle__switch'>
        <p style={{ fontWeight: !toggle && 600, color: !toggle && '#2b2c54' }}>
          {leftText}
        </p>

        <input
          className='toggle__switch__checkbox'
          id='toggle__switch__new'
          type='checkbox'
          checked={toggle}
          onChange={handleUserToggle}
        />
        <label className='toggle__switch__label' htmlFor='toggle__switch__new'>
          <span
            className='toggle__switch__button'
            style={{ background: toggle && '#F4BD52' }}
          />
        </label>

        <p style={{ fontWeight: toggle && 600, color: toggle && '#2b2c54' }}>
          {rightText}
          {spanText && <span>{spanText}</span>}
        </p>
      </div>
    </>
  );
};

export default ToggleSwitch;
