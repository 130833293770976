import { createSlice } from "@reduxjs/toolkit";

export const setUserSubscriptionSlice = createSlice({
  name: "userRole",
  initialState: {
    freeUser: false,
    authorRole: false,
    premiumUser: false,
    runRoleCheck: false,
    programAcess: false,
    awaitingAccess: false,
  },
  reducers: {
    handleSetFreeRole: (state, action) => {
      state.freeUser = action.payload;
    },
    handleSetPremiumRole: (state, action) => {
      state.premiumUser = action.payload;
    },
    handleRunRoleCheck: (state, action) => {
      state.runRoleCheck = action.payload;
    },
    handleAuthorRole: (state, action) => {
      state.authorRole = action.payload;
    },
    handleProgramAcess: (state, action) => {
      state.programAcess = action.payload;
    },
    handleAwaitingAccess: (state, action) => {
      state.awaitingAccess = action.payload;
    },
  },
});

export const {
  handleAuthorRole,
  handleSetFreeRole,
  handleProgramAcess,
  handleRunRoleCheck,
  handleAwaitingAccess,
  handleSetPremiumRole,
} = setUserSubscriptionSlice.actions;

export default setUserSubscriptionSlice.reducer;
