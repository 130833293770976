import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  caseStudyPath,
  excelPath,
  googleSheetsPath,
  learningPath,
  powerbiPath,
  sqlPath,
  pythonPath,
  tableauPath,
} from "../../constants/learnMenu/courseMenus";

// @components
import LearnMenuCard from "../../components/LearnMenuCard/LearnMenuCard";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import useScrollToTop from "../../hooks/useScrollToTop";

const CoursesMenu = () => {
  useScrollToTop();
  const [mappedPath, setMappedPath] = useState([]);
  const [courseTitle, setCourseTitle] = useState("");

  let { courseId } = useParams();

  useEffect(() => {
    switch (courseId) {
      case "learning-path":
        setMappedPath(learningPath);
        setCourseTitle("Learning path");
        break;
      case "excel-courses":
        setMappedPath(excelPath);
        setCourseTitle("Excel courses");
        break;
      case "powerbi-courses":
        setMappedPath(powerbiPath);
        setCourseTitle("Power BI courses");
        break;
      case "sql-courses":
        setMappedPath(sqlPath);
        setCourseTitle("SQL courses");
        break;
      case "python-courses":
        setMappedPath(pythonPath);
        setCourseTitle("Python courses");
        break;
      case "tableau-courses":
        setMappedPath(tableauPath);
        setCourseTitle("Tableau courses");
        break;
      case "google-sheets-courses":
        setMappedPath(googleSheetsPath);
        setCourseTitle("Google Sheets courses");
        break;
      case "case-study-projects":
        setMappedPath(caseStudyPath);
        setCourseTitle("Case study projects");
        break;
      default:
        console.log("something went wrong");
    }
  }, [courseId]);

  return (
    <>
      <MetaTags
        path={`learn/${courseId}`}
        title={`Resagratia | ${courseTitle}`}
        desc={`${courseTitle} course`}
      />
      <div className="mb-10">
        <h4 className="text-2xl font-bold leading-loose">
          {courseTitle}
        </h4>
      </div>
      <section className="flex flex-col gap-24 pb-10">
        <div className="w-full flex xs:flex-col md:flex-row items-stretch flex-wrap gap-x-6 gap-y-12">
          {mappedPath?.map((item) => (
            <LearnMenuCard
              key={item.id}
              title={item.title}
              icon={item.img}
              path={item.path}
            />
          ))}
        </div>
      </section>
    </>
  );
};

export default CoursesMenu;
