import { createSlice } from "@reduxjs/toolkit";

export const showUserFeedback = createSlice({
  name: "showUserFeedback",
  initialState: {
    topicDetails: null,
    showFeebackModal: false,
  },
  reducers: {
    handleTopicDetails: (state, action) => {
      state.topicDetails = action.payload;
    },
    handleShowShowFeebackModal: (state, action) => {
      state.showFeebackModal = action.payload;
    },
  },
});

export const {
  handleTopicDetails,
  handleShowShowFeebackModal,
} = showUserFeedback.actions;
export default showUserFeedback.reducer;
