import { createSlice } from "@reduxjs/toolkit";

export const lessonRef = createSlice({
  name: "lessonReferrence",
  initialState: {
    isBlogRef: false,
    isLessonRef: false,
  },
  reducers: {
    handleLessonRef: (state, action) => {
      state.isLessonRef = action.payload;
    },
    handleBlogRef: (state, action) => {
      state.isBlogRef = action.payload;
    },
  },
});

export const { handleLessonRef, handleBlogRef } = lessonRef.actions;

export default lessonRef.reducer;
