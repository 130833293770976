import feather from '../../feather-sprite.svg';

const Toast = ({ message, warning, notice, icon, width }) => {
  return (
    <div
      className={`${
        warning ? 'bg-red-100' : notice && 'bg-blue-50'
      } p-3 relative rounded flex flex-row ${width}`}
    >
      <div
        className={`absolute left-0 top-0 bottom-0 w-[5px] rounded-tl rounded-bl border border-solid ${
          warning
            ? 'border-red-500 bg-red-500'
            : notice && 'border-blue-500 bg-blue-500'
        }`}
      ></div>
      <div className='flex flex-row items-start gap-3'>
        <svg
          width='22'
          height='22'
          fill='none'
          stroke={warning ? 'rgb(239 68 68)' : notice && 'rgb(59 130 246)'}
          strokeWidth='3'
          strokeLinecap='round'
          strokeLinejoin='round'
        >
          <use href={`${feather}#${icon}`} />
        </svg>
        <div className='flex flex-col gap-1'>
          <span
            className={`text-sm font-light ${
              warning ? 'text-red-700' : notice && 'text-blue-700'
            }`}
          >
            {message}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Toast;
