import { createSlice } from "@reduxjs/toolkit";

const getTextBasedLessonMarkup = createSlice({
  name: "textBasedLessonMarkup",
  initialState: {
    textBasedLessonTitle: "",
    isTextBasedLessonMarkupFetched: false,
  },
  reducers: {
    handleTextBasedLessonTitle: (state, action) => {
      state.textBasedLessonTitle = action.payload;
    },
    handleIsTextBasedLessonMarkupFetched: (state, action) => {
      state.isTextBasedLessonMarkupFetched = action.payload;
    },
  },
});

export const {
  handleTextBasedLessonTitle,
  handleIsTextBasedLessonMarkupFetched,
} = getTextBasedLessonMarkup.actions;

export default getTextBasedLessonMarkup.reducer;
