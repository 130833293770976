import React, { Suspense, useEffect, useState } from "react";
import Carousel from "../../components/Carousel/Carousel";
import { Link, useParams } from "react-router-dom";
// import { v4 as uuidv4 } from 'uuid';

// @auth user
import { useAuth } from "../../context/AuthContext";

// @components
import FaqList from "../../components/FaqList/FaqList";

// @data
import {
  reviews,
  powerbiReviews,
} from "../../constants/datasets/carouselData";
import { logos } from "../../constants/courseDetails/logos";

// @styling
import "./index.scss";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import useScrollToTop from "../../hooks/useScrollToTop";

const AdditionalCourses = React.lazy(() =>
  import("../../components/AdditionalCourses/AdditionalCourses")
);

// console.log('id --', uuidv4() )

const CourseDetails = () => {
  useScrollToTop();
  let { courseName } = useParams();
  const { currentUser } = useAuth();
  const [data, setData] = useState("");
  const [openItemIndex, setOpenItemIndex] = useState(null);

  const handleItemClick = (index) => {
    setOpenItemIndex((prevIndex) =>
      prevIndex === index ? null : index
    );
  };

  useEffect(() => {
    const importCModule = async () => {
      try {
        let cModule;
        switch (courseName) {
          case "excel":
            cModule = await import(
              "../../constants/courseDetails/excel.js"
            );
            break;
          case "tableau":
            cModule = await import(
              "../../constants/courseDetails/tableau.js"
            );
            break;
          case "powerbi":
            cModule = await import(
              "../../constants/courseDetails/powerbi.js"
            );
            break;
          case "sql-game-of-thrones":
            cModule = await import(
              "../../constants/courseDetails/sql.js"
            );
            break;
          case "analysis-and-visualisation-of-gdp-in-powerbi-excel":
            cModule = await import(
              "../../constants/courseDetails/gdpanalysis.js"
            );
            break;
          case "designing-heatmaps-in-excel":
            cModule = await import(
              "../../constants/courseDetails/heatmapsInExcel.js"
            );
            break;
          case "data-analytics-fundamentals-with-google-sheets":
            cModule = await import(
              "../../constants/courseDetails/googleSheets.js"
            );
            break;
          case "introduction-to-python-programming":
            cModule = await import(
              "../../constants/courseDetails/python.js"
            );
            break;
          case "data-analysis-with-sql":
            cModule = await import(
              "../../constants/courseDetails/dataAnalysisWithSql.js"
            );
            break;
          case "sql-for-data-science":
            cModule = await import(
              "../../constants/courseDetails/sqlForDataScience.js"
            );
            break;
          case "data-science-fundamental-with-python":
            cModule = await import(
              "../../constants/courseDetails/dataScienceFundamentalWithPowerBi"
            );
            break;
          default:
            break;
        }

        if (cModule) {
          setData(cModule);
        }
      } catch (error) {
        console.error(error);
      }
    };

    importCModule();
  }, [courseName]);

  return (
    <>
      <MetaTags
        path={`course/${courseName}`}
        title={`Resagratia | ${courseName}`}
        desc={`${courseName} course`}
      />
      {data && (
        <div className="course">
          <div className="course__container">
            <div className="title__container">
              <div className="title__description">
                <div className="title__description__heading">
                  <div className="title__logo">
                    <img
                      src={data.logo && data.logo}
                      alt={data.name && data.name}
                    />
                  </div>
                  <div className="title__heading">
                    <h3>Learn {data.name && data.name}</h3>
                  </div>
                </div>
                <div className="title__description__paragraph">
                  {data?.description &&
                  typeof data?.description === "object" ? (
                    <>
                      {data.description?.map((text, index) => {
                        if (text?.type === "p")
                          return <p className="mt-4" key={text?.content}>{text?.content}</p>;
                        else if (text?.type === "ol")
                          return (
                            <ol
                              key={index}
                              className="list-decimal my-2 leading-5 text-sm pl-8"
                            >
                              {text?.listItems.map((listItem) => (
                                <li
                                  key={listItem}
                                  className="my-1"
                                >
                                  {listItem}
                                </li>
                              ))}
                            </ol>
                          );
                      })}
                    </>
                  ) : (
                    <p>{data.description}</p>
                  )}
                </div>

                {/* title tags */}
                <div className="title__tags">
                  {data.tags &&
                    data.tags.map((tag) => (
                      <div
                        className={tag.name}
                        key={tag.id}
                      >
                        <p>{tag.data}</p>
                      </div>
                    ))}
                </div>
              </div>

              <div className="title__media">
                <div className="title__media__container">
                  <div className="media__container">
                    <video
                      src={data.trailer && data.trailer}
                      poster={data.poster && data.poster}
                      controls="controls"
                      autoPlay={false}
                      controlsList="nodownload"
                    ></video>
                    <Link to={currentUser ? data.url : "/bootcamp"}>
                      <div className="media__getstarted__button">
                        <button>Get started</button>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* content */}
            <div className="content__and__tutors">
              <div className="content__and__tutors__container">
                <div className="content__and__tutors__items">
                  <div className="content__items__container">
                    <div className="content__heading">
                      <h3>Curriculum Content</h3>
                    </div>
                    <div className="content__items">
                      <div className="circle__border__container">
                        {data.content &&
                          data.content.map((item, index) => (
                            <div key={index}>
                              <div className="circle__and__description">
                                <div className={item.circle}></div>
                                <div className="content__right__heading">
                                  <h3>{item.title}</h3>
                                </div>
                              </div>

                              <div
                                className="border__and__paragraph"
                                key={index}
                              >
                                <div className={item.border}></div>
                                <div className="content__right__paragraph">
                                  <p>{item.description}</p>
                                </div>
                              </div>

                              <div className="content__right__tags">
                                {item.tags.map((tag) => (
                                  <p key={tag.id}>{tag.data}</p>
                                ))}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>

                  {/* tutors */}
                  <div className="tutors">
                    <div className="tutors__container">
                      <div className="tutors__heading">
                        <h3>Expert tutors</h3>
                      </div>
                      <div className="tutors__description__container">
                        {data.tutors &&
                          data.tutors.map((tutor) => (
                            <React.Fragment key={tutor.id}>
                              <div className="tutor__description">
                                <div className="">
                                  <div className="tutor__img">
                                    <img
                                      src={tutor.img}
                                      alt={tutor.name}
                                    />
                                  </div>
                                </div>
                                <div className="tutor__info">
                                  <div className="tutor__name">
                                    <h3>{tutor.name}</h3>
                                  </div>
                                  <div className="tutor__job__title">
                                    <p>{tutor.job}</p>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* additional courses */}
            {data.additionalData && (
              <div className="additional__courses">
                <div className="additional__courses__container">
                  <div className="additional__courses__heading">
                    <h3>
                      Some additional courses in{" "}
                      {data.name && data.name}
                    </h3>
                  </div>
                  <div className="additional__courses__content">
                    <div className="additional__courses__item">
                      <h3>
                        Additional courses in {data.name && data.name}
                      </h3>
                    </div>
                    <div className="additional__courses__details">
                      <Suspense fallback={<></>}>
                        {data.additionalData.map((data) => (
                          <AdditionalCourses
                            key={data.id}
                            title={data.title}
                            img={data.img}
                            learnUrl={data.unAuthLink}
                            libraryUrl={data.link}
                          />
                        ))}
                      </Suspense>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {/* organizations */}
            <div className="organizations">
              <div className="organizations__container">
                <h3 className="organizations__heading">
                  Organizations where some of our students come from
                </h3>
                <div className="organizations__logos__container">
                  {logos.map((logo) => (
                    <div
                      className="organizations__logos"
                      key={logo.id}
                    >
                      <img
                        src={logo.img}
                        alt={logo.img}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>

            {/* carousel */}
            <div className="course__carousel">
              <div className="course__carousel__container">
                <h3 className="course__carousel__heading">
                  Some of our best reviews
                </h3>
                <h3 className="course__carousel__subheading">
                  Here are reviews from some of our students
                </h3>
                <div className="course__imported__carousel">
                  <Carousel
                    reviews={
                      courseName === "powerbi"
                        ? powerbiReviews
                        : reviews
                    }
                  />
                </div>
              </div>
            </div>

            {/* join for free */}
            <div className="join__course">
              <div className="join__course__container">
                <div className="join__course__heading__container">
                  <h3>Want to skill up?</h3>
                  <p>Join our learners to gain in-demand skills</p>
                </div>
                <Link to={currentUser ? data.url : "/pricing"}>
                  <div className="join__course__button">
                    <button>Get started</button>
                  </div>
                </Link>
              </div>
            </div>

            {/* FAQs */}
            <div className="faq">
              <div className="faq__container">
                <div className="faq__items">
                  <h3 className="faq__heading">FAQs</h3>
                  <h3 className="faq__subheading">
                    Frequently asked questions
                  </h3>
                  <ul>
                    {data.faqs &&
                      data.faqs.map((faq) => {
                        return (
                          <FaqList
                            question={faq.question}
                            answer={faq.answer}
                            key={faq.id}
                            isFaqExpanded={openItemIndex === faq.id}
                            handleFaqExpanded={() =>
                              handleItemClick(faq.id)
                            }
                          />
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CourseDetails;
