import SqlIcon from "./SqlIcon";
import ExcelIcon from "./ExcelIcon";
import PythonIcon from "./PythonIcon";
import TableauIcon from "./TableauIcon";
import MSPowerBIIcon from "./MSPowerBIIcon";

import GSIcon from "../assets/GSIcon.svg";
import BigQuesry from "../assets/google-bigquery.svg";

export const dataAnalyticsBootcampLogos = [
  { icon: ExcelIcon },
  { icon: TableauIcon },
  { icon: SqlIcon },
  { icon: MSPowerBIIcon },
];

export const dataScienceBootcampLogos = [
  { icon: GSIcon, type: "image" },
  { icon: SqlIcon },
  { icon: PythonIcon },
  { icon: BigQuesry, type: "image" },
];

export const hearFromOurStudents = [
  {
    name: "Theresa Abuo",
    occupation: "Data Analyst & Agric Extension Agent",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/DATA%20BOOTCAMP%20EXPERIENCE%20-%20Theresa%20Abuo%20Placeholder%20Image.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/Data%20Bootcamp%20Experience%20-%20Theresa%20Abuo.mp4`,
  },
  {
    name: "Dami Alabi",
    occupation: "Marine Biologist",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/DATA%20BOOTCAMP%20EXPERIENCE%20-%20Dami%20Alabi%20Placeholder%20Image.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/DATA%20BOOTCAMP%20EXPERIENCE%20-%20Dami%20Alabi.mp4`,
  },
  {
    name: "Onyinyechi Eze",
    occupation: "Engineer / Data Analyst",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/DATA%20BOOTCAMP%20EXPERIENCE%20-%20Onyinyechi%20Eze%20Placeholder%20Image.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/Data%20Bootcamp%20Experience%20-%20Onyinyechi%20Eze.mp4`,
  },
  {
    name: "Chinaza MaryTheresa",
    occupation: "Pharmacist",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/DATA%20BOOTCAMP%20EXPERIENCE%20-%20Chinaza%20MT%20Placeholder%20Image.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/Data%20Bootcamp%20Experience%20-%20Chinaza%20MT.mp4`,
  },
  {
    name: "Timilehin Oriola",
    occupation: "Data Analyst and Mathematician",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/grad_oriola_timilehin.jpeg",
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_oriola_timilehin.mp4`,
  },
  {
    name: "Omowunmi Olabanji",
    occupation: "Optometrist",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/grad_omowunmi_olabanji.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_omowunmi.mp4`,
  },
  {
    name: "Chinonso Steve",
    occupation: "Web Developer and Analyst",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/grad_chinonso_steve.jpg",
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_steve_04_2023.mp4`,
  },
  {
    name: "Gbemisola Odedina",
    occupation: "Logistics Analyst",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/grad_gbemisola_odedina.jpeg",
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_fisayo_and_gbemisola.mp4`,
  },
  {
    name: "Dolapo Hammed",
    occupation: "HR Analyst",
    thumbNail:
      "https://storage.googleapis.com/resagratia-webapp/misc/bootcamp_page/graduate_dolapo_hammed.jpeg",
    video: `https://storage.googleapis.com/resagratia-webapp/bootcamp_video_reviews/data_bootcamp_experience_dolapo_04_2023.mp4`,
  },
];

export const dataAnalyticsOffers = [
  "14 weeks online with real-world exercises and practical projects",
  "Instructor-led training + self-paced components",
  "Access to daily, curated data-related jobs",
  "Saturday mentorship sessions with industry practitioners",
  "Study in groups, build portfolio projects with job preparation support",
  "Internship Opportunities",
  "Learn Excel, Tableau, SQL, and Power BI",
];

export const dataScienceOffers = [
  "14 weeks online with real-world, practical programming exercises",
  "Instructor-led training + self-paced components",
  "Access to daily, curated data-related jobs",
  "Saturday mentorship sessions with industry Data Scientists",
  "Study in groups, build portfolio projects with job preparation support",
  "Internship Opportunities",
  "Learn Google Sheets, SQL, Google BigQuery, and Python",
];

export const dataAnalyticsRoadmap = {
  courseName: "Data Analytics Bootcamp",
  additionalNote:
    "Crafting a compelling data portfolio is essential for every aspiring data professional. During Weeks 13 & 14 of our program, participants focus on developing portfolios highlighting their proficiency in Excel, Power BI/Tableau. Upon graduation, certificates are awarded, and we provide valuable insights on job preparation and optimizing LinkedIn profiles to enhance career prospects.",
  desc: "Data analytics is a broader term that encompasses various techniques and processes used to gather and store data, transform, analyse, and visualize data. Data analytics often involves not only analyzing historical data but also predicting future trends and outcomes. This bootcamp is perfect for those looking to start a career in data analysis or enhance their existing business and analytical skills in their current role at work.",
  courseOutline: [
    {
      courseName: "Microsoft Excel Essentials",
      duration: "week 1 - 2",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_new_logo_large.png",
      desc: "Dive into the essentials of Excel at the start of our bootcamp, where we cover fundamental topics like navigating the interface, formatting data, mastering cell referencing, performing calculations, executing lookup functions, and creating pivot tables. Through hands-on learning, students swiftly gain proficiency in using Excel to tackle real-world business data challenges.",
    },
    {
      courseName: "Advanced Excel",
      duration: "week 3 - 4",

      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/excel_new_logo_large.png",
      desc: "We continue the bootcamp with advanced use cases of Excel for business analysis. The curriculum ensures that students gain an understanding of data validation, Excel structured tables for reporting, advanced use of pivot tables for business analysis, data visualization and dashboarding, and Power Query for data transformation and report automation.",
    },
    {
      courseName: "Power BI Fundamentals",
      duration: "week 5 - 6",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
      desc: "Moving from cell calculations to row analyses can be a bit daunting. We break this process down gradually and introduce you to using Power BI from the basics to using Power Query and re-using M scripts. Data visualization and reporting are taught extensively by combining core data modelling principles with Data Analysis Expressions (DAX). Students work on mini-projects each step of the way.",
    },
    {
      courseName: "Tableau Fundamentals",
      duration: "week 7 - 8",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/tableau_logo.png",
      desc: "Tableau is an industry visual analytics software leader and this section of the bootcamp equips students with data exploration, analysis, and dashboarding skills. We cover the basics from dimensions and measures (continuous and discrete variables) to selecting chart types, Tableau actions, joins and relationships, calculated fields, creating charts and publishing to Tableau Public.",
    },
    {
      courseName: "Intro To SQL Game Of Thrones",
      duration: "week 9 - 10",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
      desc: "Begin coding directly from your browser in this exciting SQL course featuring a storytelling approach with the Game of Thrones dataset. We cover the concept of relational databases, data modelling, SQL building blocks, JOINs and UNIONs, and subqueries to solve practical exercises. The mentor sessions go in-depth with PostgreSQL to teach database set up and core data manipulation methods.",
    },
    {
      courseName: "Case Study Projects",
      duration: "week 11 - 12",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/power_bi_logo_new.png",
      desc: "It gets a little intense and more hands-on here. In the real-world, we do not always work with clean, structured data. We will cover how to build end-to-end data projects from data gathering and normalization to transformation, analysis, visualization and presentation. Students will be in teams and get to present their findings and recommendations. It’s the whole data analyst experience.",
    },
  ],
};

export const dataScienceRoadmap = {
  courseName: "Data Science Bootcamp",
  additionalNote:
    "Certificates are good but skills are better. As a fresh Data Scientist after graduating, we guide you to set up and use Git and GitHub effectively for your data portfolio of works in weeks 13 - 14. Learning is a life-long sport and we support you to apply your skills to additional real-world challenges including working with platforms like Kaggle.",
  desc: "Data Science is an interdisciplinary field that combines statistics, maths, programming, and domain expertise to extract meaningful insights from data. Our Data Science Bootcamp goes beyond traditional data analysis, delving into machine learning, predictive modeling, and AI. Perfect for aspiring data scientists, this intensive program equips participants with the tools and knowledge to solve complex problems and drive innovation.",
  courseOutline: [
    {
      courseName: "Google Sheets Essentials",
      duration: "week 1 - 2",
      img: "https://storage.googleapis.com/resagratia-webapp/misc/sheets.svg",
      desc: "We begin with a deep understanding of Spreadsheets for analytics. Google Sheets is a versatile, online-based tool for data management, analysis, collaboration, and data visualization. Students will understand how to clean data, write custom functions, import HTML, manipulate text, use pivot tables and visualize data, collaborate with others, and learn to use the BigQuery data connector.",
    },
    {
      courseName: "Data Analysis with SQL",
      duration: "week 3 - 4",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
      desc: "Get hands-on with SQL right from your browser in our captivating course, where we explore the Game of Thrones dataset using a storytelling approach. From understanding relational databases and data modeling to mastering SQL building blocks, JOINs, UNIONs, and subqueries, we guide you through practical exercises every step of the way.",
    },
    {
      courseName: "SQL for Data Science",
      duration: "week 5 - 6",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/Postgresql_elephant.svg.png",
      desc: "We continue with SQL by covering more advanced concepts on the use of CTEs for data engineering pipelines, subqueries and window functions to solve practical, real-world challenges. This course focuses on problem-solving scenarios involving advanced JOINs, arrays, table creation and views, and indexing using PostgreSQL and Google BigQuery.",
    },
    {
      courseName: "Python Fundamentals",
      duration: "week 7 - 8",
      img: "https://storage.googleapis.com/resagratia-webapp/misc/python-logo-notext.svg",
      desc: "Embark on an enriching journey into the world of programming with our Python Fundamentals. Python is easy to write and understand; it stands as the tool of choice for data science. Perfect for beginners, our fundamentals course covers concepts on numbers and operators, manipulating data types, loops, lists, dictionaries, and writing your own custom functions to solve practical problems.",
    },
    {
      courseName: "Data Science with Python",
      duration: "week 9 - 10",
      img: "https://storage.googleapis.com/resagratia-webapp/misc/python-logo-notext.svg",
      desc: "This is where it gets more exciting and in-depth with the use of Python for data science. Building on the fundamentals, we cover data manipulation in Pandas, NumPy, data visualization with Matplotlib, Folium and Seaborn libraries, statistical exploratory data analysis, dimensionality reduction and machine learning (supervised + unsupervised) while applying these concepts to real world use cases.",
    },
    {
      courseName: "Case Study Projects",
      duration: "week 11 - 12",
      img: "https://storage.googleapis.com/resagratia-webapp/course_resources_general/logos/data_science_week11-14.png",
      desc: "Work on structured and unstructured data to perform exploratory data analyses to extract useful features for your machine learning projects. The projects are very hands-on and students will build predictive models that solve peculiar industry challenges. Our students are not alone; our vetted, expert mentors provide continuous support and offer domain expertise to ensure project completion.",
    },
  ],
};

export const jobHuntInstructions = [
  "Highlight your bootcamp accomplishments",
  "Build meaningful relationships with potential employers",
  "Create a website or platform showcasing your diverse skillset",
  "participate in hackathons, and contribute to open-source projects.",
  "Be persistent, patient, and learn from every interaction.",
];

export const stats = [
  { name: "Students and professionals", num: "2017+" },
  { name: "Countries represented", num: "37" },
  { name: "Types of professionals", num: "30+" },
];

export const FAQs = [
  {
    id: 1,
    question: "What is the mode of learning?",
    answer: [
      "Our Data Bootcamps are online and delivered in a mixed format involving self-paced learning on Resagratia and live, online tutoring every Saturday, 6 PM - 8 PM. Students are also supported on Discord by our teaching assistants to answer pressing questions. Students are actively engaged to maintain high retention until completion.",
    ],
  },
  {
    id: 2,
    question: "Are the sessions recorded?",
    answer: [
      "Yes, the Saturday mentor sessions are recorded for viewing later. The recordings are shared one or two days after the live tutoring sessions.",
    ],
  },
  {
    id: 3,
    question: "Do you have discounts?",
    answer: [
      "Yes, discounts are available starting from 5% off, up to 30% off. The discount codes are generally available on the banner and are issued at random points in time. For special promotions, discount codes are shared via email.",
      "You can also take advantage of our convenient payment plan option at the point of payment for the bootcamp.",
    ],
  },
  {
    id: 4,
    question:
      "Is this for beginners and people with non-science backgrounds?",
    answer: [
      "Data Analytics and Data Science skills are for people from any educational or professional background without any discrimination. Our Data Bootcamps are very beginner friendly and the concepts are explained in bite-sized chunks by expert instructors with deep passion for teaching.",
      "Data literacy is for everyone and the world will be littered with citizen analysts in the future of work. Data Analytics and Data Science skills will be used by anyone from any industry to solve domain-specific problems.",
    ],
  },
  {
    id: 5,
    question: "Will I earn a certificate after the Data Bootcamp?",
    answer: [
      "Yes, once you meet all the requirements to earn our coveted Bootcamp Completion certificate. The certificate is hosted online and can be added to your LinkedIn profile to increase awareness of your new skills.",
    ],
  },
  {
    id: 6,
    question: "How many hours per week will I need to dedicate?",
    answer: [
      "The self-paced component requires at least 3 hours per week to complete learning objectives, quizzes and assessments. We encourage additional 1-2 hours per week to complete further reading and practical exercises.",
      "The live tutoring sessions require 1 hour 30 minutes every Saturday.",
    ],
  },
  {
    id: 7,
    question: "Are there physical classes?",
    answer: [
      "No, none for the Data Bootcamps, however, we run corporate tailored data analytics and data science trainings upon request.",
      "Please email servus@resagratia.com for more information.",
    ],
  },
  {
    id: 8,
    question:
      "What opportunities are there after graduating from this Data Bootcamp?",
    answer: [
      "Firstly, we offer limited internships to the best graduates from our Data Analytics Bootcamp.",
      "Secondly, based on your capabilities, your industry, and the skills you’ve gained during the Data Bootcamp, there are many job roles that you can look forward to including:",
    ],
    list: [
      "Data Analyst",
      "Business Intelligence Analyst / Product Analyst",
      "Data Scientist",
      "Data Engineer",
      "Customer Insights Analyst",
      "Growth Analyst",
      "HR, Sales, Accounting, & Finance Analyst",
      "And many, many more",
    ],
  },
];
