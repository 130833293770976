import { db } from "../../firebase/firebase";
import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";

export const formatCourseData = (course) => {
  const collectionName = course.title;
  const formattedData = [
    {
      title: course.title,
      duration: course.hours,
      courseId: course.courseId,
      courseTitle: course.courseTitle.map((section) => {
        return {
          section: section.section,
          active: section.active,
          courseTopic: section.courseTopic.map((topic) => {
            return {
              title: topic.title,
              courseLesson: topic.courseLesson.map((lesson) => {
                return {
                  title: lesson.title,
                  lessonId: lesson.lessonId,
                };
              }),
              topic: {
                topicId: topic.topicId,
                quizLength: topic.courseQuestion?.length ?? null,
              },
              quizLength: topic.courseQuestion?.length ?? null,
              topicId: topic.topicId,
            };
          }),
        };
      }),
    },
  ];
  return { collectionName, data: formattedData };
};

export const getCourseDetails = (course, courseDocs) => {
  courseDocs[course.data[0].courseId] = {
    topics: [],
    lessons: [],
    courseName: course.data[0].title,
    courseId: course.data[0].courseId,
    courseDuration: course.data[0].duration,
  };

  course.data[0].courseTitle.forEach((section) => {
    section.courseTopic.forEach((topic) => {
      courseDocs[course.data[0].courseId].lessons = [
        ...courseDocs[course.data[0].courseId].lessons,
        ...topic.courseLesson,
      ];
      if (topic.quizLength) {
        courseDocs[course.data[0].courseId].topics = [
          ...courseDocs[course.data[0].courseId].topics,
          {
            topicId: topic.topicId,
            passScore: Math.ceil(topic.quizLength * 0.7),
          },
        ];
      }
    });
  });
};

const getAllCourseLessons = async () => {
  const courseDocs = {};
  const baseURL =
    process.env.NODE_ENV === "production"
      ? process.env.REACT_APP_API_URL
      : process.env.NODE_ENV === "development" &&
        "http://localhost:8000";

  const courses = await fetch(
    `${baseURL}/api/v1/courses/all-courses`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=UTF-8",
      },
    }
  );

  const res = await courses.json();
  res.result.forEach((course) =>
    getCourseDetails(course, courseDocs)
  );
  return { courses: courseDocs };
};

export const getCompletedLessons = async (userEmail) => {
  const markedAsCompleteQuery = query(
    collection(db, "markedAsComplete"),
    where("userEmail", "==", userEmail)
  );

  const markedAsCompleteSnapshot = await getDocs(
    markedAsCompleteQuery
  );

  let completedLessons;

  markedAsCompleteSnapshot.docs.forEach((course) => {
    completedLessons = course.data().markedDetails;
  });

  if (!completedLessons) completedLessons = [];

  return { completedLessons };
};

export const getFromMongoOrSessionStrorage = async () => {
  let courseDocs;
  const data = sessionStorage.getItem("courses2");
  if (!data) {
    const { courses } = await getAllCourseLessons();
    sessionStorage.setItem("courses2", JSON.stringify(courses));
    courseDocs = courses;
  } else {
    const courses = JSON.parse(data);
    courseDocs = courses;
  }
  return { courseDocs };
};

export const getQuizScores = async (userId) => {
  const quizScoresQuery = query(
    collection(db, "quizScores"),
    where("userId", "==", userId)
  );

  const quizScoresSnapshot = await getDocs(quizScoresQuery);

  let quizScores;

  quizScoresSnapshot.docs.forEach((doc) => {
    quizScores = doc.data().quizDetails;
  });

  if (!quizScores) quizScores = [];

  return { quizScores };
};

export const getMaxQuizScore = (
  topic,
  quizScores,
  attemptedQuizScores
) => {
  const highestPassScore = quizScores
    .filter(
      (quizScore) =>
        quizScore.topicId === topic.topicId &&
        quizScore.score >= topic.passScore
    )
    .reduce((acc, obj) => {
      const passScore = obj.score;

      const maxPassScore = Math.max(
        ...acc.map((obj) => obj.score),
        -Infinity
      );

      if (passScore === maxPassScore) acc.push(obj);
      else if (passScore > maxPassScore) acc = [obj];

      return acc;
    }, [])[0];

  if (highestPassScore)
    attemptedQuizScores.push({
      ...highestPassScore,
      topicId: topic.topicId,
    });
};

const arrangeCompletedLessonsBasedOnCourse = (
  lesson,
  courseId,
  uniqueLessonIds,
  completedLessons,
  completedLessonsObj
) => {
  if (completedLessons.length === 0)
    completedLessonsObj[courseId] = [];

  completedLessons.forEach((completedLesson) => {
    const completedLessonId = completedLesson.lessonId;
    const lessonIsCompleted = completedLesson.isComplete;
    const lessonInCourse = completedLessonId === lesson.lessonId;
    const lessonInCourseAndCompleted =
      lessonInCourse && lessonIsCompleted;

    // Arrange completed lessons based on their courses
    if (!uniqueLessonIds.includes(completedLesson.lessonId)) {
      if (
        !completedLessonsObj[courseId] &&
        lessonInCourseAndCompleted
      ) {
        completedLessonsObj[courseId] = [completedLesson];
        uniqueLessonIds.push(completedLesson.lessonId);
      } else if (lessonInCourseAndCompleted) {
        completedLessonsObj[courseId].push(completedLesson);
        uniqueLessonIds.push(completedLesson.lessonId);
      }
    }
  });
};

export const setCompletedCourses = async (
  courseDocs,
  quizScores,
  setCourseData,
  completedLessons,
  completedCourses,
  userId
) => {
  const caseStudyCourseIds = [
    "bfc7c201-5a94-405b-8862-4e3802b47834",
    "419bde16-d7e7-4c12-8722-964c6db0a6f9",
  ];

  // Cycle through all courses in courseDocs
  for (const courseId in courseDocs) {
    // To filter out duplicates
    const uniqueLessonIds = [];

    const attemptedQuizScores = [];
    const completedLessonsObj = {};
    const lessons = courseDocs[courseId].lessons;
    // Gets the highest quiz score for each topic
    courseDocs[courseId].topics.forEach((topic) => {
      getMaxQuizScore(topic, quizScores, attemptedQuizScores);
    });

    lessons.forEach((lesson) => {
      arrangeCompletedLessonsBasedOnCourse(
        lesson,
        courseId,
        uniqueLessonIds,
        completedLessons,
        completedLessonsObj
      );
    });

    const numberOfPassedQuizzes = attemptedQuizScores.length;
    const numberOfQuizzes = courseDocs[courseId].topics.length;

    const allQuizzesCompleted =
      courseDocs[courseId].topics.length !== 0
        ? numberOfQuizzes === numberOfPassedQuizzes
        : "No quiz for this course";
    const allLessonsMarkedCompleted =
      courseDocs[courseId].lessons.length ===
      completedLessonsObj[courseId]?.length;
    const caseStudyProjectApproved = caseStudyCourseIds.includes(
      courseId
    )
      ? await checkSubmittedCaseStudyProjects(courseId, userId)
      : true;

    completedCourses.push({
      courseId,
      numberOfQuizzes,
      allQuizzesCompleted,
      numberOfPassedQuizzes,
      courseName: courseDocs[courseId].courseName,
      courseDuration: courseDocs[courseId].courseDuration,
      topicsWithQuiz: courseDocs[courseId].topics,
      lessons: courseDocs[courseId].lessons,
      userQuizScores: attemptedQuizScores || null,
      markedAsCompleteLessons: completedLessonsObj[courseId] || [],
      courseCompleted:
        allLessonsMarkedCompleted &&
        (allQuizzesCompleted === "No quiz for this course" ||
          allQuizzesCompleted) && caseStudyProjectApproved,
    });
  }
  setCourseData(completedCourses);
  return completedCourses;
};

export const getTopicLessons = (content, topic) => {
  const res = content.map(formatCourseData);
  const topicsAndLessons = res
    .map((course) => {
      return course.data.map((data) => {
        return data.courseTitle.map((section) => {
          return section.courseTopic.map((courseTopic) => {
            return {
              topicId: courseTopic.topicId,
              topicTitle: courseTopic.title,
              topicLessons: courseTopic.courseLesson,
              topicQuiz: !courseTopic.quizLength
                ? 0
                : courseTopic.quizLength,
              passScore: !courseTopic.quizLength
                ? 0
                : Math.ceil(courseTopic.quizLength * 0.7),
            };
          });
        });
      });
    })
    .flat(3);
  return topicsAndLessons.filter(
    (topicAndLessons) => topicAndLessons.topicId === topic.topicId
  )[0];
};

async function getMarkedAsCompletedLessons(uid) {
  try {
    const markedAsCompleteQuery = query(
      collection(db, "markedAsComplete"),
      where("userId", "==", uid)
    );

    const markedAsCompleteSnapshot = await getDocs(
      markedAsCompleteQuery
    );

    let completedLessons;
    let completedLessonId;

    markedAsCompleteSnapshot.docs.forEach((course) => {
      completedLessons = course.data().markedDetails;
    });

    if (!completedLessons) completedLessons = [];

    if (completedLessons.length > 0) {
      completedLessonId = completedLessons
        .filter((lesson) => lesson.isComplete === true)
        .map((lesson) => lesson.lessonId);
    } else completedLessonId = [];

    return { completedLessonId };
  } catch (error) {
    console.log(error);
  }
}

export const checkIfTopicCompleted = async (
  content,
  topicId,
  uid
) => {
  try {
    if (content.length > 0) {
      // Get all IDs of completed lessons
      const { completedLessonId } = await getMarkedAsCompletedLessons(
        uid
      );
      const courseId = content[0].courseId;
      const dbQuery = query(
        collection(db, "courses"),
        where("courseId", "==", courseId)
      );
      // Get all topics under a course
      const querySnapshot = await getDocs(dbQuery);
      const courseDoc = querySnapshot.docs[0].data();
      const topics = courseDoc.courseTopics;
      const allTopics = [...topics];
      const allTopicsObj = {};

      // Wait for all promises
      // to resolve and log the data
      await Promise.all(
        allTopics.map(async (topic, index) => {
          // Check prev topic if all lessons have been completed
          const promises = topics[index].topicLessons.map(
            async (lesson) => {
              const { lessonId } = lesson;

              // if completedLessionId is length zero,
              // it means user hasn't completed prev topic
              if (completedLessonId.length <= 0) {
                return { lessonId, isCompleted: false };
              }

              // Check if lesson is completed for this lesson
              const isCompleted =
                completedLessonId.includes(lessonId);
              return { lessonId, isCompleted };
            }
          );
          const topicLessons = await Promise.all(promises);

          // Check if all lessons in prev topic is completed
          const topicCompleted = topicLessons.every(
            (lesson) => lesson.isCompleted === true
          );

          allTopicsObj[`${topic.topicId}`] = {
            topicCompleted,
            topicId: topic.topicId,
            topicTitle: topic.topicTitle,
            quiz: topics[index].topicQuizMaxScore,
          };

          return {
            topicCompleted,
            topicId: topic.topicId,
            topicTitle: topic.topicTitle,
            quiz: topics[index].topicQuizMaxScore,
          };
        })
      );

      return allTopicsObj[topicId];
    }
  } catch (error) {
    console.log(error);
  }
};

export const currentTopicIsCompleted = async (
  topicId,
  currentQuizCompleted,
  content,
  uid
) => {
  const topicCompletedObj = await checkIfTopicCompleted(
    content,
    topicId,
    uid
  );
  return topicCompletedObj.topicCompleted && currentQuizCompleted;
};

export const getCourseProgress = (
  lessons,
  numberOfQuizzes,
  numberOfPassedQuizzes,
  updatedProgressBarState,
  markedAsCompleteLessons,
  courseCompleted,
  courseId
) => {
  const caseStudyCourseIds = [
    "bfc7c201-5a94-405b-8862-4e3802b47834",
    "419bde16-d7e7-4c12-8722-964c6db0a6f9",
  ];
  const hasCaseStudy = caseStudyCourseIds.includes(courseId);

  if (lessons && markedAsCompleteLessons) {
    const HUNDRED_PERCENT_COMPLETION = 100;
    const totalLessonsInCourse = lessons.length;
    const completedLessonsInCourse = markedAsCompleteLessons.length;

    // Adds number of quizzes passed to lessons completed
    const totalLessonsAndQuizzes =
      totalLessonsInCourse + numberOfQuizzes;
    const completedLessonsAndQuizzesPassed =
      completedLessonsInCourse +
      numberOfPassedQuizzes +
      (hasCaseStudy ? (courseCompleted ? 1 : 0) : 0);

    const percentageCompleted =
      (completedLessonsAndQuizzesPassed *
        HUNDRED_PERCENT_COMPLETION) /
      (totalLessonsAndQuizzes + (hasCaseStudy ? 1 : 0));
    updatedProgressBarState(Math.round(percentageCompleted));
  }
};

const checkSubmittedCaseStudyProjects = async (courseId, userId) => {
  const assignmentsQuery = query(
    collection(db, "assignments"),
    where("userId", "==", userId),
    where("courseId", "==", courseId),
    orderBy("submissionTimestamp", "desc")
  );
  const assignmentsSnapshot = await getDocs(assignmentsQuery);

  if (assignmentsSnapshot.docs.length < 1) return false;

  const recentSubmission = assignmentsSnapshot.docs.map((doc) =>
    doc.data()
  )[0];

  // For those who have submitted
  // before this feature was added.
  if (!recentSubmission?.status) {
    // Only applicable to GDP Analysis.
    if (courseId === "419bde16-d7e7-4c12-8722-964c6db0a6f9")
      return true;
  } else if (recentSubmission?.status === "Approved") return true;
  return false;
};
