import { createSlice } from '@reduxjs/toolkit';

export const sessionBlockerSignupSlice = createSlice({
  name: 'sessionBlockerSignup',
  initialState: {
    signup: null,
  },
  reducers: {
    handleSignupUpdate: (state, action) => {
      state.signup = action.payload;
    },
  },
});

export const { handleSignupUpdate } = sessionBlockerSignupSlice.actions;

export default sessionBlockerSignupSlice.reducer;
