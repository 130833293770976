import { Link } from "react-router-dom";
import PromoCountdown from "../PromoCountdown/PromoCountdown";

export const ChristmasBannerText = () => (
  <>
    <Link
      to="/bootcamp"
      className="text-[#f4bd52] -mb-[6px] text-[28px] font-bold block"
    >
      Resa{" "}
      <span className="inline-block relative">
        {/* <img
          className="absolute top-[2px] -left-[3px]"
          src="https://storage.googleapis.com/resagratia-webapp/misc/banenr_santa_hat.svg"
          alt="Santa Hat"
        /> */}
        D
      </span>
      ata Bootcamp
    </Link>

    <div className="flex flex-row items-center gap-3">
      <span className="text-xs text-white font-normal max-[320px]:text-[10px]">
        Continues in
      </span>
      <PromoCountdown
        parentStyle="flex flex-row items-center gap-1"
        childStyle="max-[320px]:text-[10px] text-[#2b2c54] bg-white rounded px-1 border border-white text-xs font-bold"
      />
    </div>
    <div className="xs:hidden lg:block max-[320px]:hidden lg:absolute lg:right-3 lg:w-[300px] lg:h-[55px]">
      <img
        className="w-full h-full object-cover"
        src="https://storage.googleapis.com/resagratia-webapp/misc/banner_christmas_trees_gift.svg"
        alt="5% discount"
      />
    </div>
    <div className="xs:hidden lg:block max-[320px]:hidden lg:absolute lg:left-3 lg:w-[300px] lg:h-[48px]">
      <img
        className="w-full h-full"
        src="https://storage.googleapis.com/resagratia-webapp/misc/course_trails_Nov_1_2023.svg"
        alt="trees"
      />
    </div>
  </>
);
