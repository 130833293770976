import React from 'react';
import { Link } from 'react-router-dom';
import { navbarItems } from '../../constants/navbar/navbar';

const MobileNavbarMenuItems = ({ setMenuOpen }) => {
  return (
    <>
      <div className='my-6 py-6'>
        <div className='flex flex-col'>
          {navbarItems.map((item, index) => (
            <div key={index} className='flex flex-col gap-3 border-b py-3'>
              <span className='text-base text-[#2b2c54] font-medium'>
                {item.itemTitle}
              </span>
              {item.itemLink && (
                <Link
                  key={index}
                  to={item.itemLink}
                  className='text-[#808080] text-sm'
                  onClick={() => setMenuOpen(false)}
                >
                  {item.itemTitle}
                </Link>
              )}
              <ul className='flex flex-col gap-3 text-sm'>
                {item.itemList &&
                  item.itemList.map((list, index) => (
                    <Link
                      key={index}
                      to={list.itemListLink}
                      onClick={() => setMenuOpen(false)}
                    >
                      <li className='text-[#808080]'>{list.itemListTitle}</li>
                    </Link>
                  ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MobileNavbarMenuItems;
