import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

// @components
import SearchField from "../SearchField/SearchField";
import AuthMobileNavBar from "../AuthMobileNavBar/AuthMobileNavBar";
import MobileNavbarMenuItems from "../MobileNavbarMenuItems/MobileNavbarMenuItems";

// @assets
import Logo from "../../assets/logo.svg";

// @icons
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

// @styling
import "./mobilenavbar.scss";

const MobileNavBar = ({ closeBanner }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);

  const location = useLocation();
  const { currentUser } = useAuth();

  const handleMenuOpen = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      {currentUser ? (
        <AuthMobileNavBar closeBanner={closeBanner} />
      ) : (
        <div
          className={`mobile__navbar !sticky ${
            !closeBanner ? "!top-0" : "!top-[55px]"
          }`}
        >
          <div className="mobile__navbar__container">
            {searchOpen === false && (
              <div className="mobile__navbar__logo">
                <Link to="/">
                  <img
                    src={Logo}
                    alt="mobile logo"
                  />
                </Link>
              </div>
            )}

            <div
              className={`mobile__navbar__icon ${
                searchOpen === true ? "w-full" : "gap-[9px]"
              }`}
            >
              {/* {searchOpen === false && (
                <div className="mr-3">
                  <Link to="/bootcamp">
                    <h5 className="relative font-medium text-sm">
                      <span className="absolute -top-4 -right-3 bg-[#ff8c00] text-white rounded px-1">
                        New
                      </span>
                      Bootcamp
                    </h5>
                  </Link>
                </div>
              )} */}

              <SearchField
                width="22"
                height="22"
                type="mobile"
                placeholder="Search"
                setSearchOpen={setSearchOpen}
              />
              {searchOpen === false &&
                (menuOpen === false ? (
                  <MenuRoundedIcon
                    className="mobile__menu__icon"
                    onClick={handleMenuOpen}
                  />
                ) : (
                  <CloseRoundedIcon
                    className="close__icon"
                    onClick={() => setMenuOpen(false)}
                  />
                ))}
            </div>
          </div>

          {menuOpen ? (
            <div
              className={`${
                closeBanner === true
                  ? "mobile__navbar__elements__banner"
                  : "mobile__navbar__elements"
              }`}
            >
              <div className="flex flex-row items-center w-full justify-between">
                {/* sign in button */}
                <div className="mobile__signin__button">
                  <Link
                    to="/signin"
                    state={{ previousUrl: location.pathname }}
                    onClick={() => setMenuOpen(false)}
                  >
                    <button className="bg-transparent text-[#2b2c54] font-medium p-2 border border-[#2b2c54] rounded text-sm">
                      Sign in
                    </button>
                  </Link>
                </div>

                {/* get started button */}
                <div className="mobile__getstarted__button">
                  <Link
                    to="/bootcamp"
                    onClick={() => setMenuOpen(false)}
                  >
                    <button className="bg-[#2b2c54] border border-[#2b2c54] text-white font-medium p-2 rounded text-sm">
                      Get started
                    </button>
                  </Link>
                </div>
              </div>

              {/* menu items */}
              <MobileNavbarMenuItems setMenuOpen={setMenuOpen} />
            </div>
          ) : null}
        </div>
      )}
    </>
  );
};

export default MobileNavBar;
