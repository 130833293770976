export default function ExcelIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <path
        fill="#185C37"
        d="M34.265 26.863l-19.37-3.413v25.216a2.086 2.086 0 002.087 2.084h33.425a2.086 2.086 0 002.092-2.084v-9.291L34.264 26.863z"
      ></path>
      <path
        fill="#21A366"
        d="M34.265 5.25H16.982a2.085 2.085 0 00-2.087 2.084v9.291L34.264 28l10.257 3.413L52.499 28V16.625L34.264 5.25z"
      ></path>
      <path
        fill="#107C41"
        d="M14.895 16.625h19.37V28h-19.37V16.625z"
      ></path>
      <path
        fill="#000"
        d="M28.758 14.35H14.895v28.438h13.863a2.1 2.1 0 002.09-2.085V16.434a2.1 2.1 0 00-2.09-2.084z"
        opacity="0.1"
      ></path>
      <path
        fill="#000"
        d="M27.619 15.488H14.895v28.437h12.724a2.1 2.1 0 002.09-2.084V17.57a2.1 2.1 0 00-2.09-2.084z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M27.619 15.488H14.895V41.65h12.724a2.1 2.1 0 002.09-2.084V17.572a2.1 2.1 0 00-2.09-2.084z"
        opacity="0.2"
      ></path>
      <path
        fill="#000"
        d="M26.48 15.488H14.895V41.65H26.48a2.1 2.1 0 002.089-2.084V17.572a2.1 2.1 0 00-2.09-2.084z"
        opacity="0.2"
      ></path>
      <path
        fill="url(#paint0_linear_2279_907)"
        d="M5.59 15.488H26.48a2.088 2.088 0 012.09 2.084v20.856a2.088 2.088 0 01-2.09 2.085H5.589A2.086 2.086 0 013.5 38.428V17.572a2.086 2.086 0 012.09-2.084z"
      ></path>
      <path
        fill="#fff"
        d="M9.977 34.778l4.394-6.797-4.025-6.759h3.232l2.196 4.328c.203.41.35.714.417.917h.03c.143-.33.295-.646.455-.956l2.348-4.282H22l-4.128 6.72 4.233 6.834h-3.166l-2.537-4.744a4.123 4.123 0 01-.3-.64h-.041a2.959 2.959 0 01-.294.615l-2.613 4.764H9.977z"
      ></path>
      <path
        fill="#33C481"
        d="M50.41 5.25H34.265v11.375h18.233v-9.29a2.088 2.088 0 00-2.09-2.085z"
      ></path>
      <path
        fill="#107C41"
        d="M34.266 28h18.233v11.375H34.266V28z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_2279_907"
          x1="7.864"
          x2="24.206"
          y1="13.85"
          y2="42.151"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#18884F"></stop>
          <stop
            offset="0.5"
            stopColor="#117E43"
          ></stop>
          <stop
            offset="1"
            stopColor="#0B6631"
          ></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}
