// @components
import LearningPathStepper from '../../components/LearningPathStepper/LearningPathStepper';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import useScrollToTop from '../../hooks/useScrollToTop';

const excelCoursePath = [
  {
    id: 1,
    title: `Introduction to Excel`,
    connector: true,
  },
  {
    id: 2,
    title: `Business Analysis with Excel`,
    connector: true,
  },
  {
    id: 3,
    title: `Designing Heatmaps in Excel`,
    connector: false,
  },
];

const ExcelAnalystPath = () => {
  useScrollToTop();
  return (
    <>
      <MetaTags
        path={`learn/excel-analyst-path`}
        title={`Resagratia | Excel Analyst Path`}
        desc={
        `Learn to master excel as a data analyst`
        }
      />
      <LearningPathStepper
        id='excel__path'
        title='Excel Analyst Learning Path'
        data={excelCoursePath}
        exitLink='/course/excel'
      />
    </>
  );
};

export default ExcelAnalystPath;
