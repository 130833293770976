import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useSelector } from "react-redux";
import {
  collection,
  getDocs,
  getFirestore,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import firebase from "firebase/compat/app";
import PageTitleHeader from "../../components/PageTitleHeader/PageTitleHeader";

// components
import UserSettingsSidebar from "../../components/UserSettingsSidebar/UserSettingsSidebar";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import * as dayjs from "dayjs";
import useScrollToTop from "../../hooks/useScrollToTop";

const UserSettings = () => {
  useScrollToTop();
  const [data, setData] = useState([]);
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [company, setCompany] = useState("");
  const [isPaidSubscriber, setIsPaidSubscriber] = useState(false);
  const [plan, setPlan] = useState("");
  const [endDate, setEndDate] = useState();
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);
  const [paymentInProgress, setPaymentInProgress] = useState(false);

  const { currentUser } = useAuth();
  const width = useSelector((state) => state.windowDimension.width);

  const username = currentUser.displayName;
  const email = currentUser.email;
  const uid = currentUser.uid;

  const profile = {
    company: company,
    docId: data.docId,
    username: username,
    currentUser: currentUser,
  };

  const achievements = {
    fullName: `${firstname} ${lastname}`,
  };

  const subscription = {
    isPaidSubscriber: isPaidSubscriber,
    plan: plan,
    endDate: endDate,
    inProgress: paymentInProgress,
    paymentPlan: isPaymentComplete,
  };

  useEffect(() => {
    if (!currentUser) return;
    (async () => {
      try {
        const idTokenResult = await firebase
          .auth()
          .currentUser?.getIdTokenResult(true);
        const { paidSubscriber } = idTokenResult.claims;
        if (paidSubscriber) {
          setIsPaidSubscriber(paidSubscriber);
        }
      } catch (error) {
        console.log(error);
      }
    })();

    return () => {
      setData([]);
      setPlan("");
      setEndDate();
      setCompany("");
      setLastname("");
      setFirstname("");
      setIsPaidSubscriber(false);
      setIsPaymentComplete(false);
      setPaymentInProgress(false);
    };
  }, [currentUser]);

  useEffect(() => {
    if (!isPaidSubscriber) return;
    (async () => {
      try {
        const db = getFirestore();
        const bootcampQuery = query(
          collection(db, "bootcampers"),
          orderBy("firestoreTimestamp", "desc"),
          where("email", "==", email)
        );
        const subscriptionQuery = query(
          collection(db, "users"),
          orderBy("subscriptionEnd", "desc"),
          where("uid", "==", uid)
        );
        const bootcampSnapshot = await getDocs(bootcampQuery);
        const subscriptionSnapshot = await getDocs(subscriptionQuery);

        const subscriptionPlan =
          subscriptionSnapshot?.docs[0]?.data();
        const bootcampData = bootcampSnapshot?.docs[0]?.data();
        const proPlan = subscriptionPlan?.proPlan;
        const bootcampStartDate =
          bootcampData?.bootcampStartDateString;
        const isOnPaymentPlan = bootcampData?.plan;

        const setBootcampTimeLeft = () => {
          setPlan("Bootcamp");

          if (!isOnPaymentPlan) {
            const { bootcampEndDate } = getBootcampEndDate(
              bootcampStartDate,
              3
            );
            const daysLeft = calculateTimeLeft(bootcampEndDate);
            setEndDate(daysLeft);
            setIsPaymentComplete(false);
          } else if (isOnPaymentPlan) {
            if (bootcampData?.paidFull) {
              const { bootcampEndDate } = getBootcampEndDate(
                bootcampStartDate,
                3
              );
              const daysLeft = calculateTimeLeft(bootcampEndDate);
              setEndDate(daysLeft);
              setIsPaymentComplete(true);
            } else if (!bootcampData?.paidFull) {
              const { bootcampEndDate } = getBootcampEndDate(
                bootcampStartDate,
                1
              );
              const daysLeft = calculateTimeLeft(bootcampEndDate);
              setEndDate(daysLeft);
              setPaymentInProgress(true);
            }
          }
        };

        const setSubscriptionTimeleft = () => {
          const endDate = subscriptionPlan.subscriptionEnd;
          let subscriptionEndDate = formatTimestamp(endDate);
          if (subscriptionEndDate === "Invalid timestamp format") {
            subscriptionEndDate = "2020-11-6";
          }
          const daysLeft = calculateTimeLeft(subscriptionEndDate);
          setPlan(proPlan);
          setEndDate(daysLeft);
        };

        // Checks if learner has both bootcamp and subscription plan
        if (
          bootcampSnapshot.docs.length > 0 &&
          subscriptionSnapshot.docs.length > 0 &&
          proPlan &&
          subscriptionPlan.subscriptionEnd
        ) {
          const endDate = subscriptionPlan.subscriptionEnd;
          const subscriptionEndDate = formatTimestamp(endDate);
          const bootcampStartDate =
            bootcampData?.bootcampStartDateString;
          let bootcamperEndDate;
          if (!isOnPaymentPlan) {
            const { bootcampEndDate } = getBootcampEndDate(
              bootcampStartDate,
              3
            );
            bootcamperEndDate = bootcampEndDate;
          } else if (isOnPaymentPlan) {
            if (bootcampData?.paidFull) {
              const { bootcampEndDate } = getBootcampEndDate(
                bootcampStartDate,
                3
              );
              bootcamperEndDate = bootcampEndDate;
            } else if (!bootcampData?.paidFull) {
              const { bootcampEndDate } = getBootcampEndDate(
                bootcampStartDate,
                1
              );
              bootcamperEndDate = bootcampEndDate;
            }
          }
          // Get the most recent active plan
          const mostRecentDate = compareDates(
            subscriptionEndDate,
            bootcamperEndDate
          );

          // Sets current account plan based on most recent purchase
          if (mostRecentDate === bootcamperEndDate) {
            setBootcampTimeLeft();
          } else if (mostRecentDate === subscriptionEndDate) {
            setSubscriptionTimeleft();
          }
        } else if (bootcampSnapshot.docs.length > 0) {
          setBootcampTimeLeft();
        } else if (subscriptionSnapshot.docs.length > 0 && proPlan) {
          setSubscriptionTimeleft();
        } else {
          setPlan("Admin");
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [isPaidSubscriber]);

  useEffect(() => {
    const updateUserSettings = async () => {
      const uid = currentUser.uid;
      try {
        const db = getFirestore();
        const userRef = collection(db, "users");
        const bootcampQuery = query(userRef, where("uid", "==", uid));

        onSnapshot(bootcampQuery, (snapshot) => {
          snapshot.docs.map((doc) => {
            return setData({ ...doc.data(), docId: doc.id });
          });
        });
        setFirstname(data.firstName);
        setLastname(data.lastName);
        setCompany(data.company);
      } catch (error) {
        console.error(error);
      }
    };

    updateUserSettings();
  }, [
    currentUser.uid,
    data.firstName,
    data.lastName,
    data.company,
    data.paidSubscriber,
    data.proPlan,
    data.subscriptionEnd,
  ]);

  return (
    <div className=" pb-12">
      <MetaTags
        path={`account`}
        title={`Resagratia | ${currentUser?.displayName} Settings`}
        desc={`Welcome ${currentUser?.displayName} to your profile settings`}
      />
      <div className="container mx-auto flex flex-col gap-12">
        <PageTitleHeader title="Account Settings" />
        <div className="flex flex-col gap-2">
          <h4 className="text-4xl font-medium my-3 text-[#808080]">
            {firstname} {lastname}
          </h4>
          <span className="text-base text-[#808080]">{email}</span>
        </div>

        <>
          {width <= 768 ? (
            <div className="flex flex-col gap-8">
              <div className="w-full">
                <UserSettingsSidebar
                  profile={profile}
                  subscription={subscription}
                  achievements={achievements}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="">
                <UserSettingsSidebar />
              </div>

              <div className="">
                <Outlet
                  context={{
                    profile,
                    subscription,
                    achievements,
                  }}
                />
              </div>
            </div>
          )}
        </>
      </div>
    </div>
  );
};

function getBootcampEndDate(bootcampStartDate, BOOT_CAMP_PERIOD) {
  const date = dayjs(bootcampStartDate);

  const newDate = date.add(BOOT_CAMP_PERIOD, "month");

  const bootcampEndDate = newDate.format("YYYY-MM-DD");
  return { bootcampEndDate };
}

function calculateTimeLeft(endDate) {
  const end = dayjs(endDate);
  const start = dayjs();

  if (end.isBefore(start, "day")) {
    return false;
  }
  const days = end.diff(start, "day");

  const months = Math.floor(days / 30);
  const remainingDaysAfterMonths = days % 30;
  const weeks = Math.floor(remainingDaysAfterMonths / 7);
  const remainingDays = remainingDaysAfterMonths % 7;

  if (days >= 30) {
    if (weeks > 0 && remainingDays > 0) {
      return `${months} Months, ${weeks} Weeks, and ${remainingDays} Days`;
    } else if (weeks > 0) {
      return `${months} Months and ${weeks} Weeks`;
    } else {
      return `${months} Months and ${remainingDays} Days`;
    }
  } else if (days >= 7) {
    return `${weeks} Weeks and ${remainingDays} Days`;
  } else {
    return `${days} Days`;
  }
}

function formatTimestamp(timestamp) {
  if (timestamp.seconds && timestamp.nanoseconds) {
    // Convert Firebase timestamp to JavaScript Date
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );

    // Format the date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } else if (typeof timestamp === "number") {
    // Assuming the provided time format is in milliseconds, convert to Date
    const date = new Date(timestamp);

    // Format the date as "YYYY-MM-DD"
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  } else {
    return "Invalid timestamp format";
  }
}

function compareDates(date1, date2) {
  const date1Parts = date1.split("-");
  const date2Parts = date2.split("-");

  const year1 = parseInt(date1Parts[0]);
  const month1 = parseInt(date1Parts[1]);
  const day1 = parseInt(date1Parts[2]);

  const year2 = parseInt(date2Parts[0]);
  const month2 = parseInt(date2Parts[1]);
  const day2 = parseInt(date2Parts[2]);

  if (year1 > year2) {
    return date1;
  } else if (year1 < year2) {
    return date2;
  } else if (month1 > month2) {
    return date1;
  } else if (month1 < month2) {
    return date2;
  } else if (day1 > day2) {
    return date1;
  } else {
    return date2;
  }
}

export default UserSettings;
