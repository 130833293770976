import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TabContent = ({ id, activeTab, setActiveTab, path, children }) => {
  const location = useLocation();

  useEffect(() => {
    location.pathname === '/learning-path' ? setActiveTab('tab1') : path === location.pathname && setActiveTab(id);
    location.pathname === '/learning-path' && setActiveTab('tab1');
  }, [id, setActiveTab, path, location.pathname]);

  return activeTab === id ? (
    <div className='tab__content'>{children}</div>
  ) : null;
};

export default TabContent;
