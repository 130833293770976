import { useState } from 'react';
import { useSelector } from 'react-redux';

// @components
import TabNavItem from '../TabNavItem/TabNavItem';
import TabContent from '../TabContent/TabContent';
import LearningPath from '../../screens/LearningPath/LearningPath';
import CoursesMenu from '../../screens/CoursesMenu/CoursesMenu';

// @styling
import '../CourseContentTabs/index.scss';

const LearnMenuSidebar = () => {
  const [activeTab, setActiveTab] = useState('tab1');

  const learnNav = [
    {
      id: 'learning-path',
      title: 'Learning path',
      path: '/learn/learning-path',
      tabId: 'tab1',
      component: <LearningPath />,
      digId: 1,
    },
    {
      id: 'excel-courses',
      title: 'Excel',
      path: '/learn/excel-courses',
      tabId: 'tab2',
      component: <CoursesMenu />,
      digId: 2,
    },
    {
      id: 'powerbi-courses',
      title: 'Power BI',
      path: '/learn/powerbi-courses',
      tabId: 'tab3',
      component: <CoursesMenu />,
      digId: 3,
    },
    {
      id: 'tableau-courses',
      title: 'Tableau',
      path: '/learn/tableau-courses',
      tabId: 'tab4',
      component: <CoursesMenu />,
      digId: 4,
    },
    {
      id: 'sql-courses',
      title: 'SQL',
      path: '/learn/sql-courses',
      tabId: 'tab5',
      component: <CoursesMenu />,
      digId: 5,
    },
    {
      id: 'python-courses',
      title: 'Python',
      path: '/learn/python-courses',
      tabId: 'tab6',
      component: <CoursesMenu />,
      digId: 6,
    },
    {
      id: 'sheets-courses',
      title: 'Google Sheets',
      path: '/learn/google-sheets-courses',
      tabId: 'tab7',
      component: <CoursesMenu />,
      digId: 7,
    },
    {
      id: 'case-study',
      title: 'Case-study projects',
      path: '/learn/case-study-projects',
      tabId: 'tab8',
      component: <CoursesMenu />,
      digId: 8,
    },
  ];

  const width = useSelector((state) => state.windowDimension.width);

  return (
    <>
      {width <= 768 ? (
        <div className='tabs'>
          <ul className='text-sm flex flex-row gap-3 tab__nav'>
            {learnNav.map((item, index) => (
              <TabNavItem
                key={index}
                title={item.title}
                id={item.tabId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                path={item.path}
              />
            ))}
          </ul>
          <div className='!px-0 mx-auto tab__outlet'>
            {learnNav.map((item, index) => (
              <TabContent
                key={index}
                id={item.tabId}
                activeTab={activeTab}
                path={item.path}
                setActiveTab={setActiveTab}
              >
                <div key={index} className=''>
                  {item.component}
                </div>
              </TabContent>
            ))}
          </div>
        </div>
      ) : (
        // <div className=''>
        <div className='tabs'>
          <ul className='text-sm flex flex-row gap-3 tab__nav'>
            {learnNav.map((item, index) => (
              <TabNavItem
                key={index}
                title={item.title}
                id={item.tabId}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                path={item.path}
              />
            ))}
          </ul>
          {/* <ul className='text-sm flex flex-col gap-1'>
              {learnNav.map((item, index) => (
                <SidebarNav
                  key={index}
                  title={item.title}
                  path={item.path}
                  icon={item.icon}
                />
              ))}
            </ul> */}
        </div>
        // </div>
      )}
    </>
  );
};

export default LearnMenuSidebar;
