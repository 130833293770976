import { createSlice } from "@reduxjs/toolkit";
import {
  globleLeaguePlaceholderList,
  BootcampLeagueListPlaceholder,
} from "../../constants/leagueTablePlaceholder";

export const dashboardData = createSlice({
  name: "dashboardData",
  initialState: {
    bootcampType: null,
    userEngagement: null,
    isDataAnalytics: true,
    noBootcampData: true,
    totalEngagement: "null",
    bootcampCohortDetails: [],
    globalLeaderboard: globleLeaguePlaceholderList,
    bootcampCohortData: BootcampLeagueListPlaceholder,
    isFetchingBootcamperDoc: { status: false, isChecked: false },
  },
  reducers: {
    handleBootcampType: (state, action) => {
      state.bootcampType = action.payload;
    },
    handleNoBootcampData: (state, action) => {
      state.noBootcampData = action.payload;
    },
    handleUserEngagement: (state, action) => {
      state.userEngagement = action.payload;
    },
    handleIsDataAnalytics: (state, action) => {
      state.isDataAnalytics = action.payload;
    },
    handleTotalEngagement: (state, action) => {
      state.totalEngagement = action.payload;
    },
    handleGlobalLeaderboard: (state, action) => {
      state.globalLeaderboard = action.payload;
    },
    handleBbootcampCohortData: (state, action) => {
      state.bootcampCohortData = action.payload;
    },
    handleBootcampCohortDetails: (state, action) => {
      state.bootcampCohortDetails = action.payload;
    },
    handleIsFetchingBootcamperDoc: (state, action) => {
      state.isFetchingBootcamperDoc = action.payload;
    },
  },
});

export const {
  handleBootcampType,
  handleNoBootcampData,
  handleUserEngagement,
  handleIsDataAnalytics,
  handleTotalEngagement,
  handleGlobalLeaderboard,
  handleBbootcampCohortData,
  handleBootcampCohortDetails,
  handleIsFetchingBootcamperDoc,
} = dashboardData.actions;

export default dashboardData.reducer;
