export default function PythonIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <g clipPath="url(#clip0_2279_904)">
        <path
          fill="url(#paint0_linear_2279_904)"
          d="M27.762.016c-14.182 0-13.296 6.15-13.296 6.15l.016 6.372h13.533v1.913H9.106S.031 13.42.031 27.73c0 14.31 7.921 13.802 7.921 13.802h4.727v-6.64s-.255-7.92 7.795-7.92h13.423s7.541.121 7.541-7.29V7.432S42.584.016 27.762.016zM20.3 4.3a2.432 2.432 0 012.25 3.366 2.432 2.432 0 01-3.182 1.318 2.434 2.434 0 01.932-4.684z"
        ></path>
        <path
          fill="url(#paint1_linear_2279_904)"
          d="M28.165 55.59c14.182 0 13.297-6.15 13.297-6.15l-.016-6.372H27.912v-1.913h18.91s9.074 1.03 9.074-13.28c0-14.31-7.92-13.803-7.92-13.803h-4.728v6.64s.255 7.921-7.794 7.921H22.03s-7.542-.121-7.542 7.29v12.252s-1.145 7.415 13.676 7.415m7.462-4.285a2.433 2.433 0 01-2.25-3.366 2.434 2.434 0 014.685.932 2.434 2.434 0 01-2.434 2.434z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_2279_904"
          x1="536.89"
          x2="3305.38"
          y1="499.874"
          y2="3240.75"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#387EB8"></stop>
          <stop
            offset="1"
            stopColor="#366994"
          ></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear_2279_904"
          x1="806.894"
          x2="3779.93"
          y1="868.508"
          y2="3679.02"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFE052"></stop>
          <stop
            offset="1"
            stopColor="#FFC331"
          ></stop>
        </linearGradient>
        <clipPath id="clip0_2279_904">
          <path
            fill="#fff"
            d="M0 0H56V55.781H0z"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}
