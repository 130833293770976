import dayjs from "dayjs";
import { Skeleton, Typography } from "@mui/material";
import BootcampTestIcon from "../assets/test-icon.png";
import ImpactSurveyIcon from "../assets/impact-survey.png";

export const WeekNumberAndCourseTitle = ({
  text,
  week,
  style,
  currentWeek,
}) => (
  <>
    <CardHeader
      text={week}
      style={style}
      currentWeek={currentWeek}
    />
    <CardText
      text={text}
      currentWeek={currentWeek}
    />
  </>
);

export const CardHeader = ({ text, style, currentWeek }) => (
  <h3
    className={`${style ? style : "text-black"} text-xl font-normal`}
  >
    {`${text} ${currentWeek ? "(Current)" : ""}`}
  </h3>
);

export const CardText = ({ text, style, bullet, currentWeek }) => (
  <p
    className={`${
      style
        ? style
        : currentWeek
        ? "text-[#fbb662]"
        : "text-[#a25a05]"
    } font-normal text-xs w-[14.5rem] truncate`}
  >
    {bullet && <span className="pr-1">{bullet}.</span>}
    {text}
  </p>
);

export const cardBg = (week, currentWeek) =>
  `${week.week === currentWeek ? "bg-[#222222]" : "bg-[#f4f4f4]"}`;
export const headerColour = (week, currentWeek) =>
  `${week.week === currentWeek ? "text-white" : "text-black"}`;
export const textColour = (week, currentWeek) =>
  `${
    week.week === currentWeek ? "text-[#a7a7a7]" : "text-[#626262]"
  }`;
export const icon = (week) =>
  `${week.type === "survey" ? ImpactSurveyIcon : BootcampTestIcon}`;
export const text = (week) =>
  `${week.type === "survey" ? week.survey : week.test}`;

export const formatDate = (inputDate) => {
  const parsedDate = dayjs(inputDate);
  return parsedDate.format("MMMM D YYYY");
};

export const calculateWeekFromDate = (inputDate) => {
  const { currentWeek } = calculateWeekNumberFrom(inputDate);
  return `Week ${currentWeek}`;
};

export const calculateWeekNumberFrom = (inputDate) => {
  const currentDate = dayjs();
  const startDate = dayjs(inputDate);
  const diffInWeeks = currentDate.diff(startDate, "week");
  const currentWeek = Math.ceil(diffInWeeks) + 1;

  return { currentWeek };
};

export const groupByFirstItem = (arr) => {
  const grouped = {};

  arr.forEach((item) => {
    const key = item[0];

    if (!grouped[key]) grouped[key] = [];

    grouped[key].push({
      cohort: item[1],
      zoomDate: item[3],
      zoomTime: item[4],
      zoomLink: item[5],
      startDate: item[0],
      recordingLink: item[7],
      week: `Week ${item[2]}`,
    });
  });

  return grouped;
};

export const TextSkeletonLoader = ({ width, colour, bg }) => (
  <span className={`${width} inline-block w-full`}>
    <Typography
      component="div"
      variant="body1"
    >
      <Skeleton
        className={`${colour ? "!bg-[#393131]" : bg ? bg : ""}`}
      />
    </Typography>
  </span>
);

export const dataScienceBootcampArr = [
  {
    type: "topics",
    week: "Week 1",
    course: "Data fundamentals with Google Sheets",
    topics: [
      { topic: "Introduction to Google sheets" },
      { topic: "Inserting and getting data" },
      { topic: "Data cleaning techniques" },
      { topic: "Functions and Formulas" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink:
      "/library/data-analytics-fundamentals-with-google-sheets",
  },
  {
    type: "survey",
    survey: "Bootcamp Impact Survey",
    link: "https://forms.gle/quD6P3MeF7R2JPDU9",
  },
  {
    type: "topics",
    week: "Week 2",
    course: "Data fundamentals with Google Sheets",
    topics: [
      { topic: "Data Visualization" },
      { topic: "Pivot tables,Sharing &Collaboration" },
      { topic: "Big Query Data connector" },
      { topic: "Course Case Study" },
    ],
    additionalPractice: [
      {
        name: "How to build a dashboard",
        link: "https://resagratia.com/resources/blog/google-sheets-how-build-dashboard",
      },
    ],
    courseLink:
      "/library/data-analytics-fundamentals-with-google-sheets",
  },
  {
    type: "test",
    test: "ZONA Data Analyst Assesment",
    link: "/assessment/excel-practical-challenge-test",
    link: "https://docs.google.com/document/d/1NHfNpSlN4KIj8PRzYRRzKCaevnzDHbTr/edit?usp=sharing&ouid=116779935958733211984&rtpof=true&sd=true",
  },
  {
    type: "topics",
    week: "Week 3",
    course: "Data Analysis with SQL",
    topics: [
      { topic: "Installations and SQL fundamentals" },
      { topic: "Relational Database Concepts" },
      { topic: "Select Statement and SQL clauses" },
    ],
    additionalPractice: [
      {
        name: "Understanding Relational Database Management System",
        link: "https://resagratia.com/resources/blog/understanding-relational-database-management-systems",
      },
    ],
    courseLink: "/library/data-analysis-with-sql",
  },
  {
    type: "topics",
    week: "Week 4",
    course: "Data Analysis with SQL",
    topics: [
      { topic: "In CASE you STRING and Aggregate" },
      { topic: "Introducing JOINS, UNION and Subquery" },
      { topic: "Working with Dates in SQL" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink: "/library/data-analysis-with-sql",
  },
  {
    type: "test",
    test: "Baobab BI Analyst Job Test",
    link: "/assessment/excel-practical-challenge-test",
    link: "https://docs.google.com/document/d/1ZrwZVscZe7Wdgi08dn0bX5uUDbjmzdHl/edit?usp=sharing&ouid=116779935958733211984&rtpof=true&sd=true",
  },
  {
    type: "topics",
    week: "Week 5",
    course: "SQL for Data Science",
    topics: [
      { topic: "Get familiar with PostgreSQL and course dataset" },
      { topic: "Deep dive into joins: Inner Join" },
      { topic: "Deep dive into joins: Left and Right Join" },
      { topic: "Deep dive into joins: Full Outer Join" },
      { topic: "Deep dive into joins: Cross and Self Joins" },
      { topic: "Case study" },
    ],
    additionalPractice: [
      {
        name: "Understanding JOINS in SQL",
        link: "https://resagratia.com/resources/blog/understanding-joins-in-sql",
      },
    ],
    courseLink: "/library/sql-for-data-science",
  },
  {
    type: "survey",
    survey: "Mid Bootcamp Survey",
    link: "https://forms.gle/V5XTc3RHStyBtPLUA",
  },
  {
    type: "topics",
    week: "Week 6",
    course: "SQL for Data Science",
    topics: [
      { topic: "Analyzing data in SQL" },
      { topic: "CTEs vs Subqueries" },
      { topic: "Problem solving approaches" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink: "/library/sql-for-data-science",
  },
  {
    type: "test",
    test: "Vandes Growth Analyst Test",
    link: "/assessment/excel-practical-challenge-test",
    link: "https://docs.google.com/document/d/1UoSXKZXXtm_BJ5O4VrWRjv3PUmc1_Q8tOaAVrBeKOO8/edit?usp=sharing",
  },
  {
    type: "topics",
    week: "Week 7",
    course: "Introduction to Python Programming",
    topics: [
      { topic: "Getting Started" },
      { topic: "Numbers & Operators" },
      { topic: "Variables & Strings" },
      { topic: "Boolean and Logic" },
    ],
    additionalPractice: [
      {
        name: "Pandas for Data analysis",
        link: "https://resagratia.com/resources/blog/pandas-for-data-analysis",
      },
    ],
    courseLink:
      "/library/introduction-to-python-programming-language",
  },
  {
    type: "topics",
    week: "Week 8",
    course: "Introduction to Python Programming",
    topics: [
      { topic: "Loops" },
      { topic: "Lists" },
      { topic: "Functions" },
    ],
    additionalPractice: [
      {
        name: "Numpy For Data Analysis",
        link: "https://resagratia.com/resources/blog/numpy-for-data-analysis",
      },
    ],
    courseLink:
      "/library/introduction-to-python-programming-language",
  },
  {
    type: "survey",
    survey: "Mid Bootcamp Survey",
    link: "https://forms.gle/V5XTc3RHStyBtPLUA",
  },
  {
    type: "topics",
    week: "Week 9",
    course: "Data Science Fundamentals with Python",
    topics: [
      { topic: "Understanding the Fundamentals" },
      { topic: "Data manipulation with Pandas I" },
      { topic: "Data manipulation with Pandas II" },
      { topic: "Data visualization - I" },
    ],
    additionalPractice: [
      {
        name: "Visualising Whatsapp Chats Part 1",
        link: "https://resagratia.com/resources/blog/visualizing-whatsapp-chats-using-python-power-bi",
      },
    ],
    courseLink: "/library/data-science-fundamental-with-python",
  },
  {
    type: "topics",
    week: "Week 10",
    course: "Data Science Fundamentals with Python",
    topics: [
      { topic: "Data Visualization - II" },
      { topic: "Exploratory Data Analysis" },
      { topic: "Introduction to Machine learning" },
    ],
    additionalPractice: [
      {
        name: "Visualising Whatsapp Chats Part 2",
        link: "https://resagratia.com/resources/blog/visualizing-whatsapp-chats-using-python-and-power-bi-part-2",
      },
    ],
    courseLink: "/library/data-science-fundamental-with-python",
  },
  {
    type: "topics",
    week: "Week 11",
    course: "Case Study project",
    topics: [{ topic: "Machine Learning Project" }],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink: "#",
  },
  {
    type: "topics",
    week: "Week 12",
    course: "Case Study project",
    topics: [{ topic: "Machine Learning Project" }],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink: "#",
  },
  {
    type: "survey",
    survey: "Bootcamp Graduation Survey",
    link: "https://forms.gle/DJjhBjRqrBRb8ZAa8",
  },
  {
    type: "survey",
    survey: "Graduation Project",
    link: "https://docs.google.com/document/d/1bubL5ykaaVj7i01FORVZXAJ1ydpVXCxbvvS0UVg7ZWk/edit?usp=sharing",
  },
];

export const bootcampRoadmapArr = [
  {
    type: "topics",
    week: "Week 1",
    course: "Introduction to Microsoft Excel",
    topics: [
      { topic: "Interface,navigation & formatting" },
      { topic: "Cell referencing & data analysis" },
      { topic: "Excel calculations" },
    ],
    additionalPractice: [
      {
        name: "The Importance Of Data Analysis",
        link: "https://resagratia.com/resources/blog/importance-data-analysis",
      },
      {
        name: "Data Cleaning Steps",
        link: "https://resagratia.com/resources/blog/data-cleaning-how-to-clean-data",
      },
    ],
    courseLink: "/library/introduction-to-excel",
  },
  {
    type: "topics",
    week: "Week 2",
    course: "Introduction to Microsoft Excel",
    topics: [
      { topic: "Lookup calculations" },
      { topic: "Introduction to Pivot Tables" },
    ],
    additionalPractice: [
      {
        name: "How to Use Index and Match",
        link: "https://resagratia.com/resources/blog/how-to-use-index-and-match",
      },
      {
        name: "Lookup Functions in Excel : Hlookup, Vlookup, Xlookup",
        link: "https://resagratia.com/resources/blog/lookup-functions-in-excel-hlookup-vlookup-xlookup",
      },
    ],
    courseLink: "/library/introduction-to-excel",
  },
  {
    type: "survey",
    survey: "Bootcamp Impact Survey",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSd8lSbKpjdARn2oasHAD6CnjQB01xyAXDCqf2FeHzjscJjsaw/viewform",
  },
  {
    type: "topics",
    week: "Week 3",
    course: "Business Analysis using Microsoft Excel",
    topics: [
      { topic: "Data validation & named ranges" },
      { topic: "Excel table & features" },
      { topic: "Advanced use of pivot tables" },
    ],
    additionalPractice: [
      {
        name: "The Importance of Data Visualization",
        link: "https://resagratia.com/resources/blog/importance-data-visualization",
      },
      {
        name: "The Difference Between Good and Bad Visualization",
        link: "https://resagratia.com/resources/blog/differences-between-good-data-visualization-bad-data-visualization",
      },
    ],
    courseLink: "/library/business-analysis-with-excel",
  },
  {
    type: "topics",
    week: "Week 4",
    course: "Business Analysis using Microsoft Excel",
    topics: [
      { topic: "Introduction to data visualization" },
      { topic: "Introduction to power query" },
    ],
    additionalPractice: [
      {
        name: "Interactive Dashboard Design in Excel",
        link: "https://resagratia.com/resources/datadigest/introduction-interactive-dashboard-design-excel",
      },
      {
        name: "Report Automation in Power QueryData Cleaning Steps",
        link: "https://resagratia.com/resources/datadigest/report-automation-in-power-query",
      },
    ],
    courseLink: "/library/business-analysis-with-excel",
  },
  {
    type: "test",
    testCard: true,
    testPortal: true,
    test: "Excel Practical Challenge Test",
    link: "/assessment/excel-practical-challenge-test",
    // link: "https://docs.google.com/spreadsheets/d/1O1iLFu8Xl-EWUkjvTTBVbKgUJ3zNlBKD2DEhJvO0Q3c/edit?usp=sharing",
  },
  {
    type: "topics",
    week: "Week 5",
    course: "Data Fundamentals with Power BI",
    topics: [
      { topic: "Learning the basics" },
      { topic: "Introduction to Power Query" },
      { topic: "Dataviz and reporting - part 1" },
      { topic: "Data modeling" },
    ],
    additionalPractice: [
      {
        name: "Interactive Dashboard Design in Power BI",
        link: "https://resagratia.com/resources/datadigest/designing-an-immersive-visual-experience-in-power-bi",
      },
      {
        name: "Working with Business Data in Power Query",
        link: "https://resagratia.com/resources/datadigest/working-with-business-data-in-power-query",
      },
    ],
    courseLink: "/library/data-analytics-fundamentals-with-powerbi",
  },
  {
    type: "topics",
    week: "Week 6",
    course: "Data Fundamentals with Power BI",
    topics: [
      { topic: "Intro to DAX" },
      { topic: "Dataviz and reporting - part 2" },
      { topic: "Data preparation techniques" },
    ],
    additionalPractice: [
      {
        name: "Dax for Power BI beginners",
        link: "https://resagratia.com/resources/datadigest/dax-for-power-bi-beginners",
      },
      {
        name: "Problem Solving Approaches in Power BI",
        link: "https://resagratia.com/resources/datadigest/problem-solving-approaches-in-power-bi",
      },
    ],
    courseLink: "/library/data-analytics-fundamentals-with-powerbi",
  },
  {
    type: "test",
    testCard: true,
    testPortal: true,
    test: "Mid-term Data Analyst Job Test",
    link: "/assessment/mid-term-data-analyst-job-test",
    // link: "https://docs.google.com/spreadsheets/d/1mYTE0wd4e8lATr4VH5FLvYrahMjXow2s/edit?usp=sharing&ouid=116779935958733211984&rtpof=true&sd=true",
  },
  {
    type: "topics",
    week: "Week 7",
    course: "Introduction to Tableau",
    topics: [
      { topic: "Learning the basics" },
      { topic: "Explore Tableau" },
      { topic: "Build your first visual" },
    ],
    additionalPractice: [
      {
        name: "Data Viz Wars : Tableau Vs Power BI",
        link: "https://resagratia.com/resources/blog/data-viz-wars-tableau-vs-power-bi",
      },
    ],
    courseLink: "/library/introduction-to-tableau",
  },
  {
    type: "topics",
    week: "Week 8",
    course: "Introduction to Tableau",
    topics: [
      { topic: "Data wrangling; Tableau data model" },
      { topic: "Building a Tableau dashboard" },
    ],
    additionalPractice: [
      {
        name: "Tableau : Designing a Sales KPI Dashboard",
        link: "https://resagratia.com/resources/datadigest/tableau-design-a-sales-kpi-dashboard",
      },
    ],
    courseLink: "/library/introduction-to-tableau",
  },
  {
    type: "survey",
    survey: "Mid Bootcamp Survey",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSfJET7zv2PssHJZD6fh1Jr78nwFWufrRa3eaYc5gwa1OWF4mg/viewform",
  },
  {
    type: "topics",
    week: "Week 9",
    course: "Intro to SQL with SQL Game of Thrones",
    topics: [{ topic: "SQL Intro" }, { topic: "Chapter 1" }],
    additionalPractice: [
      {
        name: "Understanding Relational Database Management System",
        link: "https://resagratia.com/resources/blog/understanding-relational-database-management-systems",
      },
    ],
    courseLink: "/library/sql-game-of-thrones",
  },
  {
    type: "topics",
    week: "Week 10",
    course: "Intro to SQL with SQL Game of Thrones",
    topics: [{ topic: "Chapter 1 review" }, { topic: "Chapter 2" }],
    additionalPractice: [
      {
        name: "Understanding JOINS in SQL",
        link: "https://resagratia.com/resources/blog/understanding-joins-in-sql",
      },
      {
        name: "Introduction to MySQL",
        link: "https://resagratia.com/resources/blog/a-data-analyst-s-introduction-to-mysql",
      },
    ],
    courseLink: "/library/sql-game-of-thrones",
  },
  {
    type: "test",
    testCard: true,
    testPortal: true,
    test: "Final Operations Analyst Job Test",
    link: "/assessment/final-operations-analyst-job-test",
    // link: "https://docs.google.com/spreadsheets/d/1HillDc0yc6Ekeka01jHq-GjIa4ZL5uM3/edit?usp=sharing&ouid=116779935958733211984&rtpof=true&sd=true",
  },
  {
    type: "topics",
    week: "Week 11",
    course: "Guided Project: Analysis & Visualization of GDP",
    topics: [
      { topic: "Introduction" },
      { topic: "Data transformation in Excel" },
      { topic: "Data transformation in Power BI, PQ" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink:
      "/library/analysis-and-visualisation-of-gdp-in-powerbi-excel",
  },
  {
    type: "topics",
    week: "Week 12",
    course: "Guided Project: Analysis & Visualization of GDP",
    topics: [
      { topic: "Analysis and visualisation in Power BI" },
      { topic: "Case study course work" },
    ],
    additionalPractice: [
      {
        name: "Visualizing Employee Attrition in Power BI",
        link: "https://resagratia.com/resources/datadigest/visualizing-employee-attrition-powerbi",
      },
    ],
    courseLink:
      "/library/analysis-and-visualisation-of-gdp-in-powerbi-excel",
  },
  {
    type: "survey",
    survey: "Bootcamp Graduation Survey",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSe8_WCUPRnzVp8r2k83_mTY20HvIb1vL-3HLbLBvvLNGSnd8Q/viewform",
  },
  {
    type: "topics",
    week: "Week 13",
    course: "Capstone Project",
    topics: [
      { topic: "Introduction" },
      { topic: "Capstone: Mysellar Global Stores" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink:
      "/library/data-analytics-portfolio-and-capstone-project",
  },
  {
    type: "topics",
    week: "Week 14",
    course: "Data Portfolio Guide",
    topics: [
      { topic: "Introduction to GitHub" },
      { topic: "GitHub account and profile" },
      { topic: "Create and build your repository" },
    ],
    additionalPractice: [
      {
        name: "",
        link: "",
      },
    ],
    courseLink:
      "/library/data-analytics-portfolio-and-capstone-project",
  },
];
