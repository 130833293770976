import { Outlet, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { learningPath } from "../../constants/learnMenu/courseMenus";

// @components
import LearnMenuCard from "../../components/LearnMenuCard/LearnMenuCard";
import ExcelAnalystPath from "../ExcelAnalystPath/ExcelAnalystPath";
import PowerbiAnalystPath from "../PowerbiAnalystPath/PowerbiAnalystPath";
import TableauAnalystPath from "../TableauAnalystPath/TableauAnalystPath";
import { MetaTags } from "../../components/MetaTags/MetaTags";
import useScrollToTop from "../../hooks/useScrollToTop";
import SqlAnalystPath from "../SqlAnalystPath/SqlAnalystPath";

const mobileOutlet = [
  {
    id: 1,
    path: `/learn/learning-path/excel-analyst-path`,
    component: <ExcelAnalystPath />,
  },
  {
    id: 2,
    path: `/learn/learning-path/powerbi-analyst-path`,
    component: <PowerbiAnalystPath />,
  },
  {
    id: 3,
    path: `/learn/learning-path/tableauviz-analyst-path`,
    component: <TableauAnalystPath />,
  },
  {
    id: 4,
    path: `/learn/learning-path/sql-analyst-path`,
    component: <SqlAnalystPath />,
  },
];

const LearningPath = () => {
  useScrollToTop();
  const width = useSelector((state) => state.windowDimension.width);
  const location = useLocation();

  return (
    <div className="">
      <MetaTags
        path={`learn/learning-path`}
        title={`Resagratia | Learning Path`}
        desc={`Take your learning to the next level`}
      />
      <div className="text-2xl font-bold leading-loose mb-2">
        Learning Path
      </div>
      <section>
        <div className="mb-6">
          <p className="text-sm mb-2 leading-relaxed">
            We designed our learning paths with a pedagogical and
            pragmatic approach to teaching data analytics concepts to
            learners. A learning path comprises multiple courses, each
            with well-curated learning objectives by industry
            professionals with in-depth teaching experience, designed
            to guide newbies on the path to expertise.
          </p>
          <p className="text-sm mb-2 leading-relaxed">
            By continuously updating our courses and learning path
            structure, we ensure that we are at the centre of
            accelerating learning gains for our students. Upon
            completing the learning paths, students are assured of
            gaining fundamental industry-ready skills in Excel,
            Tableau, Power BI or SQL.{" "}
          </p>
        </div>
        <div className="flex xs:flex-col md:flex-row items-stretch md:justify-between flex-wrap gap-y-12 my-10">
          {learningPath.map((item) => (
            <LearnMenuCard
              key={item.id}
              title={item.title}
              icon={item.img}
              path={item.path}
            />
          ))}
        </div>
        {width >= 768 ? (
          <Outlet />
        ) : (
          <div
            className={`${
              location.pathname === "/learn/learning-path" ||
              location.pathname === "/learn" ||
              location.pathname === "/learn/learning-path/" ||
              location.pathname === "/learn/"
                ? "relative"
                : "fixed inset-0 bg-white flex flex-col justify-center"
            } `}
          >
            {mobileOutlet.map(
              (item) =>
                location.pathname === item.path && (
                  <div
                    key={item.id}
                    className="mx-4 mt-16"
                  >
                    {item.component}
                  </div>
                )
            )}
          </div>
        )}
      </section>
    </div>
  );
};

export default LearningPath;
