import { createSlice } from "@reduxjs/toolkit";

export const handleTabSlice = createSlice({
  name: "tab",
  initialState: {
    tab: "tab1",
  },
  reducers: {
    handleActiveTab: (state, action) => {
      state.tab = action.payload;
    },
  },
});

export const { handleActiveTab } = handleTabSlice.actions;

export default handleTabSlice.reducer;
