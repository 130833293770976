import { createSlice } from "@reduxjs/toolkit";

export const bootcampSet = createSlice({
  name: "bootcampSet",
  initialState: {
    bootcampSetObj: null,
    bootcampGradProfiles: null,
  },
  reducers: {
    handleBootcampSetObj: (state, action) => {
      state.bootcampSetObj = action.payload;
    },
    handleBootcampGradProfiles: (state, action) => {
      state.bootcampGradProfiles = action.payload;
    },
  },
});

export const { handleBootcampSetObj, handleBootcampGradProfiles } =
  bootcampSet.actions;

export default bootcampSet.reducer;
