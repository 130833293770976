import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { getFunctions, httpsCallable } from "firebase/functions";
import { handleExperiencePoint } from "../../redux/reducers/experiencePointSlice";

const ExperiencePoint = () => {
  const dispatch = useDispatch();
  const functions = getFunctions();
  const { currentUser } = useAuth();
  const experiencePoint = useSelector(
    (state) => state.experiencePoint.experiencePoint
  );

  useEffect(() => {
    if (!currentUser) {
      dispatch(handleExperiencePoint(null));
      return;
    }

    (async () => {
      try {
        const getTotalExperiencePoint = httpsCallable(
          functions,
          "getTotalExperiencePoint"
        );

        const xp = await getTotalExperiencePoint({
          userId: currentUser?.uid,
        });
        
        const xpNum = xp?.data[0]?.total_xp;
        dispatch(
          handleExperiencePoint((xpNum ? xpNum / 1000 : 0).toFixed(2))
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, [currentUser]);

  return (
    <>
      {experiencePoint != null && experiencePoint >= 0 && (
        <div className="bg-[#222] text-[#eba959] font-bold p-2 rounded text-xs hover:bg-[#111] whitespace-nowrap">
          {(+experiencePoint).toFixed(2)}K XP
        </div>
      )}
    </>
  );
};

export default ExperiencePoint;
