import React from 'react';

const PageTitleHeader = ({ title }) => {
  return (
    <div className='border-b flex flex-col justify-center h-24'>
      <div className='container mx-auto'>
        <span className='text-lg'>{title}</span>
      </div>
    </div>
  );
};

export default PageTitleHeader;
