import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { MetaTags } from '../../components/MetaTags/MetaTags';
import useScrollToTop from '../../hooks/useScrollToTop';

const EmailVerified = () => {
  useScrollToTop();
  const { currentUser } = useAuth();

  if (currentUser.emailVerified === true) return <Navigate to='/dashboard' />;

  return (
    <div className='container mx-auto my-12 flex flex-col justify-center items-center gap-6'>
      <MetaTags
        path={`verify-email/success`}
        title={`Resagratia | Email Verified`}
        desc={
        `Your email has been verified`
        }
      />
      <div className='w-[100px] h-auto'>
        <img
          className='w-fit h-fit object-cover'
          src='https://user-images.githubusercontent.com/36554009/192116139-75053f11-c8a9-4c48-92fd-ec1fa0de8348.png'
          alt='email verified'
        />
      </div>
      <div className=''>
        <p className='text-sm text-center'>
          Hello <span className='font-medium'>{currentUser.displayName}</span>,
          you have successfully verified your email address.
        </p>
      </div>
    </div>
  );
};

export default EmailVerified;
