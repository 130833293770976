import { createSlice } from "@reduxjs/toolkit";

export const promoSlice = createSlice({
  name: "getAmount",
  initialState: {
    discountCode: "",
    codeApplied: null,
    apppliedCodesArr: [],
  },
  reducers: {
    setDiscountCodeValue: (state, action) => {
      state.discountCode = action.payload;
    },
    setApplyDiscount: (state, action) => {
      state.codeApplied = action.payload;
    },
    setApppliedCodesArr: (state, action) => {
      state.apppliedCodesArr = action.payload;
    },
  },
});

export const {
  setApplyDiscount,
  setApppliedCodesArr,
  setDiscountCodeValue,
} = promoSlice.actions;

export default promoSlice.reducer;
