import { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bootcampDates } from "../../constants/bootcamp/bootcampData";
import {
  handleSelectCohort,
  handleDataScienceCohort,
  handleStoreBootcampDate,
} from "../../redux/reducers/bootcampSlice";

// @components
import PromoCode from "../../utils/PromoCode/PromoCode";
import { SelectionMarker } from "../../utils/SelectionMarker";
import {
  formatDateTwoDaysBack,
  formatBootcampKickOffDate,
} from "../../utils/CountdownTimer/CountdownTimer";

const BootcampSignupDetails = ({
  amount,
  discount,
  discountArr,
  bootcampType,
  discountArray,
  isFullPayment,
}) => {
  const dispatch = useDispatch();
  const selectedCohort = useSelector(
    (state) => state.bootcamp.selectCohort
  );
  const spreadsheetsCohort = useSelector(
    (state) => state.bootcamp.dataScienceCohort
  );
  const dataScienceSelected = useSelector(
    (state) => state.bootcamp.dataScienceSelected
  );

  const bootcampStartDate = useSelector(
    (state) => state.bootcamp.bootcampStartDate
  );

  const storeBootcampDetails = (id, date, spreadsheetsId) => {
    dispatch(handleSelectCohort(id));
    dispatch(handleStoreBootcampDate(date));
    dispatch(handleDataScienceCohort(spreadsheetsId));
  };

  useEffect(() => {
    dispatch(handleSelectCohort(15));
    dispatch(handleDataScienceCohort(5));
    dispatch(handleStoreBootcampDate("2024-9-2"));
  }, [dispatch]);

  const deadlineDate = formatDateTwoDaysBack(bootcampStartDate);
  const kickOffDate = formatBootcampKickOffDate(bootcampStartDate);

  return (
    <div className="my-9">
      <div className="">
        <div className="mb-6">
          <h4 className="font-bold">Bootcamp cohort dates</h4>
          <p className="text-sm font-light mt-2">
            Select what cohort you would like to join.
          </p>
        </div>
        <div className="border-b pb-6 xs:w-full">
          <div className="flex flex-col gap-3 w-full">
            {bootcampDates.map((date, index) => {
              return (
                <Fragment key={index}>
                  {dataScienceSelected ? (
                    date.dataScience.cohort !== "Cohort 0.0" && (
                      <div
                        onClick={() =>
                          date.isDisabled === false
                            ? storeBootcampDetails(
                                date.bootcampId,
                                date.dateToParse,
                                date.dataScience.bootcampId
                              )
                            : null
                        }
                        key={index}
                        className={`${
                          date.bootcampId === selectedCohort &&
                          "relative border border-[#f1f3f4]"
                        } flex flex-row items-center justify-between border border-[#f1f3f4] bg-[#f1f3f4] rounded p-4 ${
                          date.isDisabled === true
                            ? "cursor-not-allowed text-[#9e9ea7] bg-[#f3f3f4] border-[#f3f3f4]"
                            : "cursor-pointer"
                        }`}
                      >
                        <span className="text-xs">
                          {dataScienceSelected
                            ? date.dataScience.cohort
                            : date.cohort}
                        </span>
                        <span className="text-xs font-medium">
                          {date.date}
                        </span>
                        {date.bootcampId === dataScienceSelected
                          ? spreadsheetsCohort
                          : selectedCohort && <SelectionMarker />}
                      </div>
                    )
                  ) : (
                    <div
                      onClick={() =>
                        date.isDisabled === false
                          ? storeBootcampDetails(
                              date.bootcampId,
                              date.dateToParse,
                              date.dataScience.bootcampId
                            )
                          : null
                      }
                      key={index}
                      className={`${
                        date.bootcampId === selectedCohort &&
                        "relative border border-[#f1f3f4]"
                      } flex flex-row items-center justify-between border border-[#f1f3f4] bg-[#f1f3f4] rounded p-4 ${
                        date.isDisabled === true
                          ? "cursor-not-allowed text-[#9e9ea7] bg-[#f3f3f4] border-[#f3f3f4]"
                          : "cursor-pointer"
                      }`}
                    >
                      <span className="text-xs">
                        {dataScienceSelected
                          ? date.dataScience.cohort
                          : date.cohort}
                      </span>
                      <span className="text-xs font-medium">
                        {date.date}
                      </span>
                      {date.bootcampId === dataScienceSelected
                        ? spreadsheetsCohort
                        : selectedCohort && <SelectionMarker />}
                    </div>
                  )}
                </Fragment>
              );
            })}
          </div>
          <div className="text-right text-xs mt-4">
            Discount deadline:{" "}
            <span className="text-[#FF8C00] font-normal">
              {deadlineDate} 11:59 PM
            </span>
          </div>

          <div className="text-right text-xs mt-2">
            Bootcamp officially kicks off on{" "}
            <span className="underline font-medium">
              {kickOffDate} by 8 PM WAT{" "}
            </span>
          </div>
        </div>

        {isFullPayment && (
          <div className="mt-9 border-b pb-6 xs:w-full">
            <div className="">
              <PromoCode
                amount={amount}
                discount={discount}
                discountArr={discountArr}
                bootcampType={bootcampType}
                discountArray={discountArray}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BootcampSignupDetails;
