import { useEffect, useState } from "react";

const useInView = (id, options = {}) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    const target = document.querySelector(`#${id}`);
    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, [id, options, isInView]);

  return isInView;
};

export default useInView;
