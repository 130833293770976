import { createSlice } from '@reduxjs/toolkit';

export const countdownTimerSlice = createSlice({
  name: 'countdownTimer',
  initialState: {
    checkPageId: null,
  },
  reducers: {
    selectCheckPageId: (state, action) => {
      state.checkPageId = action.payload;
    },
  },
});

export const { selectCheckPageId } = countdownTimerSlice.actions;

export default countdownTimerSlice.reducer;