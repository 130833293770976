import React from 'react';
import { useLocation } from 'react-router-dom';
import ErrorComponent from '../components/ErrorComponent/ErrorComponent';

const PaymentSuccessPrivateRoute = ({ children }) => {
  const location = useLocation();
  const urlArr = ['/pay', '/bootcamp/bootcamp-signup'];

  if (!urlArr.includes(location.state?.previousUrl)) {
    return <ErrorComponent />
  }
  return children;
};

export default PaymentSuccessPrivateRoute;
