export const reviews = [
  {
    id: 1,
    user: 'Samuel',
    details: `I'm loving the shortcuts. I'm learning a lot.`,
  },

  {
    id: 2,
    user: 'Priyanka',
    details: `Educator has in-depth knowledge about the topic. Happy to learn new and interesting things`,
  },
  {
    id: 3,
    user: 'Jonathan',
    details: `Thanks so much for putting this course together. The course is packed with so many practical tips and easy to follow guides. I enjoyed every bit of the time I spent on it and learnt so much also.`,
  },
  {
    id: 4,
    user: 'Sulaiman',
    details: `It's a no-brainer that this course is top notch. I have learnt a lot from watching just the first three videos/modules!. I'm really excited about this.`,
  },
  {
    id: 5,
    user: 'Aboniyi',
    details: `Awesome experience. It was an exceptional hands-on training.`,
  },
  {
    id: 6,
    user: 'Oluwatobi',
    details: `The tutor's approach to teaching is top-notch and 100% practical. I would recommend this course to Excel users of any level of proficiency. This course arguably ranks among my top 3 online courses ever.`,
  },
  {
    id: 7,
    user: 'Jinesh',
    details: `Explained in the most simple language.`,
  },
  {
    id: 8,
    user: 'Catherine',
    details: `The pace of this one is good, given there is an expectation of basic Excel knowledge. I'm learning more useful tips and tricks more quickly than I expected. Thank you!`,
  },
  {
    id: 9,
    user: 'Tony',
    details: `It was a packed course, I learned so much in a short time. I never knew excel had so many capabilities. Instructor was very knowledgeable in this area.`,
  },
  {
    id: 10,
    user: 'Elizabeth',
    details: `It was very good for me. Amazing!!!`,
  },
];

export const powerbiReviews = [
  {
    id: 1,
    user: 'Eric',
    details: `Truly, this is one of the best courses I have done in a while. As a person with prior knowledge to Power BI, I thought I knew everything but lo and behold! I really learned a lot. Keep up the good work!`,
  },

  {
    id: 2,
    user: 'Jennifer',
    details: `I love how the course touched on the core basics of Power BI. The course is very detailed and I highly recommend.`,
  },
  {
    id: 3,
    user: 'Abdulwaheed',
    details: `I enjoyed everything about the course and it’s so easy to understand Thank you for this great opportunity.`,
  },
  {
    id: 4,
    user: 'Jane',
    details: `I started this course with no prior knowledge of Power BI, after taking thus course i learnt about the interface of power Bi, how to create relationship between tables, introduction to DAX, diffferent typess of visualizing and fomatting. I had a great time while learning though the DAX was looking a bit scary at first but it went well at the end. Kudos!!!!`,
  },
  {
    id: 5,
    user: 'Ibrahim',
    details: `This is one of the best online courses I have taken. I'd recommend it to people interested in learning Microsoft Power BI.`,
  },
  {
    id: 6,
    user: 'Franck',
    details: `Educator has in-depth knowledge about the topic. Happy to learn new and interesting things.`,
  },
  {
    id: 7,
    user: 'Idongesit',
    details: `In-depth. Rich in content. The instructor is knowledgeable. Quite an interesting journey so far. It was exhilarating to be able to move data to the Power BI environment and create dashboards. The mode of presentation is quite engaging and explanatory.`,
  },
  {
    id: 8,
    user: 'Rejoice',
    details: `It is the best so simplified and easy to understand, the instructor is genius making me understand so easily I loved it, surely my power bi skills has improved a lot after taking this course.`,
  },
  {
    id: 9,
    user: 'Muomah',
    details: `The course is really insightful. He tried in teaching almost all there is to know about Power Bi.`,
  },
];
