// @icons
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import VisibilityOffRoundedIcon from "@mui/icons-material/VisibilityOffRounded";

const SignUpForm = ({
  formik,
  userEmail,
  currentUser,
  phoneNumber,
  showPassword,
  bootcamperLastname,
  handleShowPassword,
  bootcamperFirstname,
}) => {
  return (
    <form>
      <div className="xs:flex xs:flex-col xs:gap-6 md:flex md:flex-row md:justify-between mb-6">
        <div className="">
          <label htmlFor="firstName" />
          <input
            type="text"
            id="firstName"
            name="firstName"
            autoComplete="nope"
            aria-label="firstName"
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            placeholder={`your first name`}
            value={
              currentUser
                ? bootcamperFirstname
                : formik.values.firstName
            }
            className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
          />
          {formik.touched.firstName && formik.errors.firstName ? (
            <div className="text-red-400 text-xs mt-2">
              {formik.errors.firstName}
            </div>
          ) : null}
        </div>
        <div className="">
          <label htmlFor="lastName" />
          <input
            id="lastName"
            type="text"
            name="lastName"
            autoComplete="nope"
            aria-label="lastName"
            onBlur={formik.handleBlur}
            placeholder={`your last name`}
            onChange={formik.handleChange}
            value={
              currentUser
                ? bootcamperLastname
                : formik.values.lastName
            }
            className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
          />
          {formik.touched.lastName && formik.errors.lastName ? (
            <div className="text-red-400 text-xs mt-2">
              {formik.errors.lastName}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mb-6">
        <div className="">
          <label htmlFor="tel" />
          <input
            id="phoneNumber"
            type="tel"
            name="phoneNumber"
            autoComplete="nope"
            aria-label="phoneNumber"
            disabled={phoneNumber}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={formik.values.phoneNumber}
            placeholder={`Enter your phone number`}
            className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
          />
          {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
            <div className="text-red-400 text-xs mt-2">
              {formik.errors.phoneNumber}
            </div>
          ) : null}
        </div>
      </div>
      <div className="mb-6">
        <div className="">
          <label htmlFor="email" />
          <input
            id="email"
            type="email"
            name="email"
            autoComplete="nope"
            aria-label="email"
            placeholder={`your email`}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            value={currentUser ? userEmail : formik.values.email}
            className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="text-red-400 text-xs mt-2">
              {formik.errors.email}
            </div>
          ) : null}
        </div>
      </div>
      {!currentUser && (
        <div className="mb-9">
          <div className="">
            <label htmlFor="confirmEmail" />
            <input
              id="confirmEmail"
              type="email"
              name="confirmEmail"
              autoComplete="nope"
              aria-label="confirmEmail"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              placeholder={`confirm your email`}
              value={
                currentUser ? userEmail : formik.values.confirmEmail
              }
              className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
            />
            {formik.touched.confirmEmail &&
            formik.errors.confirmEmail ? (
              <div className="text-red-400 text-xs mt-2">
                {formik.errors.confirmEmail}
              </div>
            ) : null}
          </div>
        </div>
      )}
      {!currentUser && (
        <div className="mb-9">
          <div className="relative">
            <label htmlFor="password" />
            <input
              id="password"
              name="password"
              autoComplete="off"
              onBlur={formik.handleBlur}
              placeholder="your password"
              onChange={formik.handleChange}
              value={formik.values.password}
              type={`${showPassword ? "text" : "password"}`}
              className="p-[13px] rounded text-[#111] text-[14px] border border-[#e5e7eb] w-full focus:border-[#2684ff] placeholder:text-[#808080]"
            />
            <div className="absolute right-3 top-[25%]">
              {!showPassword ? (
                <VisibilityRoundedIcon
                  fontSize="small"
                  className="cursor-pointer"
                  onClick={handleShowPassword}
                />
              ) : (
                <VisibilityOffRoundedIcon
                  fontSize="small"
                  className="cursor-pointer"
                  onClick={handleShowPassword}
                />
              )}
            </div>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="text-red-400 text-xs mt-2">
              {formik.errors.password}
            </div>
          ) : null}
        </div>
      )}
    </form>
  );
};

export default SignUpForm;
