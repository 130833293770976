import { useEffect, useState } from "react";

export const useCountdownTimer = (ttl) => {
  const targetTime = Date.parse(
    JSON.parse(localStorage.getItem("target-time"))
  );
  const timeLogged = Date.parse(
    JSON.parse(localStorage.getItem("time-logged"))
  );
  const storageCountdown = JSON.parse(
    localStorage.getItem("countdown")
  );
  const timerCountdown = new Date(targetTime).getTime();

  const [countDown, setCountDown] = useState(
    timeLogged - new Date().getTime()
  );

  useEffect(() => {
    const timerInterval = setInterval(() => {
      setCountDown(timeLogged - new Date().getTime());
    }, 1000);

    return () => clearInterval(timerInterval);
  }, [timeLogged]);

  useEffect(() => {
    localStorage.setItem("countdown", JSON.stringify(countDown));
    localStorage.setItem(
      "time-logged",
      JSON.stringify(timerCountdown)
    );
    localStorage.setItem("target-time", JSON.stringify(ttl));
  });

  return getCountdownValues(storageCountdown);
};

const getCountdownValues = (storageCountdown) => {
  // calculate time left
  const days = Math.floor(storageCountdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (storageCountdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(
    (storageCountdown % (1000 * 60 * 60)) / (1000 * 60)
  );
  const seconds = Math.floor((storageCountdown % (1000 * 60)) / 1000);

  return [days, hours, minutes, seconds];
};
