// @assets
import CU from '../../assets/cu.png';
import EgbinPower from '../../assets/egbin-power.png';
import LBS from '../../assets/lbschool.png';
import MKopa from '../../assets/m-kopa.png';
import Shell from '../../assets/shell.png';
import Softcom from '../../assets/softcom.png';
import TradeDepot from '../../assets/trade-depot.png';
import UI from '../../assets/uofibadan.png';
import Dangote from '../../assets/dangote.jpg';
import Deloitte from '../../assets/deloitte.png';

export const logos = [
    {
        id: 1,
        img: CU,
    },
    {
        id: 2,
        img: LBS,
    },
    {
        id: 3,
        img: EgbinPower,
    },
    {
        id: 4,
        img: MKopa,
    },
    {
        id: 5,
        img: Shell,
    },
    {
        id: 6,
        img: Softcom,
    },
    {
        id: 7,
        img: TradeDepot,
    },
    {
        id: 8,
        img: UI,
    },
    {
        id: 9,
        img: Dangote,
    },
    {
        id: 10,
        img: Deloitte,
    },
];