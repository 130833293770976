import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @components
import Buttons from '../../components/Buttons/Buttons';
import useScrollToTop from '../../hooks/useScrollToTop';

const PaymentSuccess = () => {
  useScrollToTop();
  const amountPaid = useSelector((state) => state.getAmount.amount);
  const toggle = useSelector((state) => state.toggle.toggle);
  const exchangeRate = useSelector((state) => state.getAmount.exchangeRate);

  const location = useLocation();

  return (
    <div className='h-screen'>
      <div className='container mx-auto flex flex-col gap-9 items-center justify-center h-full'>
        <div className='w-[100px] h-[]'>
          <img
            src='https://storage.googleapis.com/resagratia-webapp/misc/success.png'
            alt=''
          />
        </div>
        <div className='flex flex-col items-center gap-6'>
          <span className='text-3xl font-bold'>
            {!toggle ? 'USD' : 'NGN'} {toggle ? amountPaid * exchangeRate  : amountPaid}
          </span>
          <p className='font-bold'>Payment successful</p>
        </div>
        {location?.state?.previousUrl === '/bootcamp/bootcamp-signup' && (
          <div className=''>
            <p className='text-sm text-center border-green-200 bg-green-200 text-green-600 rounded p-2 font-medium'>
              Please check your email for data bootcamp instructions.
            </p>
          </div>
        )}
        <div className='my-6 xs:w-1/2 md:w-[200px]'>
          <Buttons
            style={`bg-[#2b2c54] text-sm text-white w-full py-3 px-3 font-bold w-full hover:bg-[#15175c]`}
            hasLinkProp
            path={`/dashboard`}
          >
            Continue
          </Buttons>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
