import { createSlice } from '@reduxjs/toolkit';

export const userSettingsSlice = createSlice({
  name: 'userSettings',
  initialState: {
    updateChanges: false
  },
  reducers: {
    updateUserSettings: (state, action) => {
      state.updateChanges = action.payload;
    },
  },
});

export const { updateUserSettings } = userSettingsSlice.actions;

export default userSettingsSlice.reducer;
