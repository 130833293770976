import { Link } from "react-router-dom";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

const AdminDisplayName = ({ displayName, url }) => (
  <>
    <div className="welcome__dashboard__header pt-8">
      <h2>Hello, {displayName}</h2>
    </div>
    <Link
      to={`/${url}`}
      className="relative py-4 pt-8 inline-block"
    >
      <ArrowBackIosNewRoundedIcon className="relative -left-[0.35rem]" />
    </Link>
    <div>
      <h2 className="font-semibold text-3xl text-[#2b2c54e9]">
        Student Submissions
      </h2>
    </div>
  </>
);

export default AdminDisplayName