import { Link } from 'react-router-dom';
import feather from '../../feather-sprite.svg';
import { footer } from '../../constants/paths/paths';

// @ assets
import resaLogo from '../../assets/logo.svg';

// @icons
import CopyrightRoundedIcon from '@mui/icons-material/CopyrightRounded';

// @styling
// import './footer.scss';

const Footer = () => {
  return (
    <div className='h-fit border-t py-6 px-4'>
      <div className='lg:mx-auto max-w-[1100px] w-full'>
        <div className='xs:flex xs:flex-col xs:gap-6 md:flex md:flex-row md:justify-between'>
          <div className='xs:w-full md:w-[40%]'>
            <div className='xs:w-[150px] md:w-[110px] h-[28px] xs:mt-4 lg:mt-0'>
              <img
                className='max-w-full max-h-full'
                src={resaLogo}
                alt='resa logo'
              />
            </div>
            <div className='flex flex-row items-center gap-3 mt-6'>
              <div className='w-fit'>
                <a href='https://www.linkedin.com/company/resagratia'>
                  <svg
                    width='16'
                    height='16'
                    fill='#000'
                    stroke='#000'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='cursor-pointer'
                  >
                    <use href={`${feather}#linkedin`} />
                  </svg>
                </a>
              </div>

              <div className='w-fit'>
                <a href='https://twitter.com/ResaData?s=20'>
                  <svg
                    width='16'
                    height='16'
                    fill='#000'
                    stroke='#000'
                    strokeWidth='3'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    className='cursor-pointer'
                  >
                    <use href={`${feather}#twitter`} />
                  </svg>
                </a>
              </div>
            </div>
            <div className='mt-6'>
              <p className='font-semibold text-xs'>
                Ventures Park, 5 Kwaji Close, Maitama, FCT Abuja
              </p>
            </div>
          </div>

          <div className='xs:w-full md:w-[60%] xs:flex xs:flex-col xs:gap-6 md:flex md:flex-row justify-between flex-1'>
            {footer.map((heading, index) => (
              <FooterItem
                key={index}
                heading={heading}
                subheadings={heading.subheadings}
              />
            ))}
          </div>
        </div>

        <div className='my-6'>
          <div className='flex items-center gap-1'>
            <CopyrightRoundedIcon className='copyright__icon' />
            <p className='text-xs font-medium'>
              Copyright <span className='mr-1'>{new Date().getFullYear()}</span>
              Resagratia. All Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

const FooterItem = ({ heading, subheadings }) => {
  return (
    <div className=''>
      <h3 key={heading.id} className='text-sm font-bold mb-3'>
        {heading.heading}
      </h3>
      <ul className='flex flex-col gap-3'>
        {subheadings.map((subheading, index) => {
          return (
            <Link className='text-xs' key={index} to={subheading.link}>
              <li className=''>{subheading.title}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
};
