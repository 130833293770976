import Toast from '../../utils/Toast/Toast';

const NoUserSubscription = () => {
  return (
    <div className='flex flex-row justify-start'>
      <Toast
        message='You currently do not have a paid subscription.'
        warning
        icon='alert-triangle'
      />
    </div>
  );
};

export default NoUserSubscription;
