import { Link } from 'react-router-dom';

// @styles
import './index.scss';
import useScrollToTop from '../../hooks/useScrollToTop';

const NotFound = () => {
  useScrollToTop();
  
  return (
    <div className='not__found'>
      <div className='not__found__container'>
        <div className='error__container'>
          <div className='error__text__container'>
              <h3>
                Oops! We cannot find this page.
              </h3>
  
            <div className='error__text__more'>
              
              <p>
                It's not you, It's us. How about you find your way back home?
              </p>
            </div>

            <Link to='/'>
              <button>Back to home</button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
