import { Link } from 'react-router-dom';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const CloseRoundedButton = ({ path, bg, border, color }) => {
  return (
    <>
      {path ? (
        <Link
          to={path}
          className={`absolute xs:top-3 md:top-auto right-0 z-10 h-[24px] w-[24px] rounded-full ${bg} border ${border} flex items-center justify-center`}
        >
          <CloseRoundedIcon style={{ fontSize: '16px', color: color }} />
        </Link>
      ) : (
        <div className=''>
          {' '}
          <CloseRoundedIcon />
        </div>
      )}
    </>
  );
};

export default CloseRoundedButton;
