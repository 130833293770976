import dayjs from "dayjs";

export const getTimeLeftOnCountdown = (time) => {
  const countdownDateLeft = dayjs(time);
  const currentTimeLeft = dayjs();

  return {
    days: getRemainingDays(currentTimeLeft, countdownDateLeft),
    hours: getRemainingHours(currentTimeLeft, countdownDateLeft),
    minutes: getRemainingMinutes(currentTimeLeft, countdownDateLeft),
    seconds: getRemainingSeconds(currentTimeLeft, countdownDateLeft),
  };
};

const getRemainingSeconds = (currentTimeLeft, countdownDateLeft) => {
  const seconds =
    countdownDateLeft.diff(currentTimeLeft, "second") % 60;
  return padWithZeros(seconds, 2);
};

const getRemainingMinutes = (currentTimeLeft, countdownDateLeft) => {
  const minutes =
    countdownDateLeft.diff(currentTimeLeft, "minute") % 60;
  return padWithZeros(minutes, 2);
};

const getRemainingHours = (currentTimeLeft, countdownDateLeft) => {
  const hours = countdownDateLeft.diff(currentTimeLeft, "hour") % 24;
  return padWithZeros(hours, 2);
};

const getRemainingDays = (currentTimeLeft, countdownDateLeft) => {
  const days = countdownDateLeft.diff(currentTimeLeft, "day");
  return days.toString();
};

const padWithZeros = (number, minLength) => {
  const numberString = number.toString();
  if (numberString.length >= minLength) return numberString;
  return "0".repeat(minLength - numberString.length) + numberString;
};

export const SplitDate = (inputDate) => {
  const parts = inputDate.split("-");
  const day = parseInt(parts[2], 10);
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1;

  return { day, year, month };
};
